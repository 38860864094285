import { NotificationService } from './notification.service';
import { LoggingService } from './logging.service';
import { ErrorService } from './error.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, Injector, ErrorHandler } from '@angular/core';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    constructor(private injector: Injector) {

    }

    handleError(error: Error | HttpErrorResponse) {
        const errorService = this.injector.get(ErrorService);
        const logService = this.injector.get(LoggingService);
        const notify = this.injector.get(NotificationService);

        let message;
        let stackTrace;

        if (error instanceof HttpErrorResponse) {
            message = errorService.getServerErrorMessage(error);
            // send notification
            notify.showError(message);
        } else {
            message = errorService.getClientErrorMessage(error);
            notify.showError(message);
        }

        logService.logError(message, stackTrace);
        //console.log(error);
    }
}
