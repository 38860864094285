<nav class="navbar navbar-fixed-top navbar-dark navbar-expand-lg">
  <div class="container-fluid d-flex align-items-center justify-content-between">
    <a class="navbar-brand" href="/home">
      <img src="/assets/logo.png" alt="" height="50" />
      <strong> Tictrack Technologies</strong></a>
    <button data-toggle="collapse" class="navbar-toggler" (click)="isMenuCollapsed = !isMenuCollapsed">
      &#9776;
    </button>
    <div id="navbar-collapse" [ngbCollapse]="isMenuCollapsed" class="collapse navbar-collapse">
      <ul class="nav navbar-nav mr-auto" routerLinkActive="active">
        <li class="nav-item">
          <a class="nav-link" routerLink="/home">Home</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/map">Tracking</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLink="/edit">Edit</a>
        </li>
        <li class="nav-item dropdown" ngbDropdown>
          <a class="nav-link" ngbDropdownToggle id="reportDropdown" role="button">Reports</a>
          <div class="dropdown-menu" ngbDropdownMenu aria-labelledby="reportDropdown">
            <a class="nav-link" routerLink="/trip_report">Trip Report</a>
            <a class="nav-link" routerLink="/mileage_report">Mileage Report</a>
            <a class="nav-link" routerLink="/violation_report">Violation Report </a>
          </div>
        </li>

        <!-- Admin Section -->
        <li class="nav-item" *ngIf="userRole == 'Admin'">
          <a class="nav-link" routerLink="/admin">Admin</a>
        </li>
      </ul>

      <ul class="nav">
        <li class="nav-item dropdown" ngbDropdown>

          <a href="javascript:void(0)" class="nav-link" ngbDropdownToggle>
            <i class="fa fa-user"></i> {{ username }} <b class="caret"></b>
          </a>
          <div class="dropdown-menu-right" ngbDropdownMenu>
            <a class="dropdown-item">
              <i></i> Comapny: {{companyName}}
            </a>
            <a class="dropdown-item">
              <i></i> Role: {{userRole}}
            </a>
            <!--
                          <a class="dropdown-item" href="javascript:void(0)">
                              <i class="fa fa-fw fa-envelope"></i> {{ 'Inbox' | translate }}
                          </a>
                          <a class="dropdown-item" href="javascript:void(0)">
                              <i class="fa fa-fw fa-gear"></i> {{ 'Settings' | translate }}
                          </a>
                          -->
            <a class="dropdown-item" [routerLink]="['/']" (click)="logout()">
              <i class="fa fa-fw fa-power-off"></i> Log Out
            </a>
          </div>
        </li>
      </ul>

    </div>
  </div>
</nav>