import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DataService } from '../services/data.service';
import { User } from '../models/user';
import * as L from 'leaflet';

import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, Label, SingleDataSet } from 'ng2-charts';
import * as pluginAnnotations from 'chartjs-plugin-annotation';
import { Alert } from '../models/alert';
import { Vehicle } from '../models/vehicle';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  customerName = localStorage.getItem('cusName');
  customerID = localStorage.getItem('cusId');
  userName = localStorage.getItem('userName');
  userMapType = localStorage.getItem('userMapType');
  userMaster = localStorage.getItem('userIsMaster');
  userID = localStorage.getItem('userId');

  pieChart
  //
  alerts: Alert[];
  vehicles: any[];
  violations: any[] = [];
  selectedVehicleViolations: any;
  totalDistance: number;

  public spinnerStatus: string;
  public onDestroy$ = new Subject();

  // Line Chart
  public lineChartData: ChartDataSets[] = [
    {
      data: [],
      label: 'Speed',
      pointRadius: 1,
      pointHoverRadius: 1,
      backgroundColor: 'rgba(255,255,255,0.8)',
      borderWidth: 2,
    },
  ];

  public lineChartLabels: Label[] = [];

  public lineChartOptions: ChartOptions = {
    responsive: true,
    title: {
      display: true,
      text: 'Vehicle Speed',
    },
    scales: {
      xAxes: [
        {
          display: true,
          type: 'time',
          distribution: 'series',
          time: {
            parser: 'YYYY-MM-DD HH:mm:ss',
            tooltipFormat: 'll HH:mm',
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            suggestedMin: 0,
            stepSize: 10
          },
          display: true,
        },
      ],
    },
  };

  public lineChartColors: Color[] = [
    {
      borderColor: '#075754',
      backgroundColor: '#07575480',
    },
  ];

  public lineChartLegend = false;
  public lineChartType = 'line';
  public lineChartPlugins = [];


  // Barchart Option
  barChartOptions: ChartOptions = {
    responsive: true,
  };
  barChartLabels: Label[] = [];
  barChartType: ChartType = 'horizontalBar';
  barChartLegend = false;
  barChartPlugins = [];

  barChartData: ChartDataSets[] = [
    {
      data: [],
      label: 'Distance Travelled'
    }
  ];


  // Pie Chart Option
  public pieChartOptions: ChartOptions = {
    responsive: true,
  };

  public pieChartLabels: Label[] = [];
  public pieChartData: SingleDataSet = [];
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = false;
  public pieChartPlugins = [];
  public pieChartColors: Color[] = [
    {
      borderColor: '#fff',
      backgroundColor: ['#0074D9', '#FF4136', '#2ECC40', '#FF851B', '#7FDBFF', '#B10DC9', '#FFDC00', '#001f3f', '#39CCCC',
        '#01FF70', '#85144b', '#F012BE', '#3D9970', '#111111', '#AAAAAA'],
    },
  ];


  constructor(
    public authService: AuthService,
    public dataService: DataService
  ) {

  }


  ngOnInit(): void {

    // Load vehicle data
    this.dataService.getVehicle(
      {
        userId: this.userID,
        cus_id: this.customerID,
        userIsMaster: this.userMaster
      }
    ).subscribe(response => {
      // console.log(response);

      // create a graph
      this.vehicles = response;
      this.getViolation();
    });

  }

  // get the violation
  getViolation() {
    const length = this.vehicles.length;

    this.vehicles.forEach((vehicle, i) => {
      // let date = new Date().toISOString()
      // date = date.split('T')[0];
      const date = '2020-02-27';
      const data = {
        from: date.concat(' 00:00:00'),
        to: date.concat(' 23:59:59'),
        dev_id: vehicle.dev_id,
      };

      // gethistory data: all vehicles
      this.dataService.getViolationHistory(data)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(response => {

          if (response) {
            this.addViolationInformation(response, vehicle.dev_id);
          }

          if (response && i == length - 1) {
            this.vehicleViolationPlot();
          }

        });

      // Get vehicle distance
      this.dataService.getVehicleHistory(data)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(response => {

          // update the vehicle information with distance attribute
          if (response) {
            this.calculateMileage(response, vehicle.dev_id);
          }

          if (response && i == length - 1) {
            // call the distance method
            setTimeout(e => {
              this.vehicleDistancePlot();
            }, 300);

          }

        });

    });

  }

  addViolationInformation(response: any, id: number) {
    // geocode the response
    response.forEach(res => {
      const posLog = res.pos_log.split(',');

      if (res.criteria_name == 'geofence-relativity_290') {
        // geofence realtivity values
        res.actual_value = res.criteria_value === 1 ? 'Entered ' + res.object_name : 'Left ' + res.object_name;

      } else {
        // get actual value
        const actualValue = posLog[4];
        res.actual_value = actualValue;
      }

      // update the violation report
      this.violations.push(res);

      return res;
    });

    // add violation properties
    this.vehicles.forEach(vehicle => {
      if (vehicle.dev_id === id) {
        vehicle.violations = response;
      }
    });


  }

  // Calculate distance

  // Compute distance
  calculateMileage(data, vehicle_id): any {
    // convert data to lat and lng
    const coords = data.map(datum => {
      return [datum.pos_latitude, datum.pos_longitude];
    });

    // distance variable
    const distance = [];
    let previousCoord;

    // Compute distance using distanceTo function
    coords.forEach(coord => {

      if (previousCoord) {
        const length = previousCoord.distanceTo(L.latLng(coord));
        distance.push(length);
      }

      previousCoord = L.latLng(coord);

    });

    // find total distance for each vehicle
    const totalDistance = distance.length === 0 ? 0 : distance.reduce((a, b) => a + b);


    // add the data to the mileage report
    this.vehicles.forEach(vehicle => {

      if (vehicle.dev_id === vehicle_id) {
        // update the mileage value
        vehicle.mileage = Math.round(totalDistance) / 1000;

        return vehicle;
      }

      return vehicle;
    });

    // this.spinner.hide();
  }

  // get
  vehicleViolationPlot() {
    if (this.violations.length === 0) {

    } else {
      //  get the vehicles with violation
      const vehicleViolate = this.vehicles.filter(vehicle => vehicle.violations.length !== 0);

      // extract vehicle names
      const categories = [...new Set(vehicleViolate.map(violation => violation.dev_name))];
      const values = [];

      //console.log(categories);

      // get the number of violations per vehicles
      categories.forEach(category => {

        const count = vehicleViolate.filter(violation => violation.dev_name === category);
        values.push(count.length);
      });

      //console.log(values);
      //console.log(this.violations);
      // plot a chart
      this.pieChartLabels = categories;


      //  Aggregate the the array
      this.pieChartData = values;
    }


  }

  // vioa
  vehicleDistancePlot() {
    //  get the vehicle names and mileage
    const labels = this.vehicles.map(vehicle => vehicle.dev_name);
    const data = this.vehicles.map(vehicle => vehicle.mileage);

    // console.log(data);

    // plot the data
    this.barChartData = [{ data, label: 'Distance Travelled' }];
    this.barChartLabels = labels;

    this.totalDistance = data.reduce((a, b) => a + b).toFixed(0);
  }

  // Respond to chart click events
  public chartClicked({ event, active }: { event: MouseEvent, active: any }): void {
    // Get the element at the clicked position
    const firstPoint = active[0]._chart.getElementAtEvent(event)[0];

    if (firstPoint) {
      const label = this.pieChartLabels[firstPoint._index];

      this.getVehicleViolations(label);
    }
    // this.getVehicleViolations(label);
  }

  getVehicleViolations(label) {
    // get the selected vehicle
    this.selectedVehicleViolations = this.vehicles.find(vehicle => vehicle.dev_name == label);
  }

  ngOnDestroy() {

    this.onDestroy$.next();
    this.onDestroy$.complete();

  }
}
