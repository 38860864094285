
<app-navbar></app-navbar>
<div class="container-fluid">
    <div class="row">
        <div class="col-md-2">

            <div class="card mb-2">
                <div class="card-body text-center">
                    <h2>45</h2>
                    <h5 class="bg-t1"> Vehicles</h5>
                </div>
                
            </div>

            <div class="card mb-2">
                <div class="card-body text-center">
                    <h2>45</h2>
                    <h5 class="bg-t1"> Distance </h5>
                </div>
            </div>

        </div>

        <div class="col-md-6">
            <div class="card">
                <h3 class="t-1 bg-t1 w100 px-2">Recent Violation</h3>
                <div class="card-body">
                    <p>Table on violation</p>
                </div>
            </div>
            
        </div>
    </div>

    <div class="row">
        <div class="col-md-6">
            <div class="card">
                <h3 class="t-1 bg-t1 w100 px-2">Current Vehicle Status</h3>

                <div class="card-body">
                    <p>Bar graphs on vehicles</p>
                </div>
            </div>
           
        </div>
    </div>
</div>