import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    Router,
    Route
} from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class RouteGuard implements CanActivate {
    userMaster = localStorage.getItem('userIsMaster');
    constructor(private authService: AuthService, private router: Router) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        if (this.authService.isAuthenticated()) {

            if(this.userMaster == '1'){
                return true;
            }

            this.router.navigate(['/']);
            return false;
        }

        // navigate to login page
        this.router.navigate(['/signin']);
        // you can save redirect url so after authing we can move them back to the page they requested
        return false;
    }
}
