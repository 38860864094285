import { Injectable } from '@angular/core';
import { User } from '../../shared/user';
import { throwError } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Tokens } from '../models/tokens';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { SIGNIN_URL } from '../models/globals'

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  readonly endpoint = SIGNIN_URL;
  headers = new HttpHeaders().set('Content-Type', 'application/json');
  currentUser = {};

  private readonly JWT_TOKEN = 'accessToken';
  //private readonly JWT_TOKEN = 'token';
  private readonly REFRESH_TOKEN = 'refreshToken';
  public loggedUser = new User();
  constructor(private http: HttpClient, public router: Router) { }

  // Sign-in
  signIn(user) {
    return this.http
      .post<any>(`${this.endpoint}/login`, user)
      .subscribe((res: any) => {
        //console.log(res);
        if (res.token) {
          this.saveToken(res);
          // saving loggenIn user
          // this.loggedUser.username = user.username;
          this.saveUser(res);
          this.router.navigate(['/home']);
        } else {
          this.router.navigate(['']);
        }
      });
  }
  isLoggedIn() {
    return localStorage.getItem(this.JWT_TOKEN) != null;
  }


  saveUser(res) {
    // console.log(res.user.role.name);
    localStorage.setItem('userId', res.user.id);

    if (res.user.role.name === 'Admin' || res.user.role.name === 'Super Admin') {
      localStorage.setItem('userIsMaster', '1');
    } else {
      localStorage.setItem('userIsMaster', '0');
    }

    // localStorage.setItem('userIsMaster', '1');
    // VALLE
    //console.log("Cust TZ: " + res.user.customer.timezone);
    localStorage.setItem('Timezone', res.user.customer.timezone);
    localStorage.setItem('userMapType', res.user.user_map_type);
    ///localStorage.setItem('userMapType', 'GoogleMaps');
    localStorage.setItem('cusName', res.user.customer.name);
    localStorage.setItem('cusId', res.user.customer_id);
    localStorage.setItem('userName', res.user.username);
    localStorage.setItem('roleName', res.user.role.name);
    // localStorage.setItem('userMapType', res.user.user_map_type);
  }

  refreshToken() {
    return this.http
      .post<any>(`${this.endpoint}/token`, {
        refreshToken: this.getRefreshToken()
      })
      .pipe(
        tap((tokens: Tokens) => {

          this.storeJwtToken(tokens.token);
        })
      );
  }

  getJwtToken() {
    return localStorage.getItem(this.JWT_TOKEN);
  }

  private saveToken(tokens: Tokens) {
    this.storeTokens(tokens);
  }

  public doLogoutUser() {
    this.removeTokens();
    /*
    return this.http
      .post<any>(`${this.endpoint}/logout`, {
        refreshToken: this.getRefreshToken()
      })
      .subscribe((res: any) => {});
      */
  }

  private getRefreshToken() {
    return localStorage.getItem(this.REFRESH_TOKEN);
  }

  private storeJwtToken(jwt: string) {
    localStorage.setItem(this.JWT_TOKEN, jwt);
  }

  private storeTokens(tokens: Tokens) {

    localStorage.setItem(this.JWT_TOKEN, tokens.token);
    //localStorage.setItem(this.REFRESH_TOKEN, tokens.refreshToken);
  }

  private removeTokens() {
    localStorage.removeItem(this.JWT_TOKEN);
    localStorage.removeItem(this.REFRESH_TOKEN);
    localStorage.removeItem('userId');
    localStorage.removeItem('userIsMaster');
    localStorage.removeItem('userMapType');
    localStorage.removeItem('cusName');
    localStorage.removeItem('cusId');
    localStorage.removeItem('userName');
  }
  isAuthenticated(): boolean {
    return (
      localStorage.getItem(this.JWT_TOKEN) != null && !this.isTokenExpired()
    );
  }
  isTokenExpired(): boolean {
    return false;
  }
  handleError(error) {
    // Handle errors
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
      window.alert(errorMessage);
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }
}
