export class User {
  userId: number;
  password: string;
  username: string;
  cusId: string;
  userIsMaster: number;
  userMapType: string;
  cusName: string;
  roles: string[];
}
