import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { delay, catchError, tap, map, retry } from 'rxjs/operators';

import { Poi } from '../models/poi';
import { Vehicle } from '../models/vehicle';
import { Geofence } from '../geofence';
import { htmlAstToRender3Ast } from '@angular/compiler/src/render3/r3_template_transform';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from './auth.service';

import * as L from 'leaflet';
import 'leaflet-control-geocoder';
import { AsyncValidatorFn, AbstractControl } from '@angular/forms';
import { GLOBAL_URL, RGEO_CODE } from '../models/globals';
import { RouteInfo } from '../models/route-info';
import { Alert } from '../models/alert';
import * as turf from '@turf/turf';
import { RouteService } from './route.service';
import { TimeZoneService } from './timezone.service';
import { Console } from 'console';

@Injectable({
  providedIn: 'root',
})

export class DataService {
  private alertsRules: any[] = [];
  private poi: any[] = [];
  private routes: RouteInfo[];
  private geofences: any;
  private userID: number;
  private userIsMaster: number;
  private cusId: string;
  readonly API_URL = GLOBAL_URL;
  readonly R_Geocode = RGEO_CODE;
  private zone = localStorage.getItem("Timezone");
  // API_URL = 'http://90.230.253.17:4002/api';

  constructor(
    private httpClient: HttpClient,
    private routeService: RouteService,
    private timeZoneService: TimeZoneService
  ) {
    // Create an array of values that
    this.cusId = localStorage.getItem('cusId');
    // Get the alert rules
    this.getAlertRules({ cus_id: this.cusId }).subscribe((response) => {

      this.alertsRules = response;
      // console.log(this.alertsRules);
    });

    // Get Point of interest layer
    this.getPoi({ cus_id: this.cusId }).subscribe((response) => {
      this.poi = response;
    });

    this.getGeofence({ cus_id: this.cusId }).subscribe(response => {
      this.geofences = response;
    });

    // get the routes
    this.routeService.getRoutes({
      cus_id: this.cusId,
    }).subscribe(response => {
      this.routes = response;
    });
  }

  getAlertRules(customer: any): Observable<any> {
    return this.httpClient
      .post<any>(`${this.API_URL}/get_alert_rules/`, customer)
      .pipe(
      // catchError(this.handleError([], 'Alert rules')),
      // retry(2)
    );
  }

  // Get Poi of Interest data
  getPoi(customer: { cus_id: any }): Observable<any[]> {
    return this.httpClient
      .post<any>(`${this.API_URL}/get_poi`, customer)
      .pipe(
        map((pois: any[]) =>
          pois.filter((poi: any) => {
            if (poi.poi_latitude !== 0 && poi.poi_latitude != null) {
              return poi;
            }
          })
        )
      );
  }

  createPoi(poi: any): Observable<any> {
    return this.httpClient
      .post<any>(`${this.API_URL}/put_poi/`, poi)
      .pipe(
      // catchError(this.handleCrudError('addPoi', poi))
    );
  }

  updatePoi(poi: any): Observable<any> {
    return this.httpClient
      .post<any>(`${this.API_URL}/update_poi/`, poi)
      .pipe(
      // catchError(this.handleCrudError('Update Poi', poi))
    );
  }

  deletePoi(poi: any): Observable<any> {
    return this.httpClient
      .post(`${this.API_URL}/delete_poi`, poi)
      .pipe(
      // catchError(this.handleCrudError('Delete Poi ', poi))
    );
  }

  // Get Geofence layer
  getGeofence(customer: { cus_id: any }): Observable<Geofence[]> {
    return this.httpClient
      .post<Geofence[]>(`${this.API_URL}/get_geofence`, customer)
      .pipe(
      // catchError(this.handleCustomError<Geofence[]>([])), retry(2)
    );
  }

  // update geofence data
  updateGeofence(data: any): Observable<any> {
    return this.httpClient
      .post<any>(`${this.API_URL}/update_geofence`, data)
      .pipe(catchError(this.handleCrudError('Geofence', data)), retry(2));
  }

  // Delete geofence data
  deleteGeofence(data: any): Observable<any> {
    return this.httpClient
      .post<any>(`${this.API_URL}/delete_geofence`, data)
      .pipe(
      // catchError(this.handleCrudError('Geofence', data)), retry(2)
    );
  }

  // create Geofence
  createGeofence(data: any): Observable<any> {
    return this.httpClient
      .post<any>(`${this.API_URL}/put_geofence`, data)
      .pipe(
      // catchError(error => {
      //    return throwError (
      //       new Error('Failed to geocode')
      //     );
      //   })
    );
  }

  // Get Vehicle data
  getVehicle(user: any): Observable<Vehicle[]> {
    return this.httpClient
      .post<Vehicle[]>(`${this.API_URL}/get_vehicle`, user)
      .pipe(
        map((vehicles: Vehicle[]) => {
          // console.log(vehicles);
          //var zone = localStorage.getItem("Timezone");
          var gmt = "GMT";
          //console.log("CustTimeZonw: " + this.zone);
          vehicles = this.timeZoneService.convertVehiclesTimezone(vehicles, gmt, this.zone);
          return vehicles.filter((vehicle: Vehicle) => {
            if (vehicle.dev_latitude !== 0 && vehicle.dev_latitude !== null) {
              return vehicle;
            }
          })
        }
        )
      );
  }

  // get Vehicle history
  getVehicleHistory(vehicle: any): Observable<any[]> {
    return this.httpClient
      .post(`${this.API_URL}/get_vehicle_history`, vehicle)
      .pipe(
        catchError(error => {
          throw (
            new Error('Failed to load Vehicle History: Reload the site')
          );

        }),
        // retry(2),
        map((response: any[]) => {
          // console.log({ response });
          let prev;
          let res = response.filter((position) => {
            if (prev) {
              if (prev.pos_inputs % 2 === 0 && position.pos_inputs % 2 === 0) {
              } else if (prev.pos_inputs === 2 && position.pos_inputs === 2) {
              } else if (
                prev.pos_inputs % 2 === 0 &&
                position.pos_inputs % 2 !== 0
              ) {
                prev = position;
                return prev;
              } else {
                prev = position;
                return position;
              }
            }
            prev = position;
          });
          // console.log({ response });
          // When the vehicles input is always is Zero
          // we send bakc the first and last coordinates so 
          // we can calculate a mileage of 0 and no movement in the reports
          if (res.length < 1 && response.length > 0) {
            res[0] = response[0];
            res[1] = response[response.length - 1];
          }
          return res;
        })
      );
  }

  // Violation reports
  getViolationHistory(vehicle: any): Observable<any> {
    return this.httpClient
      .post<any>(`${this.API_URL}/get_vehicle_alert`, vehicle)
      .pipe(
      // catchError(this.handleCrudError('Violation Report', []))
    );
  }

  // Geocode vehicle location
  /*
    r_geocoding(coord: any): Observable<any> {
      return this.httpClient
        .post<any>(`${this.R_Geocode}`, coord)
        .pipe(
        // catchError(this.handleCustomError<any[]>([]))
      );
    } */

  r_geocoding(coord: any): Observable<any> {
    //console.log("http://89.117.51.75:2322/reverse?lon=" + coord.lng + "&lat=" + coord.lat)
    //console.log(coord.lat);
    if (this.zone === "Africa/Lagos") {
      return this.httpClient
        .post<any>(`${this.R_Geocode}`, coord)
        .pipe(
        // catchError(this.handleCustomError<any[]>([]))
      );
    } else {
      return this.httpClient
        .get<any>("http://89.117.51.75:2322/reverse?lon=" + coord.lng + "&lat=" + coord.lat)
        .pipe(
        // catchError(this.handleCustomError<any[]>([]))
      );
    }
  }
  // Get geocoded data
  getgeocodedData(
    user: { userId: any; cus_id: any; userIsMaster: any },
    customer: { cus_id: any }
  ): Observable<any> {

    // Get the vehicle layer
    return this.getVehicle(user).pipe(
      map((response) => {
        //
        for (let vehicle of response) {
          vehicle.location = {};
          // Find the closes Point of interest
          // Calculate the distance to each, store results to an array

          const closestPoi = this.poi.map(
            (poi: { poi_latitude: any; poi_longitude: any }) => {
              const distance = L.latLng(
                vehicle.dev_latitude,
                vehicle.dev_longitude
              ).distanceTo(L.latLng(poi.poi_latitude, poi.poi_longitude));
              return distance;
            }
          );

          // create a copy of distance array
          const sortedPoiDistances = [...closestPoi];

          // Get the index of the hte closest Poi of interest
          const closestPoiIndex = closestPoi.indexOf(
            sortedPoiDistances.sort((a: number, b: number) => a - b)[0]
          );

          // Update the point of interest property with POi name and distance
          vehicle.poi =
            this.poi[closestPoiIndex].poi_name +
            ' (' +
            parseInt(closestPoi[closestPoiIndex], 10) +
            ' m)';
          // Update the Secondary Status property
          if (vehicle.vehicle_class === 'CONCRETE_MIXER') {
            switch (vehicle.dev_inputs) {
              case 0:
                //
                vehicle.sec_status = 'MIXER: IDLE';
                vehicle.status = 'IGN OFF';
                break;
              case 1:
                //
                vehicle.sec_status = 'MIXER: IDLE';
                vehicle.status = 'Stopped (IGN ON)';
                if (vehicle.dev_speed > 3) { vehicle.status = 'Normal Speed'; }
                break;
              case 2:
                vehicle.sec_status = 'MIXER:CHARGED';
                vehicle.status = 'Stopped (IGN OFF)';
                break;
              case 3:
                vehicle.sec_status = 'MIXER:CHARGED';
                vehicle.status = 'Stopped (IGN ON)';
                if (vehicle.dev_speed > 3) { vehicle.status = 'Normal Speed'; }
                break;
              case 4:
                vehicle.status = 'Stopped (IGN OFF)';
                vehicle.sec_status = 'MIXER:DISCHARGING';
                break;
              case 5:
                vehicle.sec_status = 'MIXER:DISCHARGING';
                vehicle.status = 'Stopped (IGN ON)';
                if (vehicle.dev_speed > 3) { vehicle.status = 'Normal Speed'; }
                break;
              default:
                vehicle.sec_status = vehicle.dev_inputs.toString();
                break;
            }
          } else {
            switch (vehicle.dev_inputs) {
              case 0:
                vehicle.status = 'IGN OFF';
                break;
              case 1:
                vehicle.status = 'Stopped (IGN ON)';
                if (vehicle.dev_speed > 10) { vehicle.status = 'Normal Speed'; }
                break;
              case 3:
                vehicle.status = 'Stopped (IGN ON)';
                if (vehicle.dev_speed > 10) { vehicle.status = 'Normal Speed'; }
                break;
              case 5:
                vehicle.status = 'Stopped (IGN ON)';
                if (vehicle.dev_speed > 10) { vehicle.status = 'Normal Speed'; }
                break;
              case 7:
                vehicle.status = 'Stopped (IGN ON)';
                if (vehicle.dev_speed > 10) { vehicle.status = 'Normal Speed'; }
                break;
              default:
                vehicle.status = 'IGN OFF';
                break;
            }
          }

          vehicle = this.updateStatusWithAlert(vehicle);


          // Handling the time: update vehicle status
          // OFF if it never sent a signal within the last 15 minutes
          const currentTime = new Date();
          const vehicleTime = new Date(vehicle.dev_gps_time);

          // currentTime.setHours(currentTime.getHours() - 3);
          currentTime.setMinutes(currentTime.getMinutes() - 15);
          // console.log({ currentTime, vehicleTime });
          if (vehicleTime < currentTime) {
            vehicle.status = 'OFF';


            vehicle.audio = false;
            vehicle.visual = false;

          }
        }

        return response;
      })
    );
    // .pipe(delay(1000));
  }

  updateStatusWithAlert(vehicle): any {
    // Speed alert
    // Handle speed and Get the alert value using device id

    const deviceAlerts = this.alertsRules.filter(dev => {
      return dev.dev_id === vehicle.dev_id;
    });

    // Update the vehicle status property
    if (deviceAlerts.length) {
      deviceAlerts.forEach((device: Alert) => {
        // Update the stats
        switch (device.alert_category) {
          case 'speed':
            // speed conditions
            if (vehicle.dev_speed < 10 && vehicle.dev_inputs === 1) {
              vehicle.status = 'Stopped (IGN ON)';
            } else if (
              vehicle.dev_speed > 10 &&
              vehicle.dev_speed < device.criteria_value
            ) {
              vehicle.status = 'Normal Speed';
            } else if (vehicle.dev_speed > device.criteria_value) {
              vehicle.status = 'Over Speed';
              vehicle = this.getAlertType(device, vehicle);

            } else {

            }

            return vehicle;
            break;
          case 'geofence':
            if (device.criteria_name.includes('geofence-relativity')) {
              // get the geofence
              const geofence = this.geofences.find((geofen: any) => geofen.geofence_id === device.object_id);

              if (geofence) {
                const point = turf.point([vehicle.dev_longitude, vehicle.dev_latitude]);
                const polygon = turf.polygon(this.getPolygonCoordinates(geofence));

                // determine if its within the geofence
                const within = turf.booleanWithin(point, polygon);

                // Check the left or entered geofence criteria
                if (device.criteria_value === 0 && within) {
                  vehicle.sec_status = 'Entered the geofence';

                  vehicle = this.getAlertType(device, vehicle);
                  return vehicle;

                } else if (device.criteria_value === 1 && !within) {
                  vehicle.sec_status = 'Left the geofence';

                  vehicle = this.getAlertType(device, vehicle);
                  return vehicle;

                } else {
                  return vehicle;
                }

              } else {
                // check the geofence speed
                if (vehicle.dev_speed > device.criteria_value) {
                  vehicle.status = 'Geofence Overspeeding';

                  vehicle = this.getAlertType(device, vehicle);
                  return vehicle;

                }

              }
            } else {
              return vehicle;
            }

            break;
          case 'routing':
            // get the route
            const route = this.routes.find(routed => routed.route_id === device.object_id);

            // compute distances
            const pt = turf.point([vehicle.dev_longitude, vehicle.dev_latitude]);
            const line = turf.lineString(this.wktToArray(route.coord));

            const distanceFromRoute = turf.pointToLineDistance(pt, line) * 1000;

            if (distanceFromRoute > device.criteria_value) {
              // updated the status
              vehicle.sec_status = 'off-route: ' + (distanceFromRoute - device.criteria_value).toFixed(0) + 'metres';

              vehicle = this.getAlertType(device, vehicle);
              return vehicle;

            } else {

              return vehicle;

            }

            break;
          default:
            // tslint:disable-next-line: no-bitwise
            if ((vehicle.dev_inputs & device.criteria_value) === device.criteria_value) {
              vehicle = this.getAlertType(device, vehicle);
              return vehicle;
            } else {

              return vehicle;
            }
            break;
        }
      });


      return vehicle;
    } else {
      if (vehicle.dev_speed > 3) {
        //
        vehicle.status = 'Normal Speed';
      }
      vehicle.visual = '';
      vehicle.audio = '';

      return vehicle;
    }

    // geofence alerts
    // route alerts

    return '';
  }

  // Return an alert based on the alert type
  getAlertType(device, vehicle) {
    switch (device.alert_type) {
      case 1:
        break;
      case 2:
        vehicle.audio = true;
        vehicle.visual = false;
        break;
      case 3:
        vehicle.audio = true;
        vehicle.visual = false;
        break;
      case 4:
        vehicle.audio = false;
        vehicle.visual = true;
        break;
      case 5:
        vehicle.visual = true;
        vehicle.audio = false;
        break;
      case 6:
        vehicle.visual = true;
        vehicle.audio = true;
        break;
      default:
        vehicle.audio = true;
        vehicle.visual = true;
        break;
    }

    return vehicle;
  }

  // object to array
  wktToArray(geometry): any[] {
    let coordinates = geometry.split('(')[1].replace(')', '').split(',');

    coordinates = coordinates.map(coord => {
      const cd = coord.split(' ');
      return cd.map(c => parseFloat(c));
    });

    return coordinates;
  }


  // Create an array
  getPolygonCoordinates(geofence): any {

    // Create array of coordinates from the geofence object
    const coordinates = geofence.polygon_geometry.map(coordindates => {
      return coordindates.map(coord => {
        return [coord.x, coord.y];
      });
    });

    return coordinates;
  }

  // Handle failed alert rules
  handleError(result: any[], dataStr: string) {
    return (error: any): any => {

      // add the error object
      result.push({ error: error.message });

      throwError(new Error(`Failed to load ${dataStr}: ${error.message}`));
      return of(result);
    };
  }

  handleCrudError(name: string, object: any) {
    return (error: any) => {

      throwError(new Error(`Failed to ${name}: ${error.message}`));
      return [];
      // return [{ error: name }];
    };
  }

  // Handle Errors for typed objects
  handleCustomError<T>(result?: T) {
    return (error: any) => {

      throwError(error);
      return of(result as T);
    };
  }

}