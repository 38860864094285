<app-navbar></app-navbar>
<div class="container-fluid">
    <div class="row">
        <div class="col-md-2">

            <div class="card mb-2">
                <div class="card-body text-center t-1">
                    <h2>{{vehicles?.length}}</h2>
                    Vehicles
                </div>

            </div>

            <div class="card mb-2">
                <div class="card-body text-center t-2 py-2">
                    <h2 class="t-2">{{totalDistance}}</h2>
                    Active Vehicles
                </div>
            </div>

            <div class="card mb-2">
                <div class="card-body text-center text-danger py-2">
                    <h2 class="text-danger">{{violations?.length}}</h2>
                    Violations
                </div>
            </div>

            <div class="card mb-2">
                <div class="card-body text-center t-2 py-2">
                    <h2 class="t-2">{{totalDistance}}</h2>
                    Total Distance
                </div>
            </div>

        </div>

        <div class="col-md-6">
            <div class="card">
                <h3 class="t-1 bg-t1 w100 px-2">Recent Violation</h3>
                <div class="card-body row">
                    <div class="col-md-6">
                        <div class="chart-wrapper">
                            <canvas baseChart width="200" height="120" [data]="pieChartData" [labels]="pieChartLabels"
                                [chartType]="pieChartType" [options]="pieChartOptions" [plugins]="pieChartPlugins"
                                [colors]="pieChartColors" [legend]="pieChartLegend" (chartClick)="chartClicked($event)">
                            </canvas>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <!-- Vehicle Violation -->
                        <h5>{{ selectedVehicleViolations?.dev_name }}</h5>
                        <!-- <ul class="list-group">
                            <li class="list-group-item " *ngFor="let violation of selectedVehicleViolations?.violations">
                                <small>{{ violation?.criteria_name}}</small> 
                                <small>{{ violation?.criteria_category}}</small>
                            </li>
                        </ul> -->
                        <table class="table">
                            <thead>
                                <th><small>Criteria</small></th>
                                <th><small>Value</small></th>
                                <th><small>Actual Value</small></th>
                                <th><small>Alert Time</small></th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let violation of selectedVehicleViolations?.violations">
                                    <td><small>{{ violation?.criteria_name}}</small></td>
                                    <td><small>{{ violation?.criteria_value}}</small></td>
                                    <td><small>{{ violation?.actual_value}}</small></td>
                                    <td><small>{{ violation?.alert_time}}</small></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        </div>
        <div class="col-md-4 distance">
            <div class="card">
                <h3 class="t-1 bg-t1 w100 px-2">Distance Travelled</h3>

                <div class="card-body">
                    <div class="bar-wrapper">
                        <canvas baseChart width="100%" height="400" [datasets]="barChartData" [labels]="barChartLabels"
                            [options]="barChartOptions" [plugins]="barChartPlugins" [legend]="barChartLegend"
                            [colors]="lineChartColors" [chartType]="barChartType">

                        </canvas>
                    </div>
                </div>
            </div>
        </div>
    </div>


</div>