import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { User } from '../models/user';
import { UserDevice } from '../models/user-device';
import { catchError } from 'rxjs/operators';
import { GLOBAL_URL } from '../models/globals';
@Injectable({
  providedIn: 'root'
})
export class UserServiceService {
  API_URL = GLOBAL_URL;

  constructor(private http: HttpClient) { }

  getUsers(customer): Observable<User[]> {
    return this.http
      .post<User[]>(`${this.API_URL}/get_users`, customer)
      .pipe(
        catchError(error => throwError(error))
      );
  }

  // User CRUD
  createUser(user: User): Observable<any> {
    //console.log(user);
    return this.http
      .post<User>(`${this.API_URL}/create_user`, user)
      .pipe(
        catchError(error => throwError(error))
      );
  }

  updateUser(user: User): Observable<any> {
    return this.http
      .post<User>(`${this.API_URL}/update_user`, user)
      .pipe(
        catchError(error => throwError(error))
      );
  }

  deleteUser(user): Observable<any> {
    return this.http
      .post<any>(`${this.API_URL}/delete_user`, user)
      .pipe(
        catchError(error => throwError(error))
      );
  }

  // DEVICES CRUD
  getUserDevices(user): Observable<UserDevice[]> {
    return this.http
      .post<UserDevice[]>(`${this.API_URL}/get_user_devices`, user)
      .pipe(
        catchError(error => throwError(error))
      );
  }

  addUserDevice(device: UserDevice): Observable<any> {
    return this.http
      .post<any>(`${this.API_URL}/add_user_device`, device)
      .pipe(
        catchError(error => throwError(error))
      );
  }

  deleteUserDevice(device): Observable<any> {
    return this.http
      .post<UserDevice>(`${this.API_URL}/delete_user_device`, device)
      .pipe(
        catchError(error => throwError(error))
      );
  }

}
