import {
  Component,
  OnInit,
  OnDestroy,
  AfterViewInit,
  ViewChild,
  ElementRef
} from '@angular/core';
import { FormControl } from '@angular/forms';
import * as L from 'leaflet';
import 'leaflet-rotatedmarker';
import 'leaflet-control-geocoder';
import 'leaflet.layergroup.collision';
import '../../shared/L.Control.MousePosition';

import { Subscription, timer, Observable, fromEvent, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';

import { Poi } from '../models/poi';
import { Vehicle } from '../models/vehicle';
import { Geofence } from '../geofence';

import { DataService } from '../services/data.service';

import * as labelgun from 'labelgun';

// var moment = require('moment-timezone');

var hideLabel = function (label) {
  label.labelObject.style.opacity = 0;
  label.labelObject.style.transition = 'opacity 0s';
};
var showLabel = function (label) {
  label.labelObject.style.opacity = 1;
  label.labelObject.style.transition = 'opacity 1s';
};

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit, AfterViewInit, OnDestroy {
  // class properties
  private map: L.map;
  private vehicleLocation: any;
  private baseLayers: any = {};
  private overlayLayers: any = {};

  private poi: Poi[];

  private geofence: Geofence[];

  vehicleSearch: any;
  public geocodedLocation: any;

  // Leaflet layers
  private vehicleFeatureGroup: L.FeatureGroup;
  private iconLabels: L.featureGroup;
  private vehicleLayerGroup: L.layergroup;

  // Point of interest layer
  private poiFeatureGroup: L.FeatureGroup;

  filter = new FormControl('');
  subscription: Subscription;

  // User Detail
  userMaster: any;
  userID: any;
  cusId: any;
  userRole: any;
  username: any;
  customerName: any;
  userMapType: any; // Details on network status
  public onlineEvent: Observable<Event>;
  public offlineEvent: Observable<Event>;
  public subscriptions: Subscription[] = [];

  public connectionStatus = 'online';
  public widthVal = 940;
  private zone = localStorage.getItem("Timezone");
  // Zoom event
  zoomEvent: Observable<Event>;
  zoomLevel: number;

  // Marker click event
  clickEvent: Observable<Event>;

  // Destroy the subscriber
  private onDestroy$ = new Subject<void>();

  // Check if data i loaded
  public isDataReady: boolean = false;

  // Smooth table updates
  trackByVehicle(vehicle: Vehicle): number {
    return vehicle.dev_id;
  }

  // Audio element
  @ViewChild('audioElement', { static: false }) public _audioRef: ElementRef;
  private audio: HTMLMediaElement;
  audioStatus: string = 'paused';
  audioControl: string = 'mute';

  labelEngine = new labelgun.default(hideLabel, showLabel);

  constructor(
    private _vehicleService: DataService,
    private spinner: NgxSpinnerService
  ) {
    // Get the user details
    this.userMaster = localStorage.getItem('userIsMaster');
    this.userID = localStorage.getItem('userId');
    this.cusId = localStorage.getItem('cusId');
    this.username = localStorage.getItem('userName');
    this.customerName = localStorage.getItem('cusName');
    this.userMapType = localStorage.getItem('userMapType');
  }

  ngOnInit() {
    // Initialize a spinner
    // console.log(window.innerWidth);
    this.spinner.show();

    // Initialize network connection details
    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');

    this.subscriptions.push(
      this.onlineEvent.subscribe(() => {
        // (event);
        this.connectionStatus = 'online';
      })
    );

    this.subscriptions.push(
      this.offlineEvent.subscribe(() => {
        this.connectionStatus = 'offline';
      })
    );

    // Subscribe to a service after every ten seconds
    this.subscription = timer(100, 40000)
      .pipe(
        switchMap(() =>
          this._vehicleService.getgeocodedData(
            {
              userId: this.userID,
              cus_id: this.cusId,
              userIsMaster: this.userMaster
            },
            { cus_id: this.cusId }
          )
        )
      )
      .subscribe(
        response => {
          //  check if vehicel Location if undefined
          if (this.vehicleLocation === undefined) {

            if (response.length === 0) {
              this.spinner.hide();
            } else {
              // geocode all the vehicle location
              this.vehicleLocation = response;

              // geocode the vehicle address
              this.vehicleLocation.forEach(vehicle => {
                return this.geocodeVehicleData(vehicle);
              });

              // console.log(this.vehicleLocation);
              this.vehicleSearch = response;

              setTimeout(() => this.loadDataToMap(), 5000);
              this.isDataReady = true;
            }

          } else {
            // if they are similar don't update details
            this.updateVehicleLocation(response);
          }

          // Update the audio
          this.updateAudioStatus();
        },
        error => {
          // Handle any errors
          // console.log('Failed load the data' + error);
          window.alert('Failed load the data ' + error);
        },
        () => {
          // Loaded"
        }
      );

    // Initialize the map
    this.InitMap();
    // Table filters
    this.filter.valueChanges.subscribe(response => {
      this.vehicleSearch = this.search(response);
    });
  }

  // Play or pause the audio
  playOrPause(): void {
    // check the audio status and audioControl status
    if (this.audioStatus == 'playing' && this.audioControl == 'mute') {
      this.audio.play();
      this.audioControl = 'volume';
    } else {
      this.audio.pause();
      this.audioControl = 'mute';
    }
  }

  // Check the notification type
  updateAudioStatus(): void {
    // Update the audio status
    this.vehicleLocation.forEach(vehicle => {
      if (vehicle.audio) {
        this.audioStatus = 'playing';

        // this.playOrPause();
        // break;
      }
    });
  }

  // LifeCycle Methods
  ngAfterViewInit() {
    // Initialize
    this.audio = this._audioRef.nativeElement;

    // Get the Point of Interest Data

    this._vehicleService
      .getPoi({ cus_id: this.cusId })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(response => {
        // check if the there is response error
        if (response[0].error) {
          alert()
        } else {
          this.poi = response;
          // Loa the data to the map
          if (this.poi.length > 0) {
            this.loadPoiToMap();
          }
        }

      });

    // Subscribe to geofence layer
    this._vehicleService
      .getGeofence({ cus_id: this.cusId })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(response => {
        this.geofence = response;

        // load the geofence to the map
        if (this.geofence.length > 0) {
          this.loadGeofenceToMap();
        }
      });
  }

  // Table Filter
  private search(text: string): Vehicle[] {
    //  filter the vehicle Location using the search string
    return this.vehicleLocation.filter(vehicle => {
      const term = text.toLowerCase();

      // return the matching entities
      return vehicle.dev_name.toLowerCase().includes(term);
    });
  }

  public InitMap(): void {
    // Check if the map container is initialized
    if (this.map) {
      this.map.remove();
    }

    // create a map object
    this.map = L.map('map', {
      center: [6.437063, 3.413717],
      zoom: 18,
      minZoom: 4,
      maxZoom: 25
    });
    //L.control.mousePosition().addTo(this.map);
    // Add a open streetmap tile layer
    const osm = L.tileLayer(
      'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      {
        maxZoom: 22,
        attribution:
          '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
      }
    );

    // Mapbox tile attribution
    const mbAttr =
      'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, ' +
      '<a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, ' +
      'Imagery Â© <a href="https://www.mapbox.com/">Mapbox</a>';

    // mapbox tile url
    // tslint:disable-next-line: max-line-length
    const mbUrl =
      'https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token=pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4NXVycTA2emYycXBndHRqcmZ3N3gifQ.rJcFIG214AriISLbB6B5aw';

    // add mapbox tile layer
    const grayscale = L.tileLayer(mbUrl, {
      id: 'mapbox.terrain-vector',
      attribution: mbAttr
    });

    const Esri_WorldImagery = L.tileLayer(
      'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
      {
        attribution:
          'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
      }
    );
    // add google mapstitle layer
    const googleStreets = L.tileLayer(
      'http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',
      {
        maxZoom: 22,
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
      }
    );

    // Check user map type
    if (this.userMapType === 'GoogleMaps') {
      googleStreets.addTo(this.map);
    } else {
      osm.addTo(this.map);
    }
    L.control.mousePosition().addTo(this.map);
    // add the tile layer to baselayer object
    this.baseLayers['OSM'] = osm;
    this.baseLayers['Google Maps'] = googleStreets;
    this.baseLayers['Sattelite'] = Esri_WorldImagery;

    // Initalize vehicle featureGroup
    this.vehicleFeatureGroup = L.featureGroup();
    this.iconLabels = L.featureGroup();

    // Create a layer group for iconlabel and the respective vehicles
    this.vehicleLayerGroup = L.layerGroup([
      this.iconLabels,
      this.vehicleFeatureGroup
    ]);

    this.iconLabels.bringToFront();

    // add the layer group to the overlay control
    this.overlayLayers['Vehicles'] = this.vehicleLayerGroup;
    // Map zoom event
    this.zoomEvent = fromEvent(this.map, 'zoomend');

    // Initialize map zoom level
    this.zoomLevel = this.map.getZoom();

    // get the map zoom level
    // this.zoomEvent.subscribe(() => {

    //   // add or remove POI layer
    //   this.addRemovePoi();

    // });

    // reset layer labels
    this.resetLabel([this.vehicleFeatureGroup]);

    // reset labels on zoomend
    this.map.on("zoomend", () => {
      this.poiFeatureGroup ?
        this.resetLabel([this.vehicleFeatureGroup, this.poiFeatureGroup]) :
        this.resetLabel([this.vehicleFeatureGroup]);

      // update zoom level
      this.zoomLevel = this.map.getZoom();
      this.addRemovePoi();

      this.map.closePopup();
    });

    //  Add a geocode
    L.Control.geocoder({
      position: 'topleft'
    }).addTo(this.map);
  }

  // Geocode the data
  public geocodeVehicleData(vehicle: any): any {
    // Get lat abd lng
    const lat = vehicle.dev_latitude;
    const lng = vehicle.dev_longitude;

    // check for invalid lat and lng
    if (lng == 0 || lat == 0) {
      vehicle['location'] = {};
    } else {
      if (this.zone === "Africa/Lagos") {
        // check for invalid lat and lng
        if (lng == 0 || lat == 0) {
          vehicle['location'] = {};
        } else {
          // Subscribe to reverse geocoding service
          this._vehicleService
            .r_geocoding({
              lat: lat.toString(),
              lng: lng.toString()
            })
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(res => {
              // Update the location field
              //console.log(res[0]);
              vehicle['location']['county'] = res[0]['place'] ? res[0]['place'] : [];
              vehicle['location']['name'] = res[0]['street'] ? res[0]['street'] : [];
              vehicle['location']['city'] = res[0]['region'] ? res[0]['region'] : [];
              vehicle['location']['state'] = res[0]['state'] ? res[0]['state'] : [];
              vehicle['location']['country'] = res[0]['country'] ? res[0]['country'] : [];
              //vehicle['location'] = res[0] ? res[0] : {};
              //console.log(vehicle);
            });
        }
      } else {
        // Subscribe to reverse geocoding service
        this._vehicleService
          .r_geocoding({
            lat: lat.toString(),
            lng: lng.toString()
          })
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(res => {
            // Update the location field
            if (!(res.features[0])) { vehicle['location'] = {} } else {
              //console.log(res.features[0]);
              if (!res.features[0].properties.county) {
                res.features[0].properties.county = res.features[0].properties.city;
              }
              let obj = res.features[0].properties;
              vehicle['location'] = obj ? obj : {};
            };
          });
      }
    }

    return vehicle;
  }

  public loadDataToMap(): void {
    // this.map.setCenter([this.vehicleLocation[0].dev_latitude, this.vehicleLocation[0].dev_longitude]);
    this.spinner.hide();
    this.vehicleFeatureGroup.clearLayers();
    this.iconLabels.clearLayers();

    // Create Feature Group
    this.vehicleLocation.forEach(element => {
      // Popup content
      // const popupContent = this.createPopupContent(element);

      // Create a marker object
      const marker = L.marker([element.dev_latitude, element.dev_longitude], {
        rotationAngle: parseFloat(element.dev_heading),
        icon: this.getIcon(element.status),
        title: element.dev_name,
        clickable: true
      })
        .bindTooltip(element.dev_name, {
          permanent: true,
          className: 'vehicle-tooltip',
          offset: [4, -0],
          direction: 'right',
          opacity: 0.8
        })
        .addTo(this.vehicleFeatureGroup);

      // Click event to display the popup
      marker.on('click', e => this.onClickEvent(e));
    });

    //Add Vehicle layer group
    let i = 0;
    this.vehicleFeatureGroup.eachLayer(layer => {
      this.addLabel(layer, i);
      i++;
    });

    this.vehicleLayerGroup.addTo(this.map);

    // fit map to vehicle layer bound()
    if (this.vehicleLocation[1]) {
      this.map.fitBounds(this.vehicleFeatureGroup.getBounds());
    } else {
      this.map.panTo(new L.LatLng(this.vehicleLocation[0].dev_latitude, this.vehicleLocation[0].dev_longitude));
    }

    // reload the data

    // Update the audio status
  }

  public createPopupContent(element: any): string {
    return `<div class="">
          <div class="" style="height:280px; font-size=10px; width:230px;">
              <p><strong>Vehicle</strong><br>
              <strong class="ml-2">ID:</strong> ${element.dev_id}<br>
              <strong class="ml-2">Name: </strong> ${element.dev_name}<br>
              <strong class="ml-2">License Plate: </strong> ${element.dev_license_plate}<br>

              <strong>Driver</strong><br>
              <strong class="ml-2">Name: </strong> ${element.dev_driver_name}<br>
              <strong class="ml-2">Phone: </strong> ${element.dev_driver_mobile}<br>

              <strong>Location</strong><br>
              <strong class="ml-2">GPS Time:</strong> ${element.dev_gps_time}<br>
              <strong class="ml-2">Speed: </strong> ${element.dev_speed} km/h<br>
              <strong class="ml-2">Direction</strong> ${element.dev_heading}<br>

              <strong>Address</strong><br>
              <strong class="ml-2">Street Address</strong> ${element.location.name}<br>
              <strong class="ml-2">Place</strong> ${element.location.county}, ${element.location.city}<br>
              <strong class="ml-2">State, Country</strong> ${element.location.state}, ${element.location.country}<br>
              <strong class="ml-2">P.O.I:</strong> ${element.poi}</p>

          </div>
        </div>`;
  }

  // Bind the popup on layer click
  private onClickEvent(e) {
    const layer = e.target;

    const vh = this.vehicleSearch.find(
      vs => vs.dev_name == layer.options.title
    );
    layer.bindPopup(this.createPopupContent(vh));
  }

  // Fly to marker location and open popup on table click
  public flyToVehicle(name: string): void {
    // Iterate through the feature group
    this.vehicleFeatureGroup.eachLayer(layer => {
      // get the corresponding layer

      if (layer.options.title === name) {
        /*
        var [...coordinates] = [layer.getLatLng()];
        console.log(layer.getLatLng().lat + 0.05);
        var markerBounds = L.latLngBounds([...coordinates]);
        this.map.fitBounds(markerBounds);*/

        //this.map.flyTo(layer.getLatLng(), 18);
        // this.map.setView(layer.getLatLng(), 18, { animate: true });
        this.map.panTo(layer.getLatLng());
        this.map.setZoom(16);

        // Create the popup on click/ fly
        const vehicle = this.vehicleSearch.find(
          vehicle => vehicle.dev_name == name
        );
        layer.bindPopup(this.createPopupContent(vehicle));
        layer.openPopup();
      }
    });
  }

  private getIcon(status: string): L.Icon {
    // Conditional fomating: return a marker depending on vehicle status
    let iconUrl = '';
    if (status === 'Over Speed') {
      iconUrl = '/assets/red.png';
    }
    // tslint:disable-next-line: one-line
    else if (status === 'Normal Speed') {
      iconUrl = '/assets/green.png';
    }
    // tslint:disable-next-line: one-line
    else if (status === 'Stopped (IGN ON)') {
      iconUrl = '/assets/blue.png';
    }
    // tslint:disable-next-line: one-line
    else if (status === 'OFF') {
      iconUrl = '/assets/orange.png';
    }
    // tslint:disable-next-line: one-line
    else {
      iconUrl = '/assets/grey.png';
    }

    return L.icon({
      iconUrl: iconUrl,
      // iconSize: [35, 45],
      // popupAnchor: [-2, -2],
      iconSize: [25, 30],
      popupAnchor: [-3, -3]
    });
  }

  private updateVehicleLocation(data: any) {
    // Filter the changed data
    let change = data.filter(datum => {
      let vehicle = this.vehicleLocation.find(
        vehicle => vehicle.dev_id == datum.dev_id
      );
      if (vehicle.dev_gps_time.toString() != datum.dev_gps_time) {
        return datum;
      }
    });

    // Iterate over each layer in the feature group
    this.vehicleFeatureGroup.eachLayer(layer => {
      // Iterate on Each data object
      let datum = change.find(datum => layer.options.title == datum.dev_name);
      if (datum) {
        // Update the lat and lng
        layer.setLatLng(L.latLng(datum.dev_latitude, datum.dev_longitude));

        // Change the icon
        layer.setIcon(this.getIcon(datum.status));
        // Update the orientation
        layer.setRotationAngle(datum.dev_heading);
        layer.closePopup();
      }
    });

    // Update the divICon
    this.iconLabels.eachLayer(layer => {
      change.forEach(datum => {
        if (layer.options.title === datum.dev_name) {
          layer.setLatLng(L.latLng(datum.dev_latitude, datum.dev_longitude));
        }
      });
    });

    // Update the table data
    this.vehicleSearch = data.map(datum => {
      datum = this.geocodeVehicleData(datum);
      return datum;
    });

    // console.log(this.vehicleSearch);

    // this.vehicleSearch = response;
    this.vehicleLocation = this.vehicleSearch;
  }

  // Point of interest Layer
  private loadPoiToMap(): void {
    // creata an icon object
    const poiIcon = L.icon({
      iconUrl: '/assets/poi.png',
      iconSize: [25, 30],
      popupAnchor: [-3, -3]
    });

    // create a POI feature group
    this.poiFeatureGroup = L.featureGroup();

    // Add markers and labels to feature groups

    this.poi.forEach(poi => {
      // console.log(poi);
      L.marker([poi.poi_latitude, poi.poi_longitude], {
        icon: poiIcon
      })
        .bindTooltip(`${poi.poi_name}`, {
          permanent: true,
          className: 'poi-tooltip',
          offset: [4, -0],
          direction: 'right',
          opacity: 0.8
        })
        .addTo(this.poiFeatureGroup);

      // Create a iconLabel
      // const icon = L.divIcon({
      //   className: 'card-body',
      //   html:
      //     '<div class="card-title" style="width:120px; font-size=12px; text-shadow: 3px 2px #DCDCDC; ">' +
      //     poi.poi_name +
      //     '</div>'
      // });

      // // add the label marker to the poinfeature group
      // L.marker([poi.poi_latitude, poi.poi_longitude], {
      //   icon: icon
      // }).addTo(this.poiFeatureGroup);
    });

    // add the label to label gun
    let i = 0;
    this.poiFeatureGroup.eachLayer(layer => {
      this.addLabel(layer, i);
      i++;
    });


    // Add point featuregroup to map
    this.addRemovePoi();

    // add point featuregroup to overlaylayer object
    this.overlayLayers['POI Layers'] = this.poiFeatureGroup;

    // Add layer control to the map
    L.control.layers(this.baseLayers, this.overlayLayers).addTo(this.map);
  }

  // add or remove POIs depending on the zoom level
  public addRemovePoi(): void {
    // Add layer for zoom level greater than 12
    if (this.zoomLevel > 12) {

      if (this.map.hasLayer(this.poiFeatureGroup)) {
        // recalculate the label
        this.resetLabel([this.vehicleFeatureGroup, this.poiFeatureGroup]);
      } else {

        this.poiFeatureGroup.addTo(this.map);
        this.resetLabel([this.vehicleFeatureGroup, this.poiFeatureGroup]);
      }

    } else {
      this.map.removeLayer(this.poiFeatureGroup);
      this.resetLabel([this.vehicleFeatureGroup]);
    }
  }

  private loadGeofenceToMap(): void {
    // Create array of coordinates from the geofence object
    this.geofence.forEach(feature => {
      feature.polygon_geometry = feature.polygon_geometry.map(coordindates => {
        return coordindates.map(coord => {
          return [coord.y, coord.x];
        });
      });
    });
    // Geofence Feature Group
    const geofenceFeatureGroup = L.featureGroup();

    // Add geofences to map
    this.geofence.forEach(geofence => {
      // create polygon
      new L.Polygon(geofence.polygon_geometry, {
        color: geofence.color
      })
        .bindPopup(
          `<div class="">
          <div class="text-center">
            <h6 class="card-title">${geofence.geofence_name}</h6>
          </div>
          <div class="row">
             <table class="table">
              <tr>
              <td>Shape</td>
              <td>${geofence.geofence_shape}</td>
              </tr>

              <tr>
              <td>Rule</td>
              <td>${geofence.geofence_rule}</td>
              </tr>
            </table>
          </div>
        </div>`
        )
        .addTo(geofenceFeatureGroup);
    });

    // Add the geofence to the map
    geofenceFeatureGroup.addTo(this.map);

    // Add the geofence layer to the overlayLayer object
    this.overlayLayers['Geofence'] = geofenceFeatureGroup;
  }

  // reset label gun labels
  resetLabel(markers) {
    this.labelEngine.reset();
    var i = 0;
    for (var j = 0; j < markers.length; j++) {
      markers[j].eachLayer(label => {
        this.addLabel(label, ++i);
      });


    }
    this.labelEngine.update();
  }

  // addLabel
  addLabel(layer, id) {
    // This is ugly but there is no getContainer method on the tooltip :(
    if (layer.getTooltip()) {
      var label = layer.getTooltip()._source._tooltip._container;
      if (label) {

        // We need the bounding rectangle of the label itself
        var rect = label.getBoundingClientRect();

        // We convert the container coordinates (screen space) to Lat/lng
        var bottomLeft = this.map.containerPointToLatLng([rect.left, rect.bottom]);
        var topRight = this.map.containerPointToLatLng([rect.right, rect.top]);
        var boundingBox = {
          bottomLeft: [bottomLeft.lng, bottomLeft.lat],
          topRight: [topRight.lng, topRight.lat]
        };

        let weight = Math.round(Math.random() * (5 - 1) + 1);

        // Ingest the label into labelgun itself
        this.labelEngine.ingestLabel(
          boundingBox,
          id,
          weight, // Weight
          label,
          "Test " + id,
          false
        );

        // If the label hasn't been added to the map already
        // add it and set the added flag to true
        if (!layer.added) {
          layer.addTo(this.map);
          layer.added = true;
        }
      }
    }
  }
  hideShow() {
    //console.log('HIDESHOW');
    var x = document.getElementById("mySide");
    //console.log('xvalue: ' + x.style.display);

    if (x.style.display === "none") {
      x.style.display = "block";
      this.widthVal = 940;
      // Calculate the offset
      var offset = this.map.getSize().x * 0.5;
      // Then move the map
      this.map.panBy(new L.Point(+offset, 0), { animate: false });
    } else {
      x.style.display = "none";
      this.widthVal = (window.innerWidth);
      // Calculate the offset
      var offset = this.map.getSize().x * 0.5;
      // Then move the map
      this.map.panBy(new L.Point(-offset, 0), { animate: false });
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    // console.log(this.subscription.closed);

    // Unsubscribe to
    this.onDestroy$.next();
    this.onDestroy$.complete();

    // Unsubscribe to any service
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }
}
