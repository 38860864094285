<div class="container-fluid">

    <div class="row">

        <div class="col-md-3">
            <ngx-spinner type="ball-clip-rotate-multiple" size="medium" bdColor="#fff" bdOpacity=0.7 color="#333"
                [fullScreen]="false">
                <p class="loading text-white">{{ spinnerStatus }}</p>
            </ngx-spinner>

            <!-- List of users -->
            <h3 class="t-1  bg-t1 w100 px-2">Current Users</h3>

            <!-- search bar -->
            <form action="" class="mb-1">
                <div class="input-group input-group-sm">
                    <input type="search" name="" id="" class="form-control form-control-sm" placeholder="Filter User"
                        [formControl]="userSearch">

                    <div class="input-group-prepend">
                        <span class="input-group-text">
                            <i class="fa fa-search" aria-hidden="true"></i>
                        </span>
                    </div>
                </div>
            </form>

            <div class="list-group" id="user-list">
                <li class="list-group-item d-flex justify-content-between" *ngFor="let user of userFilter"
                    (click)="getUserDetails(user.username)">
                    {{ user.username }}
                    <p>{{ user.email}}</p>
                </li>
            </div>

        </div>

        <div class="col-md-6">
            <!-- Devices -->
            <div class="row mt-2">

                <div class="col-md-5">
                    <p class="t-2 w-100 bg-t1 pl-2">Available Devices</p>
                    <small class="t-2">Click to add device</small>

                    <!-- <div class="list-group devices">
                        <li class="list-group-item d-flex justify-content-between" *ngFor="let vehicle of availableDevices"
                            (click)="addToUserDevice(vehicle.dev_id,content)">
                            {{  vehicle.dev_name }}
                            <p>{{ vehicle.dev_class }}</p>
                        </li>
                    </div> -->

                    <select name="" id="" class="list-group devices w-100" [formControl]="availableVehicleControl"
                        multiple>
                        <option class="list-group-item d-flex justify-content-between" value="{{vehicle?.dev_id}}"
                            *ngFor="let vehicle of availableDevices">
                            {{ vehicle?.dev_name }}
                        </option>
                    </select>

                </div>

                <div class="col-md-2 pt-5">
                    <!-- add or a single device -->
                    <button class="btn bg-outline-t1 mt-5 mb-2" (click)="assignVehicleToUser(content)">
                        <i class="fa fa-arrow-right" aria-hidden="true"></i>
                    </button>
                    <button class="btn bg-outline-t1 mb-2" (click)="assignAllVehiclesToUser(content)">
                        <i class="fa fa-arrow-right" aria-hidden="true"></i>
                        <i class="fa fa-arrow-right" aria-hidden="true"></i>
                    </button>

                    <!-- remove all or single device -->
                    <button class="btn bg-outline-t1 mb-2" (click)="removeFromUserVehicles(content)">
                        <i class="fa fa-arrow-left" aria-hidden="true"></i>
                    </button>

                    <button class="btn bg-outline-t1" (click)="removeAllUserVehicles(content)">
                        <i class="fa fa-arrow-left" aria-hidden="true"></i>
                        <i class="fa fa-arrow-left" aria-hidden="true"></i>
                    </button>

                </div>
                <div class="col-md-5">
                    <p class="t-2 w-100 bg-t1 pl-2">User Devices</p>
                    <small class="t-2">Click to remove device</small>

                    <!-- <div class="list-group devices">
                        <li class="list-group-item d-flex justify-content-between" *ngFor="let vehicle of userDevices">
                            (click)="removeDevice(vehicle.dev_id, content)">
                            {{  vehicle.dev_name }}
                            <p>{{ vehicle.dev_class }}</p>
                        </li>
                    </div> -->

                    <select name="" id="" class="list-group devices w-100" multiple [formControl]="userVehicleControl">
                        <option class="list-group-item d-flex justify-content-between"
                            *ngFor="let vehicle of userDevices" value="{{vehicle?.dev_id}}">
                            {{ vehicle?.dev_name }}
                            <p>{{ vehicle?.dev_class }}</p>
                        </option>
                    </select>

                </div>
            </div>


        </div>

        <div class="col-md-3">
            <h3 class="t-1 bg-t1 w100 pl-2">User Details</h3>
            <!-- User Details -->
            <div class="card" *ngIf="selectedUser">
                <div class="card-header d-flex justify-content-between">
                    <h3 class="card-title ml-2">{{ selectedUser.username}}</h3>
                    <!-- action -->
                    <div class="mr-2" *ngIf="selectedUser">

                    </div>
                </div>
                <div class="card-body">
                    <table class="table">
                        <tbody>
                            <tr>
                                <td>Map Type</td>
                                <td>{{ selectedUser.user_map_type }}</td>
                            </tr>
                            <tr>
                                <td>Marker Type</td>
                                <td>{{ selectedUser.user_marker_set }}</td>
                            </tr>

                            <tr>
                                <td>User Is Master</td>
                                <td *ngIf="selectedUser.user_is_master == 0">
                                    True
                                </td>
                                <td *ngIf="selectedUser.user_is_master != 0">
                                    False
                                </td>
                            </tr>

                        </tbody>

                    </table>

                </div>
            </div>
        </div>

    </div>
</div>

<!-- Modal to listen to user -->
<ng-template #content let-modal>
    <div class="modal-body text-center text-white">
        <p>{{ spinnerStatus }}</p>
    </div>
    <div class="btn-group my-1">
        <button type="button" class="btn btn-sm mx-2" [ngClass]="{
      'bg-t1':this.action != 'Delete',
      'btn-danger':this.action == 'Delete'
    }" (click)="modal.close(action)">{{action}}</button>
        <button type="button" class="btn bg-t3 btn-sm mx-2" (click)="modal.close('Cancel')">Cancel</button>
    </div>
</ng-template>