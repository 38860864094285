<div class="container-fluid">
    <div class="row">
        <div class="col-md-3">
            <ngx-spinner 
                size="medium" type="ball-clip-rotate-multiple" bdColor="#fff" [fullScreen]="false" bdOpacity=0.5
                color="#333">
                <p class="t-2">{{ spinnerStatus }}</p>
            </ngx-spinner>

            <!-- List of users -->
            <h3 class="t-1  bg-t1 w100 px-2">Current Routes</h3>

            <!-- search bar -->
            <form action="" class="mb-1">
                <div class="input-group input-group-sm">
                    <input type="search" name="" id="" class="form-control form-control-sm" placeholder="Filter Route"
                        [formControl]="vehicleSearch">
            
                    <div class="input-group-prepend">
                        <span class="input-group-text">
                            <i class="fa fa-search" aria-hidden="true"></i>
                        </span>
                    </div>
                </div>
            </form>

            <div class="list-group devices">
                <li class="list-group-item d-flex justify-content-between" *ngFor="let route of routes"
                    (click)="getVehicleRouteInformation(route?.route_id)">
                    {{  route?.name }}
                </li>
            </div>
         
            
        </div>

        <div class="col-md-9">
            <div id="map">

            </div>

            <!-- get Route information -->
            <!-- List of vehicle routes -->
            <h3 class="t-1  bg-t1 w100 px-2">Vehicle Routes <span class="alert alert-warning ml-2">{{ routeError }}</span></h3>
           
            <div class="row">
                <div class="col-md-12">
                    <!-- Form -->
                    <button class="btn btn-sm btn-danger ml-2" *ngIf="selectedRoute?.name"
                        (click)="deleteRoute(content,selectedRoute?.route_id)">Delete</button>

                    <button class="btn btn-sm bg-t3 ml-2" (click)="resetForm()" *ngIf="routeForm.get('routeName').value">Reset Form</button>
                    <button class="btn btn-sm bg-t1 ml-2" (click)="findRoute()">Find Route</button>
                  

                    <form action="" class="form-inline" (ngSubmit)="onSubmit(content)" [formGroup]="routeForm">
                       
                        
                        <div class="input-group input-group-sm ">
                            <div class="input-group-prepend ">
                                <span class="input-group-text" id="basic-addon1">
                                   Name
                                </span>
                            </div>
                            <input type="text" name="" id="" class="form-control form-control-sm" formControlName="routeName">

                            <div class="input-append mt-2">
                                <span class="alert alert-danger" *ngIf="routeForm.get('routeName').errors?.required">Required</span>
                            </div>
                        </div>
                        
                        

                        <div class="input-group input-group-sm">
                            <div class="input-group-prepend ">
                                <span class="input-group-text" id="basic-addon1">
                                    Distance
                                </span>
                            </div>
                            <input type="text" name="" id="" class="form-control form-control-sm" formControlName="distance">
                            <div class="input-append mt-2">
                                <span class="alert alert-danger" *ngIf="routeForm.get('distance').errors?.required">Required</span>
                            </div>
                        </div>
                        
                       
                        <div class="input-group input-group-sm">
                            <div class="input-group-prepend ">
                                <span class="input-group-text" id="basic-addon1">
                                    From
                                </span>
                            </div>
                            <input type="text" name="" id="" class="form-control form-control-sm" formControlName="fromAddress">
                            <div class="input-append mt-2">
                                <span class="alert alert-danger" *ngIf="routeForm.get('fromAddress').errors?.required">Required</span>
                            </div>
                        </div>
                        
                        

                        <div class="input-group input-group-sm">
                            <div class="input-group-prepend ">
                                <span class="input-group-text" id="basic-addon1">
                                 To
                                </span>
                            </div>
                            <input type="text" name="" id="" class="form-control form-control-sm" formControlName="toAddress">
                            <div class="input-append mt-2">
                                <span class="alert alert-danger" *ngIf="routeForm.get('toAddress').errors?.required">Required</span>
                            </div>
                        </div>
                                               

                        <button type="submit" class="btn btn-sm bg-t1 ml-2">{{ buttonTitle }} Route</button>
                    </form>
                    
                </div>

              
            </div>
            
        </div>
    </div>
</div>

<!-- Modal to listen to user -->
<ng-template #content let-modal>
    <div class="modal-body text-center text-white">
        <p>{{ spinnerStatus }}</p>
    </div>
    <div class="btn-group my-1">
        <button type="button" class="btn btn-sm mx-2"
            [ngClass]="{
                'bg-t1':this.action != 'Delete',
                'btn-danger':this.action == 'Delete'
            }" 
            (click)="modal.close(action)">
            {{action}}
        </button>
        <button type="button" class="btn bg-t3 btn-sm mx-2" (click)="modal.close('Cancel')">Cancel</button>
    </div>
</ng-template>