<app-navbar></app-navbar>

<div class="container-fluid">
    <div class="row">
        <!-- Collapsible -->
        <div class="col-md-3 noprint" id="side-panel">
            <div class="d-flex aligns-items-center justify-content-between mb-1">
                <p class="mt-2">Vehicle information</p>
            </div>
           
            <form [formGroup]="mileageForm">
                <ng-multiselect-dropdown name="vehicle" [placeholder]="'Select Vehicle'" [data]="vehicles" formControlName="vehicle"
                   [settings]="dropdownSettings" >
                </ng-multiselect-dropdown>
                <p class="alert alert-danger" *ngIf="mileageForm.get('vehicle')?.errors?.required"> Required</p>
                <!-- Date filters -->
                <div class="input-group input-group-sm mt-2">
                    <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1">From</span>
                    </div>
                    <input class="form-control" placeholder="yyyy-mm-dd" name="dp" ngbDatepicker #from="ngbDatepicker"
                    formControlName="from">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="from.toggle()" type="button">
                            <i class="fa fa-calendar" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
                <p class="alert alert-danger" *ngIf="mileageForm.get('from')?.errors?.required"> Required</p>
                <!-- To -->
                <div class="input-group input-group-sm mt-2">
                    <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1">To</span>
                    </div>
                    <input 
                        class="form-control" 
                        placeholder="yyyy-mm-dd" name="dp" 
                        ngbDatepicker 
                        [minDate]="minimumDate" 
                        [maxDate]= "maximumDate"
                        #to="ngbDatepicker"
                        formControlName="to"
                     >
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="to.toggle()" type="button">
                           <i class="fa fa-calendar" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>

                <p class="alert alert-danger" *ngIf="mileageForm.get('to')?.errors?.required"> Required</p>
                <div class="input-group mt-2">
                    <input type="submit" value="Submit" name="Submit" class="btn btn-sm submit" (click)="onSubmit()">
                </div>
            </form>

            <div class="btn-group mt-2 ml-2" id="pdf-btn" *ngIf="ispdfReady">
                <button (click)="generatePDF('print')" class="btn btn-sm">
                    <i class="fa fa-print text-danger " aria-hidden="true"></i>
                    Print
                </button>
                <button (click)="generatePDF('download')" class="btn btn-sm">
                    <i class="fa fa-download text-danger " aria-hidden="true"></i>
                    Pdf
                </button>
                <button (click)="generatePDF('open')" class="btn btn-sm">
                    <i class="fa fa-eye text-danger" aria-hidden="true"></i>
                    Pdf
                </button>
            </div>
        </div>
        <div class="col-md-9">
            <div class="violation">

           
            <ngx-spinner size="medium" type="ball-clip-rotate-multiple" bdColor="#fff" [fullScreen]="false" bdOpacity=0.5
                color="#333"><br>
             <p class="mt-5">{{ spinnerText}}</p>
            </ngx-spinner>
                <ngb-accordion [closeOthers]="true" activeIds="static-1" #acc>
                    <ngb-panel *ngFor="let vehicle of selectedVehicle" id="{{vehicle.dev_name}}" >
                        <ng-template ngbPanelHeader>
                            <div class="d-flex align-items-center justify-content-between">
                                <button ngbPanelToggle class="btn btn-link p-0">
                                    <img src="../../assets/down.png" alt="" *ngIf="!acc.isExpanded(vehicle.dev_name)">
                                    <img src="../../assets/up.png" alt="" *ngIf="acc.isExpanded(vehicle.dev_name)">
                                </button>

                                <div class="">
                                    <img *ngIf="!vehicle.hasOwnProperty('violations')" src="../../assets/spinner.gif" alt="" height="30" width="30">
                                    <p *ngIf="vehicle.hasOwnProperty('violations')" class="mt-2">
                                        {{ vehicle.dev_name | titlecase }} &nbsp; &nbsp;

                                        {{ vehicle?.violations?.length }} violations
                                    </p>
                                    
                                </div>
                            </div>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <table class="table">
                                <thead>
                                    <th>Date</th>
                                    <th>Driver Name</th>
                                    <th>Criteria Name</th>
                                    <th>Criteria Value</th>
                                    <th>Actual Value</th>
                                    <th>Location</th>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let violation of vehicle.violations">
                                        <td>{{ violation?.alert_time }}</td>
                                        <td>{{ vehicle?.dev_driver_name }} </td>
                                        <td>{{ violation?.criteria_name }}</td>
                                        <td>{{ violation?.criteria_value }}</td>
                                        <td>{{ violation?.actual_value }}</td>
                                        <td>
                                            {{ violation?.location?.street | titlecase }},
                                            {{ violation?.location?.neighborhood | titlecase }},
                                            {{ violation?.location?.ua | titlecase }},
                                            {{ violation?.location?.state | titlecase }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
        </div>
    </div>
    </div>
</div>