import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { SigninComponent } from "./signin/signin.component";
import { HomeComponent } from "./home/home.component";
import { MapComponent } from "./map/map.component";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";

import { AuthService } from "./services/auth.service";
import { AuthGuard } from "./services/auth.guard";
import { EncrService } from "./services/encr.service";
import { AuthInterceptor } from "./services/authconfig.interceptor";
import { NgxSpinnerModule } from "ngx-spinner";
import { GeofencesComponent } from './geofences/geofences.component';
import { NavbarComponent } from './navbar/navbar.component';
import { ReportsComponent } from './reports/reports.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ChartsModule } from 'ng2-charts';
import { MileageComponent } from './mileage/mileage.component';
import { SummaryComponent } from './summary/summary.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ViolationReportComponent } from './violation-report/violation-report.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { GlobalErrorHandler } from './services/global-error-handler';
import { UserManagementComponent } from './user-management/user-management.component';
import { RouteManagementComponent } from './route-management/route-management.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AdminComponent } from './admin/admin.component';
import { UserComponent } from './user/user.component';
import { RouteGuard } from './services/route.guard';
import { AlertsComponent } from './alerts/alerts.component';
import { TimeZoneService } from './services/timezone.service';
import { MenuComponent } from './menu/menu/menu.component';

@NgModule({
  declarations: [
    AppComponent,
    SigninComponent,
    HomeComponent,
    MapComponent,
    GeofencesComponent,
    NavbarComponent,
    ReportsComponent,
    MileageComponent,
    SummaryComponent,
    ViolationReportComponent, UserManagementComponent, RouteManagementComponent, DashboardComponent, AdminComponent, UserComponent, AlertsComponent, MenuComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    NgbModule,
    ChartsModule,
    NgMultiSelectDropDownModule,
    MatSnackBarModule
  ],
  providers: [
    AuthGuard,
    RouteGuard,
    AuthService,
    EncrService,
    TimeZoneService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
