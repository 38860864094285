import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { User } from '../models/user';
import { Subject } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { DataService } from '../services/data.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserServiceService } from '../services/user-service.service';
import { takeUntil } from 'rxjs/operators';
import { EncrService } from '../services/encr.service';


@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
  // user list
  users: User[];
  newUser = {} as User;
  customerName = localStorage.getItem('cusName');
  customerID = localStorage.getItem('cusId');
  userName = localStorage.getItem('userName');
  userMapType = localStorage.getItem('userMapType');
  userMaster = localStorage.getItem('userIsMaster');
  userID = localStorage.getItem('userId');

  // User form
  userForm = new FormGroup({
    username: new FormControl('', Validators.required),
    userPassword: new FormControl('', Validators.required),
    userEmail: new FormControl('', [Validators.required, Validators.email]),
    userMapType: new FormControl('', Validators.required),
    userMarkerSet: new FormControl('', Validators.required)
  });

  // User form search
  userSearch = new FormControl('');
  userFilter;
  userSelected: boolean;
  spinnerStatus;
  action;
  buttonTitle = 'Create';

  selectedUser: User;
  onDestroy$ = new Subject();

  constructor(
    public authService: AuthService,
    public dataService: DataService,
    public spinner: NgxSpinnerService,
    private modalService: NgbModal,
    private userService: UserServiceService,
    private encrService: EncrService
  ) { this.userSelected = false }

  ngOnInit(): void {
    this.buttonTitle = 'Create';
    this.spinner.show();
    this.action = 'Create';
    setTimeout(e => { this.spinner.hide(); }, 2000);

    // get users
    this.getUsers();

    // filter user
    this.userSearch.valueChanges.subscribe(term => {
      //console.log(term);
      this.userFilter = this.users.filter(user => user.username.includes(term));
    });
  }

  // get a list of users
  getUsers() {

    this.userService.getUsers({
      customerId: this.customerID
    })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(response => {
        //console.log(response);
        this.users = response;
        this.userFilter = response;
      });

  }

  // Select a user on click
  getUserDetails(username: string): void {
    //console.log(username);
    //console.log(this.users.length);
    this.selectedUser = this.users.find(user => user.username === username);
  }

  resetForm(content) {
    this.buttonTitle = 'Create';
    this.action = 'Create';
    this.userForm.reset({
      username: '',
      userPassword: '',
      userEmail: '',
      userMapType: '',
      userMarkerSet: ''

    });
  }

  // CRUD
  // Create User
  onSubmit(content) {
    //console.log(this.userForm.getRawValue());
    // check if form is valid
    //console.log(content);
    // call createUser or Update User
    // this.spinnerStatus = this.action + ' Create user ';

    // open a modal to confirm the action
    const formData = this.userForm.getRawValue();
    // this.action = 'Create';
    this.spinnerStatus = ' Create user ' + formData.username;
    // console.log(this.action);
    this.modalService.open(content, { centered: true }).result.then(result => {
      if (result === 'Cancel') {

      } else {
        // send the request ot the user
        this.action !== 'Update' ? this.createUser() : this.updateUser();

      }
    });
  }

  createUser() {
    const formData = this.userForm.getRawValue();
    //(formData);

    this.newUser.username = formData.username;
    this.newUser.password_hash = this.encrService.set(formData.userPassword);
    this.newUser.customer_id = localStorage.getItem('cusId');
    this.newUser.email = formData.userEmail;
    this.newUser.user_map_type = formData.userMapType;
    this.newUser.user_marker_set = 'Arrows';
    //console.log(this.newUser);
    this.userService.createUser(this.newUser)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(response => {

        //console.log(response);
        // update the list of users
        this.getUsers();
      });
  }

  updateUser() {
    const formData = this.userForm.getRawValue();
    this.selectedUser.username = formData.username;
    this.selectedUser.password_hash = this.encrService.set(formData.userPassword);
    this.selectedUser.customer_id = localStorage.getItem('cusId');
    this.selectedUser.email = formData.userEmail;
    this.selectedUser.user_map_type = formData.userMapType;
    //console.log(this.selectedUser);

    this.userService.updateUser(this.selectedUser)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(response => {
        //console.log(response);
        if (response.serverStatus === 2) {

          // update users
          this.getUsers();

          setTimeout(e => {
            this.selectedUser = this.users.find(user => user.id === this.selectedUser.id);
          }, 1000);

          // update the selected user
        }

      });

  }

  addUserToForm(user: User) {
    // Update the form with selected user
    this.action = 'Update';
    this.buttonTitle = 'Update';

    this.userForm.setValue({
      username: user.username,
      userEmail: user.email,
      userMapType: user.user_map_type,
      userMarkerSet: user.user_marker_set,
      userPassword: ''
    });

  }

  // delete a user from the database
  deleteUser(id: string, content) {
    //console.log(id);
    this.action = 'Delete';
    this.spinnerStatus = ' Delete user ' + this.selectedUser.username;
    // open a modal to confirm the action
    this.modalService.open(content, { centered: true }).result.then(result => {
      if (result === 'Cancel') {
        this.action = 'Update'
      } else {
        this.spinner.show();
        // send the request ot the user
        this.userService.deleteUser({
          user_id: id
        })
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(response => {
            //console.log(response);
            this.spinner.hide();

            // Update selectes user and the list of users
            this.selectedUser = undefined;
            this.userFilter = this.users.filter(user => user.id !== id);
            this.action = 'Create';
          });
      }
    });

  }

  ngDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
