<!-- Spinner -->
<ngx-spinner size="medium" type="ball-clip-rotate-multiple" bdColor="#fff" bdOpacity=0.9 color="#333"
  [fullScreen]="false"></ngx-spinner>

<app-navbar></app-navbar>
<div class="container-fluid row" [ngClass]="{
  'd-none':isDataReady == false,
  'd-flex':isDataReady == true
}">

  <div class="collapse-view col-md-4" id="mySide">
    <div class="d-flex align-items-center justify-content-between">
      <div class="user-details">
        <!-- <p>
          Client Name: {{ customerName }}<br />
          Username: {{ username }}
        </p> -->
      </div>
      <div class="rounded-circle response-status" [ngClass]="{
          'bg-success': connectionStatus == 'online',
          'bg-danger': connectionStatus == 'offline'
        }"></div>
    </div>
    <form action="" class="mt-2">
      <div class="input-group">
        <input type="text" class="filter" [formControl]="filter" placeholder="Filter Vehicle" />
      </div>
    </form>
    <div id="table-view">
      <table class="" id="table-results">
        <thead>
          <th></th>
          <th>Vehicle</th>
          <th id="location">Location</th>
          <th>Status</th>
          <th>Sec. Status</th>
        </thead>

        <tbody>
          <tr *ngFor="
              let vehicle of vehicleSearch;
              let i = index;
              trackBy: trackByVehicle
            " (click)="flyToVehicle(vehicle.dev_name)" [ngClass]="{ glow: vehicle?.visual == true }">
            <td>
              <div class="status" [ngClass]="{
                  't-red': vehicle.status == 'Over Speed',
                  't-green': vehicle.status == 'Normal Speed',
                  't-orange': vehicle.status == 'OFF',
                  't-blue': vehicle.status == 'Stopped (IGN ON)',
                  't-grey': vehicle.status == 'IGN OFF'
                }"></div>
            </td>

            <td>{{ vehicle.dev_name }}</td>
            <td>
              {{ vehicle.location?.county | titlecase}}
            </td>
            <td>{{ vehicle.status }}</td>
            <td>
              {{ vehicle.sec_status }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <audio #audioElement loop>
      <source src="../../assets/my.mp3" type="audio/mpeg" />
      Your browser does not support the audio element.
    </audio>


    <p class="text-center text-default h6">
      Map Legend
    </p>
    <div class="row text-center">
      <!-- Map Legend -->
      <div class="col-md-2">
        <img class="status-marker" src="/assets/orange.png" alt="" />
        <p>OFF</p>
      </div>
      <div class="col-md-2">
        <img class="status-marker" src="/assets/grey.png" alt="" />
        <p>IGN-OFF</p>
      </div>
      <div class="col-md-2">
        <img class="status-marker" src="/assets/blue.png" alt="" />
        <p>Stopped (IGN-ON)</p>
      </div>
      <div class="col-md-2">
        <img class="status-marker" src="/assets/green.png" alt="" />
        <p>Normal Speed</p>
      </div>
      <div class="col-md-2">
        <img class="status-marker" src="/assets/red.png" alt="" />
        <p>Over Speed</p>
      </div>
      <div class="col-md-2">
        <img class="status-marker" src="/assets/poi.png" alt="" />
        <p>POI</p>
      </div>
    </div>
  </div>
  <div class="col-lg-8 col-md-8 col-sm-8">

    <div class="btn" (click)="playOrPause()" id="audio-control">
      <img *ngIf="audioControl == 'volume'" src="/assets/play.png" alt="play" height="26" width="26" />
      <img *ngIf="audioControl == 'mute'" src="/assets/mute.png" alt="mute" height="26" width="25" />
    </div>
    <div class="btn" (click)="hideShow()" id="side-control">
      <img src="/assets/Hamburger_icon.png" alt="play" height="26" width="26" />
    </div>


    <div id="map" [style.width.px]="[widthVal]">
    </div>
  </div>
</div>


<!-- Style the table text as per the vehicle status  -->

<!-- Use tabs to navigate to the next view  -->