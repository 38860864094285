import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DataService } from '../services/data.service';
import { User } from '../models/user';
import { Vehicle } from '../models/vehicle';
import { UserDevice } from '../models/user-device';

import { NgxSpinnerService } from 'ngx-spinner';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserServiceService } from '../services/user-service.service';
import { takeUntil, map } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';
import { threadId } from 'worker_threads';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss']
})
export class UserManagementComponent implements OnInit {
  userForm = new FormGroup({
    username: new FormControl('', Validators.required),
    userIsMaster: new FormControl('', Validators.required),
    userPassword: new FormControl('', Validators.required),
    userEmail: new FormControl('', [Validators.required, Validators.email]),
    userMapType: new FormControl('', Validators.required),
    userIsControl: new FormControl('', Validators.required),
    userMapLanguage: new FormControl('', Validators.required),
    customerId: new FormControl('', Validators.required),
    userMarkerSet: new FormControl('', Validators.required),
    crId: new FormControl('')
  });

  userVehicleControl = new FormControl('');
  availableVehicleControl = new FormControl('');

  // user list
  users: User[];

  customerName = localStorage.getItem('cusName');
  customerID = localStorage.getItem('cusId');
  userName = localStorage.getItem('userName');
  userMapType = localStorage.getItem('userMapType');
  userMaster = localStorage.getItem('userIsMaster');
  userID = localStorage.getItem('userId');

  // User form search
  userSearch = new FormControl('');
  userFilter;

  spinnerStatus;
  action;
  buttonTitle = 'Create';


  availableDevices: any[] = [];
  allDevices: Vehicle[];

  selectedUser: User;
  onDestroy$ = new Subject();
  userDevices;

  //
  userItem = [];
  availableItem = [];

  constructor(
    public authService: AuthService,
    public dataService: DataService,
    public spinner: NgxSpinnerService,
    private modalService: NgbModal,
    private userService: UserServiceService
  ) {

  }

  ngOnInit(): void {
    this.spinner.show();

    // get all Devices
    this.dataService.getVehicle({
      userId: this.userID,
      cus_id: this.customerID,
      userIsMaster: this.userMaster,
    })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(response => {
        this.allDevices = response;
        this.spinner.hide();
      });


    // get users
    this.getUsers();

    // filter user
    this.userSearch.valueChanges.subscribe(term => {
      //console.log(term);
      this.userFilter = this.users.filter(user => user.username.includes(term));
    });


    // get values
    this.userVehicleControl.valueChanges.subscribe(term => {
      //console.log({ term });
      if (term) {
        this.userItem = term;
      }
    });

    // Listen to changes in form control
    this.availableVehicleControl.valueChanges.subscribe(term => {
      //console.log({ term });
      if (term) {

        this.availableItem = term;
      }
    });

  }

  // get user vehicles on click
  getUserVehices(user: User) {
    const userId = user.id;

    //console.log(this.allDevices);
    // get user devices
    this.userService.getUserDevices({
      userId: userId
    })
      .pipe(
        takeUntil(this.onDestroy$),
        map(response => {
          response = this.updateUserDeviceInfo(response);
          return response;
        })
      )
      .subscribe(response => {
        this.userDevices = response;

        //console.log({ userdevices: response });
        // update user vehicles
        this.getAvailableDevices();
      });

  }

  // filter devices and update devices names
  updateUserDeviceInfo(response: UserDevice[]): any {
    const updateDevices = response.map(device => {
      const dev = this.allDevices.find(vehicle => vehicle.dev_id === device.dev_id);

      if (dev) {

        // merge the objects
        device = { ...device, ...dev };
        return device;
      }
    }).filter(vehicle => vehicle !== undefined);

    return updateDevices;
  }

  // remove  user devices froma ll devices
  getAvailableDevices() {
    //console.log("getting devices")
    this.availableDevices = this.allDevices.map(device => {
      const dev = this.userDevices.find(deviceInner => deviceInner.dev_id === device.dev_id);
      if (dev) {
        return;
      } else {

        return device;
      }

    }).filter(device => device !== undefined);
    //console.log({ devs: this.availableDevices })
  }

  // get a list of users
  getUsers() {

    this.userService.getUsers({
      customerId: this.customerID
    })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(response => {
        this.users = response;
        this.userFilter = response;
      });

  }

  // Select a user on click
  getUserDetails(username: string): void {
    this.userDevices = [];
    this.selectedUser = this.users.find(user => user.username === username);

    // update user devices
    if (this.selectedUser) {
      this.getUserVehices(this.selectedUser);
    }

  }

  // add available device to user device
  addToUserDevice(devId: number) {
    // open a modal to confirm the action
    // send the request ot the use

    this.spinner.show();
    // send request to the serve
    this.userService.addUserDevice({
      user_id: this.selectedUser.id,
      dev_id: devId,
      cus_id: this.customerID
    }).subscribe(response => {

      // update the available devices
      if (response.serverStatus === 2) {
        const device = this.availableDevices.find(vehicle => vehicle.dev_id === devId);
        if (device)
          this.userDevices.push(device);

        this.availableDevices = this.availableDevices.filter(vehicle => {
          //console.log({ vehicle, devId })
          return vehicle.dev_id !== devId
        });
        //console.log({ avlDevs: this.availableDevices });
      }

      this.spinner.hide();
    });


  }

  // remove
  removeDevice(devId: number) {
    // send the request ot the user
    this.spinner.show();

    // send request to the serve
    this.userService.deleteUserDevice({
      userId: this.selectedUser.id,
      devId: devId,
      cus_id: this.customerID
    }).subscribe(response => {

      if (response.serverStatus === 2) {
        const device = this.userDevices.find(vehicle => vehicle.dev_id === devId);
        if (device)
          this.availableDevices.push(device);
        this.userDevices = this.userDevices.filter(vehicle => {
          //console.log({ vehicle, devId })
          return vehicle.dev_id !== devId
        }
        );
        //console.log({ deledtedDevs: this.userDevices });
      }

      this.spinner.hide();
    });
  }

  // Assign vehicle to the user
  assignVehicleToUser(content) {
    // prompt the user to confirm the action
    this.action = 'Add device';
    this.spinnerStatus = this.action + ' ....';

    // open a modal to confirm the action
    this.modalService.open(content, { centered: true }).result.then(result => {
      if (result === 'Cancel') {

      } else {

        if (this.availableItem.length == 0) {
          // show notification
          window.alert("device not selected")
        } else {
          this.availableItem.forEach(device => {
            this.addToUserDevice(parseInt(device, 10));
          });
          this.availableItem = [];
        }
      }
    });

  }

  assignAllVehiclesToUser(content) {
    // prompt the user to confirm the action
    this.action = 'Add device';
    this.spinnerStatus = this.action + ' ....';

    // open a modal to confirm the action
    this.modalService.open(content, { centered: true }).result.then(result => {
      if (result === 'Cancel') {

      } else {

        this.allDevices.forEach(device => {
          this.addToUserDevice(device.dev_id);
        });

      }
    });

  }


  // Remove the user devices
  removeFromUserVehicles(content) {
    // prompt the user to confirm the action
    this.action = 'Delete';
    this.spinnerStatus = this.action + ' ....';

    // open a modal to confirm the action
    this.modalService.open(content, { centered: true }).result.then(result => {
      if (result === 'Cancel') {

      } else {

        if (this.userItem.length == 0) {
          // show notifiation
          window.alert("device not selected")
        } else {
          this.userItem.forEach(device => {
            this.removeDevice(parseInt(device, 10));
          });
          this.userItem = [];
        }

      }
    });

  }

  removeAllUserVehicles(content) {
    // prompt the user to confirm the action
    this.action = 'Delete';
    this.spinnerStatus = this.action + ' ....';


    // open a modal to confirm the action
    this.modalService.open(content, { centered: true }).result.then(result => {
      if (result === 'Cancel') {

      } else {

        this.userDevices.forEach(device => {
          this.removeDevice(device.dev_id);
        });

      }
    });

  }

  ngDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
