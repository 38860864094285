<app-navbar></app-navbar>

<div class="container-fluid d-flex">
    <nav class="navbar bg-t1" id="side-navbar" [ngClass]="{
        'collapse-navbar': isSideNavCollapsed == true
    }">

        <a class="btn text-white" (click)="toggleNavbar()">
            <i class="fa fa-bars" aria-hidden="true" [hidden]="!isSideNavCollapsed"></i>
            <i class="fa fa-times" aria-hidden="true" [hidden]="isSideNavCollapsed"></i>
        </a>

        <div class="container">

            <ul class="nav navbar-nav mr-auto">

                <li class="nav-item">
                    <a class="nav-link" routerLink="/admin">
                        <i class="fa fa-user mr-1" aria-hidden="true"></i>
                        <span [hidden]="isSideNavCollapsed">Users</span>
                    </a>
                </li>

                <li class="nav-item">
                    <a class="nav-link" routerLink="device">
                        <i class="fa fa-car mr-1" aria-hidden="true"></i>
                        <span [hidden]="isSideNavCollapsed">User-Vehicle</span>
                    </a>
                </li>

                <li class="nav-item">
                    <a class="nav-link" routerLink="routes">
                        <i class="fa fa-line-chart mr-1" aria-hidden="true"></i>
                        <span [hidden]="isSideNavCollapsed">Route</span>
                    </a>
                </li>

                <li class="nav-item">
                    <a class="nav-link" routerLink="alerts">
                        <i class="fa fa-audio-description mr-1" aria-hidden="true"></i>
                        <span [hidden]="isSideNavCollapsed">Alert</span>
                    </a>
                </li>

            </ul>
        </div>

    </nav>

    <div class="row ml-3" id="active-route">
        <router-outlet></router-outlet>
    </div>

</div>