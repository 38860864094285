<div class="btn-group me-3">
    <button type="button" class="btn btn-primary">Split me</button>
    <div class="btn-group" ngbDropdown role="group" aria-label="Button group with nested dropdown">
        <button type="button" class="btn btn-primary dropdown-toggle-split" ngbDropdownToggle></button>
        <div class="dropdown-menu" ngbDropdownMenu>
            <button ngbDropdownItem>One</button>
            <button ngbDropdownItem>Two</button>
            <button ngbDropdownItem>Four!</button>
        </div>
    </div>
</div>