<div class="container-fluid">
    <div class="row">
        <div class="col-md-3">
            <ngx-spinner size="medium" type="ball-clip-rotate-multiple" bdColor="#fff" [fullScreen]="false"
                bdOpacity=0.5 color="#333">
                <p class="t-2">{{ spinnerStatus }}</p>
            </ngx-spinner>

            <!-- List of users -->
            <h3 class="t-1  bg-t1 w100 px-2">Alert Rules</h3>

            <!-- search bar -->
            <form action="" class="mb-1">
                <div class="input-group input-group-sm">
                    <input type="search" name="" id="" class="form-control form-control-sm" placeholder="Filter Alert"
                        [formControl]="alertSearch">

                    <div class="input-group-prepend">
                        <span class="input-group-text">
                            <i class="fa fa-search" aria-hidden="true"></i>
                        </span>
                    </div>
                </div>
            </form>

            <div class="list-group devices">
                <li class="list-group-item d-flex justify-content-between" *ngFor="let alert of alertRules"
                    (click)="getAlertInformation(alert?.dev_id, alert?.alert_type, alert?.cus_id, alert?.object_id,alert?.criteria_value)">
                    {{  alert?.dev_name }}
                    {{  alert?.criteria_name }}
                </li>
            </div>
        </div>

        <div class="col-md-8">
            <h3 class="t-1  bg-t1 w100 px-2">Alert</h3>


            <form action="" class="form-horizontal" (ngSubmit)="onSubmit(content)" [formGroup]="alertForm">

                <ng-multiselect-dropdown name="vehicle" [placeholder]="'Select Vehicle'" [data]="vehicleList"
                    formControlName="vehicle" [settings]="dropdownSettings" (onSelect)="onItemSelect($event)" [ngClass]="{
                        'd-block':selectedAlert == undefined,
                        'd-none':selectedAlert != undefined
                    }">
                </ng-multiselect-dropdown>

                <h5 [ngClass]="{
                        'd-block':selectedAlert != undefined,
                        'd-none':selectedAlert == undefined
                    }">
                    {{selectedVehicle?.dev_name}}
                </h5>

                <div class="input-group input-group-sm">
                    <div class="input-group-prepend ">
                        <span class="input-group-text" id="basic-addon1">
                            Alert Category
                        </span>
                    </div>

                    <select name="alert" class="form-control form-control-sm" formControlName="alertCategory">
                        <option value="routing">Routing</option>
                        <option value="geofence">Geofence</option>
                        <option value="speed">Max speed</option>
                        <option value="concrete-mixer">Concrete Mixer</option>
                    </select>

                    <div class="input-append mt-2">
                        <span class="alert alert-danger"
                            *ngIf="alertForm.get('alertCategory').errors?.required">Required</span>
                    </div>
                </div>

                <!-- concrete -->

                <div class="input-group input-group-sm ">
                    <div class="input-group-prepend ">
                        <span class="input-group-text" id="basic-addon1">
                            Criteria Name
                        </span>
                    </div>
                    <input type="text" name="" id="" class="form-control form-control-sm"
                        formControlName="criteriaName">

                    <div class="input-append mt-2">
                        <span class="alert alert-danger"
                            *ngIf="alertForm.get('criteriaName').errors?.required">Required</span>
                    </div>
                </div>

                <!-- Concrete Mixer Status -->
                <div class="input-group input-group-sm " *ngIf="alertForm.get('alertCategory').value == 'concrete-mixer'">
                    <div class="input-group-prepend ">
                        <span class="input-group-text" id="basic-addon1">
                            Concrete Mixer Status
                        </span>
                    </div>
                    <select name="alert" class="form-control form-control-sm" formControlName="concreteMixerStatus">
                        <option value="mixer-charging">Charging</option>
                        <option value="mixer-discharging">Discharging</option>
                    </select>
                
                    <div class="input-append mt-2">
                        <span class="alert alert-danger" *ngIf="alertForm.get('criteriaName').errors?.required">Required</span>
                    </div>
                </div>

                <!-- Geofence Category -->
                <div class="input-group input-group-sm" *ngIf="alertForm.get('alertCategory').value == 'geofence'">
                    <div class="input-group-prepend ">
                        <span class="input-group-text" id="basic-addon1">
                            Geofence Category
                        </span>
                    </div>
                    <select name="alert-type" id="" class="form-control form-control-sm"
                        formControlName="geofenceCategory">
                        <option value="geofence-relativity">Geofence Relativity</option>
                        <option value="geofence-maxspeed">Geofence Speed</option>
                    </select>

                    <div class="input-append mt-2">
                        <!-- <span class="alert alert-danger" *ngIf="alertForm.get('criteriaNa').errors?.required">Required</span> -->
                    </div>
                </div>

                <!-- Alert Type -->

                <div class="input-group input-group-sm">
                    <div class="input-group-prepend ">
                        <span class="input-group-text" id="basic-addon1">
                            Alert Type
                        </span>
                    </div>

                    <select name="alert-type" id="" class="form-control form-control-sm" formControlName="alertType">
                        <option value="1">Email</option>
                        <option value="2">Sound</option>
                        <option value="3">Email, Sound</option>
                        <option value="4">Visual</option>
                        <option value="5">Email, Audio</option>
                        <option value="6">Audio, Visual</option>
                        <option value="7">Audio, Visual, Email</option>
                    </select>

                    <div class="input-append mt-2">
                        <span class="alert alert-danger"
                            *ngIf="alertForm.get('alertType').errors?.required">Required</span>
                    </div>
                </div>

                <!-- Email -->
                <div class="input-group input-group-sm">
                    <div class="input-group-prepend ">
                        <span class="input-group-text" id="basic-addon1">
                            Email
                        </span>
                    </div>
                    <input type="text" name="" id="" class="form-control form-control-sm" formControlName="email">
                    <div class="input-append mt-2">
                        <span class="alert alert-danger" *ngIf="alertForm.get('email').errors?.email">Required</span>
                    </div>
                </div>

                <!-- Criteria Value -->
                <div class="input-group input-group-sm">
                    <div class="input-group-prepend ">
                        <span class="input-group-text" id="basic-addon1">
                            Criteria Value
                        </span>
                    </div>

                    <input type="number" name="" id="" class="form-control form-control-sm"
                        formControlName="criteriaValue" *ngIf="alertForm.get('alertCategory').value == 'speed'">

                    <select name="" id="" class="form-control form-control-sm" formControlName="criteriaValue"
                        *ngIf="alertForm.get('geofenceCategory').value == 'geofence-relativity'">
                        <option value="0">Enter Geofence</option>
                        <option value="1">Leave Geofence</option>
                    </select>

                    <select name="" id="" class="form-control form-control-sm" formControlName="criteriaValue"
                        *ngIf="alertForm.get('alertCategory').value == 'concrete-mixer'">
                        <option value="2">Input 2</option>
                        <option value="3">Input 3</option>
                        <option value="4">Input 4</option>
                    </select>

                    <input type="number" name="" id="" class="form-control form-control-sm"
                        formControlName="criteriaValue"
                        *ngIf="alertForm.get('geofenceCategory').value == 'geofence-maxspeed'">

                    <input type="number" name="" id="" class="form-control form-control-sm"
                        formControlName="criteriaValue" *ngIf="alertForm.get('alertCategory').value == 'routing'">

                    <div class="input-append mt-2">
                        <span class="alert alert-danger"
                            *ngIf="alertForm.get('criteriaValue').errors?.required">Required</span>
                    </div>
                </div>

                <!-- Form options -->
                <!-- Route -->
                <div class="input-group input-group-sm" *ngIf="alertForm.get('alertCategory').value == 'routing'">
                    <div class="input-group-prepend ">
                        <span class="input-group-text" id="basic-addon1">
                            Routes
                        </span>
                    </div>

                    <select name="" *ngIf="alertForm.get('alertCategory').value == 'routing'"
                        class="form-control form-control-sm" formControlName="routes">

                        <option *ngFor="let route of routes" value="{{route.name}}">
                            {{route.name}}
                        </option>

                    </select>

                </div>

                <!-- Geofence list -->
                <div class="input-group input-group-sm" *ngIf="alertForm.get('alertCategory').value == 'geofence'">
                    <div class="input-group-prepend ">
                        <span class="input-group-text" id="basic-addon1">
                            Geofence
                        </span>
                    </div>

                    <select name="" *ngIf="alertForm.get('alertCategory').value == 'geofence'"
                        class="form-control form-control-sm" formControlName="geofences">

                        <option *ngFor="let geofence of geofences" value="{{geofence.geofence_name}}">
                            {{geofence.geofence_name}}
                        </option>

                    </select>

                </div>

                <button type="submit" class="btn btn-sm bg-t1"
                    *ngIf="buttonTitle == 'Create'"
                 >{{ buttonTitle }} Alert</button>

            </form>
            <button class="btn btn-sm bg-t3 mt-2" (click)="resetForm()"
                *ngIf="alertForm.get('alertCategory').value">Reset
                Form</button>
            <button class="btn btn-sm btn-danger ml-2 mt-2" *ngIf="selectedAlert"
                (click)="deleteAlert(content,selectedAlert?.dev_id, selectedAlert?.alert_type, selectedAlert?.criteria_name, selectedAlert?.object_id)">Delete</button>
        </div>


    </div>
</div>

<!-- Modal to listen to user -->
<ng-template #content let-modal>
    <div class="modal-body text-center text-white">
        <p>{{ spinnerStatus }}</p>
    </div>
    <div class="btn-group my-1">
        <button type="button" class="btn btn-sm mx-2" [ngClass]="{
      'bg-t1':action != 'Delete',
      'btn-danger':action == 'Delete'
    }" (click)="modal.close(action)">{{action}}</button>
        <button type="button" class="btn bg-t3 btn-sm mx-2" (click)="modal.close('Cancel')">Cancel</button>
    </div>
</ng-template>