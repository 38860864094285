import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import * as L from 'leaflet';
import 'leaflet.polyline.snakeanim';
import 'leaflet.heat';

import { DataService } from '../services/data.service';
import { ReportGeneratorService } from '../services/report-generator.service';
import { NgbDateStruct, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';

import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { FormControl, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';


import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, Label } from 'ng2-charts';

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import html2canvas from 'html2canvas';
import { TimeZoneService } from '../services/timezone.service';
import { length } from '@turf/turf';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
})
export class ReportsComponent implements OnInit {
  private map;
  public mapCanvasUrl;
  public vehicle = [];
  public vehicelNames;

  // Time in NgDateStruct
  public from = { hour: 3, minute: 30 };
  public to = { hour: 10, minute: 10 };
  public filterDate = { hour: 10, minute: 45 };
  public manad: string;
  public fromTime = new FormControl('00:00:00', Validators.required);
  public toTime = new FormControl('23:59:59', Validators.required);



  // time filter
  timeFilter = new FormControl('16:46');

  // chartjs
  public lineChartData: ChartDataSets[] = [
    {
      data: [],
      label: 'Speed',
      pointRadius: 1,
      pointHoverRadius: 1,
      backgroundColor: 'rgba(255,255,255,0.8)',
      borderWidth: 2,
    },
  ];

  public lineChartLabels: Label[] = [];

  public lineChartOptions: ChartOptions = {
    responsive: true,
    title: {
      display: true,
      text: 'Vehicle Speed',
    },
    scales: {
      xAxes: [
        {
          display: true,
          type: 'time',
          distribution: 'series',
          time: {
            parser: 'YYYY-MM-DD HH:mm:ss',
            tooltipFormat: 'll HH:mm',
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            suggestedMin: 0,
            stepSize: 10
          },
          display: true,
        },
      ],
    },
  };
  public lineChartColors: Color[] = [
    {
      borderColor: '#075754',
      backgroundColor: '#07575480',
    },
  ];
  public lineChartLegend = false;
  public lineChartType = 'line';
  public lineChartPlugins = [];

  // Report div
  @ViewChild('report') htmlData: ElementRef;
  @ViewChild('reportMap') myMap: ElementRef;

  userID;
  cusId;
  userMaster;
  username;
  customerName;

  // Map dimensions
  mapHeight = '54vh';

  // Map data
  public vehiclePath;
  public vehicleLocation;
  public vehicleHistory;
  public coordinates;
  public currentIndex = 0;
  public averageSpeed: number;
  public totalDistance: number;
  public selectedHistory = false;
  public selectedVehicle;


  public isEntireTrip = true;

  public tripReport;
  public tripGroups;
  public tripGroupsCopy;
  public tripFilter;

  public filterOpacity = 0;
  // setInterval obectes
  public timer;

  public spinnerStatus: string;
  public onDestroy$ = new Subject();
  private zone = localStorage.getItem("Timezone");
  // Form date
  fromDate;
  toDate;
  //
  public isCollapsed = true;
  // vehicle name
  public vehicleName = new FormControl();

  // Datepicker
  public model: NgbDateStruct;
  timeModel: NgbTimeStruct;

  public models: any;
  public ispdfReady = false;

  // typeahead search:return an object of type string
  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map((term) =>
        term.length < 1
          ? []
          : this.vehicelNames
            .filter((v) => v.toLowerCase().indexOf(term.toLowerCase()) > -1)
            .slice(0, 10)
      )
    )



  //
  constructor(
    private dataService: DataService,
    private spinner: NgxSpinnerService,
    private reportGenerator: ReportGeneratorService,
    private timeZoneService: TimeZoneService
  ) {
    // user details from local storage
    this.userMaster = localStorage.getItem('userIsMaster');
    this.userID = localStorage.getItem('userId');
    this.cusId = localStorage.getItem('cusId');
    this.username = localStorage.getItem('userName');
    this.customerName = localStorage.getItem('cusName');
  }

  ngOnInit(): void {
    // subscribe to getVehicle Services to load user vehicles
    this.spinnerStatus = 'Loading vehicle data ...';
    this.spinner.show();

    // get vehicle data
    this.dataService
      .getVehicle({
        userId: this.userID,
        cus_id: this.cusId,
        userIsMaster: this.userMaster,
      })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((response) => {
        this.vehicle = response;
        this.vehicelNames = this.vehicle.map((vehicle) => vehicle.dev_name);

        // this.addDataToMap();
        this.spinner.hide();
      });

    this.initMap();

    this.timeFilter.valueChanges.subscribe(() => {
      // display the filter tab
      this.filterOpacity = 1;

      // filter the data
      this.filterTripByTime();
    });
  }

  initMap(): void {
    this.map = L.map('map-report').setView([0.12, 6.908], 4);
    // add google mapstitle layer
    const googleStreets = L.tileLayer(
      'http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',
      {
        maxZoom: 22,
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
        crossOrigin: true
      }
    );

    // Add a open streetmap tile layer
    const osm = L.tileLayer(
      'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      {
        maxZoom: 22,
        attribution:
          '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
      }
    );


    L.control.layers({
      Google: googleStreets,
      OSM: osm
    }).addTo(this.map);

    this.map.addLayer(googleStreets);
  }

  getReport(): void {
    //console.log(this.model);
    if (!this.model || !this.vehicleName.value) {
      throw (
        new Error('Fill all the vehicle name and the date')
      );
    }
    // empty tgroups
    this.tripGroupsCopy = [];

    // get vehicle id
    const vehicle = this.vehicle.find(
      (vh) => vh.dev_name === this.vehicleName.value
    );

    this.selectedVehicle = vehicle;
    //console.log(this.selectedVehicle);
    // create data string
    this.manad = (this.model.month).toLocaleString();
    if ((this.manad).length === 1) {
      this.manad = "0" + this.manad;
    }
    this.fromDate = `${this.model.year}-` + this.manad + `-${this.model.day} ${this.fromTime.value}`;
    this.toDate = `${this.model.year}-` + this.manad + `-${this.model.day} ${this.toTime.value}`;
    //console.log("FromDate: " + this.fromDate);
    //console.log("TillDate: " + this.toDate);
    const lagos = localStorage.getItem("Timezone");
    const gmt = "Europe/London";
    // foramt the data sent to the service
    const data = {
      from: this.timeZoneService.convertTimeToTimezone(this.fromDate, lagos, gmt),
      to: this.timeZoneService.convertTimeToTimezone(this.toDate, lagos, gmt),
      dev_id: vehicle.dev_id,
    };
    //console.log({ data });
    // show spinner
    this.spinnerStatus = 'Loading vehicle report ...';
    this.spinner.show();

    // get vehicle report
    this.dataService.getVehicleHistory(data)
      //.pipe(takeUntil(this.onDestroy$))
      .subscribe(response => {
        //  Handle error response
        //console.log(response);
        try {
          if (!response[0]) {
            throw Error(`No trip data for the period ${data.from} - ${data.to}`);
          }

          this.spinner.hide();

          this.vehicleHistory = response;
          // add data to the map
          this.addDataToMap();
        } catch (error) {
          alert(error.message);

          this.spinner.hide();
        }

      });
  }

  //
  addDataToMap(): void {
    // filter hsitory informations: if history remains unchanged dont add
    const arr = this.vehicleHistory;

    // Assign vehicle history to arr
    if (arr.length === 1) {
      alert('The vehicle has been off');
    } else {
      //console.log(arr);
      this.vehicleHistory = arr;

      // Extract vehicle coordinates
      const coord = this.getLineCoordinates(this.vehicleHistory);

      // remove layer from the map
      if (this.vehiclePath) {
        this.map.removeLayer(this.vehiclePath);
      }

      // Create a polyline object
      this.vehiclePath = L.polyline(coord, {
        color: '#075754'
      });

      // add path to map
      this.vehiclePath.addTo(this.map);
      // .snakeIn();

      this.map.fitBounds(this.vehiclePath.getBounds());

      // Variable to store the distance history variable
      let previousPoint;

      // calculate the distance between two points
      this.vehicleHistory[0].distance = 0;
      this.vehiclePath.getLatLngs().forEach((latlng, i) => {
        if (previousPoint) {
          // get line length
          const length = previousPoint.distanceTo(latlng);

          // add the attribute to the this.vehicleHistory Object
          this.vehicleHistory[i].distance = Math.round(length) / 1000;
        }
        previousPoint = latlng;
      });

      //console.log(this.vehiclePath);

      // Create the plot
      this.updateTripStats(this.vehicleHistory);
      this.groupVehicleLocationToTrips();

      // create map canas url
      setTimeout(() => this.getMapCanvas(), 3000);
    }
  }

  // Add vehicle path to the map


  // Get an array
  getLineCoordinates(data: any[]): any[] {
    // Create array of array coordinates
    const coord = data.map((vehicle) => {
      // return [vehicle.pos_longitude, vehicle.pos_latitude];
      return [vehicle.pos_latitude, vehicle.pos_longitude];
    });

    return coord;
  }

  // Update counts and chart
  updateTripStats(data: any[]): void {
    const speeds = data.map((vh) => vh.pos_speed);
    this.averageSpeed = speeds.reduce((a, b) => a + b) / data.length;

    // Update distance
    this.totalDistance =
      data.map((vh) => vh.distance).reduce((a, b) => a + b);

    // update the cart
    this.lineChartData = [
      {
        data: speeds,
        label: 'Speed',
        pointRadius: 1,
        pointHoverRadius: 1,
        borderWidth: 1,
      },
    ];

    // Update the labels
    this.lineChartLabels = data.map((vh) => vh.pos_gps_time);
  }

  forward(): void {
    // remove marker from the map
    if (this.vehicleLocation) {
      this.map.removeLayer(this.vehicleLocation);
    }

    // get vehicle to index;
    this.currentIndex =
      this.currentIndex === this.vehicleHistory.length - 1
        ? 0
        : this.currentIndex + 1;
    //console.log(this.currentIndex);

    // add to map
    this.addActiveVehicleLocationToMap(this.currentIndex);
  }

  backward(): void {
    // remove marker from the map
    if (this.vehicleLocation) {
      this.map.removeLayer(this.vehicleLocation);
    }

    // get vehicle to index;
    this.currentIndex =
      this.currentIndex === 0
        ? this.vehicleHistory.length - 1
        : this.currentIndex - 1;
    //(this.currentIndex);

    // add to map
    this.addActiveVehicleLocationToMap(this.currentIndex);
  }

  play(): void {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = undefined;
    } else {
      // initialize a interval object
      this.timer = window.setInterval(() => {
        // vehicle location
        this.map.eachLayer((layer) => {
          if (layer instanceof L.Marker) {
            console.log(layer);
            this.map.removeLayer(layer);
          }
        });

        // change the marker position
        this.currentIndex =
          this.currentIndex === this.vehicleHistory.length - 1
            ? 0
            : this.currentIndex + 1;
        //console.log(this.currentIndex);

        // add maker to map
        this.addActiveVehicleLocationToMap(this.currentIndex);
      }, 1000);
    }
  }

  pause(): void {
    // pause the animation
    clearInterval(this.timer);
  }

  stop(): void {
    // clear timer
    clearInterval(this.timer);
  }

  first(): void {
    // remove marker from the map
    if (this.vehicleLocation) {
      this.map.removeLayer(this.vehicleLocation);
    }

    // first index
    this.currentIndex = 0;
    this.addActiveVehicleLocationToMap(this.currentIndex);
  }

  last(): void {
    // remove marker from the map
    if (this.vehicleLocation) {
      this.map.removeLayer(this.vehicleLocation);
    }

    // get last index of vehicle
    this.currentIndex = this.vehicleHistory.length - 1;

    // add to map
    this.addActiveVehicleLocationToMap(this.currentIndex);
  }

  // addActiveMarker to map
  addActiveVehicleLocationToMap(index: number): any {
    // Get current Vehicle location from history
    const currentVehicle = this.vehicleHistory[index];

    // create a custom icon
    const icon = L.icon({
      iconUrl: this.getIconUrl(currentVehicle.pos_speed),
      iconSize: [25, 25],
    });

    // Add vehicleLocation to map and open popup
    this.vehicleLocation = L.marker(
      [currentVehicle.pos_latitude, currentVehicle.pos_longitude],
      {
        icon,
        rotationAngle: currentVehicle.pos_heading,
      }
    )
      .bindPopup(
        `<p><strong>Speed</strong> ${currentVehicle.pos_speed}<br>
        <strong>GPS Time</strong> ${currentVehicle.pos_gps_time}
        </p>`
      )
      .addTo(this.map)
      .openPopup();
  }

  // get Icon Url
  getIconUrl(speed): string {
    if (speed === 0) {
      return '../assets/grey.png';
    } else {
      return '../assets/green.png';
    }
  }

  animateLine(): void {
    this.vehiclePath.snakeIn();
  }

  addVehicleHistory(posId): void {
    const index = this.vehicleHistory.findIndex((vh) => vh.pos_id === posId);
    //console.log(index);

    this.selectedHistory = true;

    // remove marker from the map
    if (this.vehicleLocation) {
      this.map.removeLayer(this.vehicleLocation);
    }

    this.addActiveVehicleLocationToMap(index);
  }

  // Trip history data
  groupVehicleLocationToTrips(): void {
    // update the status
    this.tripReport = this.vehicleHistory.map((vh) => {
      if (vh.pos_inputs === 0) {

        vh.status = 'IGN OFF';
        return vh;
      } else {
        if (vh.pos_speed < 4) {
          vh.status = 'STOPPED IGN ON';
        } else {
          vh.status = 'Normal Speed';
        }

        return vh;
      }
    });

    //console.log(this.tripReport);
    let previousPoint;

    // update time interval
    this.tripReport = this.tripReport.map((vh) => {
      if (previousPoint) {
        const prevDate: any = new Date(previousPoint.pos_gps_time);
        const currentDate: any = new Date(vh.pos_gps_time);

        vh.time = `${currentDate.getHours() - prevDate.getHours()}hrs
                      ${currentDate.getMinutes() - prevDate.getMinutes()
          } minutes
                      ${currentDate.getSeconds() - prevDate.getSeconds()
          } seconds`;
      }

      previousPoint = vh;
      return vh;
    });

    //console.log(this.tripReport);
    this.tripFilter = JSON.parse(JSON.stringify(this.tripReport));

    // Group information to trips
    let currentTrip = 'trip 1';
    let tripCount = 0;
    let trips = [];

    this.tripReport.forEach((trip) => {
      if (trips[0]) {
        if (trip.pos_inputs % 2 === 0) {
          // Update the trip with
          trips[tripCount].location.push(trip);

          // increment trip number by one
          tripCount += 1;
          currentTrip = `trip ${tripCount + 1}`;

          // Create a trip
          trips[tripCount] = { Name: currentTrip, location: [] };

        } else {
          trips[tripCount].location.push(trip);
        }
      } else {
        trips[0] = { Name: currentTrip, location: [] };
        trips[tripCount].location.push(trip);
      }
    });

    //console.log(trips);
    // Remove trips with empty location
    trips = trips.filter(trip => trip.location[0]);

    // Update stops
    let prevTrip;
    let stopCount = 1;
    let stopName = 'Stop 1';

    trips.forEach((trip, i) => {
      const currentTripLength = trip.location.length - 1;
      let startDate;
      let stopDate;
      let duration;

      if (prevTrip) {
        const prevTripLength = prevTrip.location.length - 1;

        // If it is the last trip: stop should be form input to value
        if ((trips.length - 1) === i) {
          stopDate = new Date(this.toDate);
          startDate = new Date(trip.location[currentTripLength].pos_gps_time);

          duration = `${stopDate.getHours() - startDate.getHours()}hrs
                        ${stopDate.getMinutes() - startDate.getMinutes()} minutes
                        ${stopDate.getSeconds() - startDate.getSeconds()} seconds`;

          trip.LastStop = {
            Name: 'Last Stop',
            start: startDate,
            stop: stopDate,
            duration
          };

          // return trip;
        }

        // Increment trip count
        stopCount += 1;
        stopName = `Stop ${stopCount}`;

        // Dates
        stopDate = new Date(trip.location[0].pos_gps_time);
        startDate = new Date(prevTrip.location[prevTripLength].pos_gps_time);
        duration = `${stopDate.getHours() - startDate.getHours()}hrs
                    ${stopDate.getMinutes() - startDate.getMinutes()} minutes
                    ${stopDate.getSeconds() - startDate.getSeconds()} seconds`;

        // create stop object
        trip.stop = {
          Name: stopName,
          start: startDate,
          stop: stopDate,
          duration
        };

        // assign previous trip to trip and return the trip
        prevTrip = trip;
        return trip;
      }

      // If it is the last trip: stop should be form input to value
      if ((trips.length - 1) === i) {
        // Dates
        stopDate = new Date(this.toDate);
        startDate = new Date(trip.location[0].pos_gps_time);

        duration = `${stopDate.getHours() - startDate.getHours()}hrs
                    ${stopDate.getMinutes() - startDate.getMinutes()} minutes
                    ${stopDate.getSeconds() - startDate.getSeconds()} seconds`;

        trip.LastStop = {
          Name: 'Last Stop',
          start: startDate,
          stop: stopDate,
          duration
        };
      }

      // Dates and duration
      stopDate = new Date(trip.location[0].pos_gps_time);
      startDate = new Date(this.fromDate);
      duration = `${stopDate.getHours() - startDate.getHours()}hrs
                  ${stopDate.getMinutes() - startDate.getMinutes()} minutes
                  ${stopDate.getSeconds() - startDate.getSeconds()} seconds`;

      // assign previous trip to trip and return the trip
      trip.stop = {
        Name: stopName,
        start: startDate,
        stop: stopDate,
        duration
      };

      // assign previous trip to trip and return the trip
      prevTrip = trip;
      return trip;
    });

    // add trip information: start, stop, duration, distance
    trips.forEach((trip) => {
      trip.start = trip.location[0].pos_gps_time;
      trip.end = trip.location[trip.location.length - 1].pos_gps_time;

      // Convert time string to time object
      const currentDate = new Date(trip.start);
      const prevDate = new Date(trip.end);

      // Calulate the trip duration
      trip.duration = `${currentDate.getHours() - prevDate.getHours()}hrs
                          ${currentDate.getMinutes() - prevDate.getMinutes()} minutes
                          ${currentDate.getSeconds() - prevDate.getSeconds()} seconds`;

      // get trip distance
      trip.distance = trip.location
        .map((dt) => dt.distance)
        .reduce((a, b) => a + b);
      return trip;
    });

    // assign trip to tripGroups
    this.tripGroups = trips;

    // create a deep copy of tripGroups
    this.tripGroupsCopy = JSON.parse(JSON.stringify(this.tripGroups));

    //console.log(this.tripGroups);

    // Geocode the data
    this.tripReport = this.tripReport.map((vh) => {
      this.dataService
        .r_geocoding({
          lat: vh.pos_latitude.toString(),
          lng: vh.pos_longitude.toString(),
        })
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((response) => {
          //console.log(response);
          if (this.zone === "Africa/Lagos") {
            //console.log(response[0]);
            let plats = response[0].street + ", " + response[0].place + ", " + response[0].region + ", " + response[0].state;
            //let plats = response[0].street + ", " + response[0].place + ", " + response[0].region + ", " response[0].state
            //vh.location = response[0] ? response[0] : {};
            vh.location = plats;
            //console.log(vh);

          } else {
            if (!response.features[0].properties.county) {
              response.features[0].properties.county = response.features[0].properties.city;
            }
            let plats = response.features[0].properties.name + ", " + response.features[0].properties.county + ", " + response.features[0].properties.city + "," + response.features[0].properties.state;
            let obj = plats;
            //console.log(obj);
            vh.location = obj ? obj : {};
            //console.log(vh);
          }
        });
      //console.log(vh);
      return vh;
    });
  }

  displayTripOnMap(name: string): void {
    // Change entire trip status to
    this.isEntireTrip = false;
    // remove from the map
    if (this.vehiclePath) {
      this.map.removeLayer(this.vehiclePath);
    }

    // filter the current trip
    const currenTrip = this.tripGroups.find((trip) => trip.Name === name);

    // Get the coordinates
    const coord = this.getLineCoordinates(currenTrip.location);

    // create the line Path
    this.vehiclePath = L.polyline(coord, {
      color: '#075754',
      weight: 3,
    }).addTo(this.map);

    // Fit map bounds to layer
    this.map.fitBounds(this.vehiclePath.getBounds());

    // Get trip stats  plot the graph
    this.updateTripStats(currenTrip.location);
  }

  //
  loadEntireTrip() {
    this.isEntireTrip = true;
    this.filterOpacity = 0;

    // create a deep copy of tripGroups
    this.tripGroupsCopy = JSON.parse(JSON.stringify(this.tripGroups));

    // remove from the map
    if (this.vehiclePath) {
      this.map.removeLayer(this.vehiclePath);
    }

    //(this.tripReport);
    // Get the coordinates
    const coord = this.getLineCoordinates(this.tripReport);

    // create the line Path
    this.vehiclePath = L.polyline(coord, {
      color: '#075754',
      weight: 3,
    }).addTo(this.map);

    // Fit map bounds to layer
    this.map.fitBounds(this.vehiclePath.getBounds());

    // Get trip stats  plot the graph
    this.updateTripStats(this.tripReport);
  }

  pinStopToMap(name: string) {
    // get the clicked trip
    const stop = this.tripGroups.find(trip => trip.stop.Name === name).location[0];
    //console.log(stop);

    // remove any marker from the map
    this.map.eachLayer(layer => {
      if (layer instanceof L.Marker) {
        this.map.removeLayer(layer);
      }
    });

    // Add trip to map
    L.marker([stop.pos_latitude, stop.pos_longitude], {
      // icon:L.icon({
      //   iconUrl:'../assets/grey.png',
      //   iconSize:[23,23]
      // })
    }).addTo(this.map);


  }

  filterTripByTime() {
    this.isEntireTrip = false;
    const filterTime = this.timeFilter.value.split(':');

    // reset the value
    this.tripFilter = JSON.parse(JSON.stringify(this.tripReport));

    // reset with time
    this.tripFilter = this.tripFilter.filter(trip => {

      let time = trip.pos_gps_time.split(' ')[1];
      time = time.split(':');

      if (time[0] === filterTime[0] && time[1] === filterTime[1]) {
        return trip;
      }
    });

    //console.log(this.tripFilter);
  }

  filterTrips() {
    this.isEntireTrip = false;

    // create a deep copy of tripGroups
    this.tripGroupsCopy = this.tripGroups.length === this.tripGroupsCopy.length ?
      this.tripGroupsCopy : JSON.parse(JSON.stringify(this.tripGroups));

    const dateS = `${this.model.year}-${this.model.month}-${this.model.day} ${this.filterDate.hour}:${this.filterDate.minute}:00`;

    const date = new Date(dateS);

    this.tripGroupsCopy = this.tripGroupsCopy.filter(trip => {
      // start and stop date
      const sDate = new Date(trip.start);
      const stopDate = new Date(trip.end);

      // filter the trips within the dates
      if (sDate < date && stopDate > date) {

        // filter the trips
        const tripLocation = trip.location.filter(lc => {
          // get position time
          let time = lc.pos_gps_time.split(' ')[1];
          time = time.split(':');

          // filter the positions with the given date
          if (time[0] == this.filterDate.hour && time[1] == this.filterDate.minute) {
            return lc;
          }
        });

        // return the date
        trip.location = tripLocation;
        return trip;
      }
    });

    //console.log(this.filterDate);
  }

  generatePDF(action: string): void {
    const documentDefinition = this.getDocumentDefinition();

    // react to the respective action
    switch (action) {
      case 'download':
        pdfMake.createPdf(documentDefinition).download();
        break;
      case 'print':
        pdfMake.createPdf(documentDefinition).print();
        break;
      case 'open':
        pdfMake.createPdf(documentDefinition).open();
        break;
      default:
        pdfMake.createPdf(documentDefinition).open();
        break;
    }

  }

  // Create pdfMake doc definition
  getDocumentDefinition() {
    return {
      content: [
        this.reportGenerator.createPageHeader(this.selectedVehicle.dev_name + ' Trip/History Report', [this.fromDate]),
        {
          // Vehicle information
          columns: [
            [
              {
                text: 'Driver Name: ' + this.selectedVehicle.dev_driver_name,
                margin: [0, 5, 0, 0],
                style: 'attr'

              },
              {
                text: 'Driver Mobile:  ' + this.selectedVehicle.dev_driver_mobile,
                margin: [0, 0, 0, 0],
                style: 'attr'
              },
              {
                text: 'License Plate: ' + this.selectedVehicle.dev_license_plate,
                margin: [0, 0, 0, 0],
                style: 'attr'
              }
            ],

            [
              {
                text: 'Start Time: ' + Object.values(this.from).join(':'),
                margin: [0, 5, 0, 0],
                alignment: 'center',
                style: 'attr'
              },
              {
                text: 'End Time: ' + Object.values(this.to).join(':'),
                margin: [0, 0, 0, 0],
                alignment: 'center',
                style: 'attr'
              }
            ],
            [
              {
                text: 'Total Distance: ' + Math.round(this.totalDistance) + ' Km',
                margin: [0, 5, 0, 0],
                alignment: 'right',
                style: 'attr'
              },
              {
                text: 'Average Speed: ' + Math.round(this.averageSpeed) + ' km/hr',
                margin: [0, 0, 0, 10],
                alignment: 'right',
                style: 'attr'
              }
            ]
          ]
        },

        {
          image: this.mapCanvasUrl,
          width: 580,
          height: 250,
          alignment: 'center',
          margin: [0, 10, 0, 10]
        },
        {
          text: 'Trip Summary',
          fontSize: 11,
          margin: [0, 15, 0, 10],
          alignment: 'center'
        },
        this.getSummaryInformation(),
        {
          text: 'Entire trip Report',
          fontSize: 11,
          alignment: 'center',
          margin: [0, 20, 0, 15]
        },
        this.getVehicleTable()
      ],
      styles: {
        header: {
          fontSize: 14,
          bold: true,
        },
        tableHeader: {
          bold: true,
          fontSize: 9
        },
        attr: {
          fontSize: 10,
          bold: false
        }
      },
      info: {
        title: 'Trip/ History Report',
        author: this.username,
        subject: 'Trip/ History Report',
        keywords: 'trip, history, report',
      },
    };
  }

  // create trip summary object
  getSummaryInformation() {
    return {
      table: {
        widths: [60, 60, 60, 200, 100],
        headerRows: 1,
        body: [
          [
            {
              text: 'Name',
              style: 'tableHeader'
            },
            {
              text: 'Start',
              style: 'tableHeader'
            },
            {
              text: 'Stop',
              style: 'tableHeader'
            },
            {
              text: 'Duration',
              style: 'tableHeader',
            },
            {
              text: 'Distance (Km)',
              style: 'tableHeader'
            }
          ],
          ...this.createTripSummaryTable(this.tripGroups)
        ]
      },
      layout: 'lightHorizontalLines'
    };
  }

  createTripSummaryTable(tripHistory) {
    const tableRows = [];

    tripHistory.forEach((trip, index) => {
      const stop = [
        {
          text: trip.stop.Name,
          fontSize: 9,
          bold: false,
          color: '#b2bb1c'
        },
        {
          text: trip.stop.start.toTimeString().split(' ')[0],
          fontSize: 9,
          bold: false,
          color: '#b2bb1c'
        },
        {
          text: trip.stop.stop.toTimeString().split(' ')[0],
          fontSize: 9,
          bold: false,
          color: '#b2bb1c'
        },
        {
          text: trip.stop.duration.split('\n').join(''),
          fontSize: 9,
          bold: false,
          color: '#b2bb1c'
        },
        {
          text: '',
          fontSize: 9,
          bold: false,
          color: '#b2bb1c'
        }
      ];

      tableRows.push(stop);

      const tripSummary = [
        {
          text: trip.Name,
          fontSize: 9,
          bold: false,
        },
        {
          text: trip.start.split(' ')[1],
          fontSize: 9,
          bold: false,
        },
        {
          text: trip.end.split(' ')[1],
          fontSize: 9,
          bold: false,
        },
        {
          text: trip.duration.split('\n').join(''),
          fontSize: 9,
          bold: false
        },
        {
          text: trip.distance.toFixed(3),
          fontSize: 9,
          bold: false
        }
      ];

      tableRows.push(tripSummary);

      if (index == tripHistory.length - 1) {
        // add the last stop
        const lastStop = [
          {
            text: trip.LastStop.Name,
            fontSize: 9,
            bold: false,
            color: '#b2bb1c'
          },
          {
            text: trip.LastStop.start.toTimeString().split(' ')[0],
            fontSize: 9,
            bold: false,
            color: '#b2bb1c'
          },
          {
            text: trip.LastStop.stop.toTimeString().split(' ')[0],
            fontSize: 9,
            bold: false,
            color: '#b2bb1c'
          },
          {
            text: trip.LastStop.duration.split('\n').join(''),
            fontSize: 9,
            bold: false,
            color: '#b2bb1c'
          },
          {
            text: '',
            fontSize: 9,
            bold: false,
            color: '#b2bb1c'
          }
        ];

        tableRows.push(lastStop);
      }


    });
    return tableRows;
  }


  getVehicleTable() {
    // vehicle table layer
    return {
      table: {
        widths: ['*', 100, 50, 300],
        headerRows: 1,
        body: [
          [
            {
              text: 'Time',
              style: 'tableHeader'
            },
            {
              text: 'Status',
              style: 'tableHeader'
            },
            {
              text: 'Speed',
              style: 'tableHeader'
            },
            {
              text: 'Location',
              style: 'tableHeader',
            }
          ],
          ...this.setTextStyle()
        ]
      },
      layout: 'lightHorizontalLines'
    };
  }

  setTextStyle() {
    const output = [];
    this.vehicleHistory.forEach(history => {
      // get the colors
      const color = history.status === 'Normal Speed' ?
        'green' : history.status === 'Overspeed' ?
          'red' : history.status === 'STOPPED IGN ON' ?
            'blue' : 'gray';

      const styleObject = [
        {
          text: history.pos_gps_time.split(' ')[1],
          color,
          fontSize: 9,
          alignment: 'left'
        },
        {
          text: history.status,
          color,
          fontSize: 9,
          alignment: 'left'
        },
        {
          text: history.pos_speed,
          color,
          fontSize: 9,
          alignment: 'left'
        },
        {
          text: history.location,
          color,
          fontSize: 9,
          alignment: 'left'
        }
      ];

      output.push(styleObject);
    });

    return output;
  }

  getMapCanvas() {

    html2canvas(this.myMap.nativeElement, {
      useCORS: true
    }).then(canvas => {
      // update the data url to the map
      this.mapCanvasUrl = canvas.toDataURL('image/png');

      this.ispdfReady = true;
    });
  }

  // creata a base64Image
  getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute('crossOrigin', 'anonymous');
      img.onload = () => {
        var canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        var dataURL = canvas.toDataURL('image/png');

        //console.log(dataURL);
        resolve(dataURL);
      };
      img.onerror = error => {
        reject(error);
      };
      img.src = url;
    });
  }


  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();

    window.clearInterval(this.timer);
  }
}
