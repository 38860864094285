//export const GLOBAL_URL = 'http://127.0.0.1:4002/api';
export const GLOBAL_URL = 'https://customer.trackonnet.net/api';
// export const SIGNIN_URL = 'http://127.0.0.1:3002';
export const ProxyURL = 'https://customer.trackonnet.net/proxy';
//export const ProxyURL = 'https://trackonnet.se/proxy';
// export const API_URL = 'http://localhost:4002/api';
// export const GLOBAL_URL = 'https://api.tictrack.com/api';
// export const SIGNIN_URL = 'https://api.tictrack.com/api';
export const SIGNIN_URL = 'https://trackonnet.net/api';
// export const ProxyURL = 'https://api.tictrack.com';
// export const RGEO_CODE = 'http://90.230.253.17:3010/reverse_geocode';
// export const RGEO_CODE = 'http://127.0.0.1:3010/reverse_geocode';
// export const RGEO_CODE = 'http://5.189.164.45:3010/reverse_geocode';
export const RGEO_CODE = 'https://customer.trackonnet.net/reverse_geocode';