import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Vehicle } from '../models/vehicle';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Subject } from 'rxjs';
import { DataService } from '../services/data.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { takeUntil, subscribeOn } from 'rxjs/operators';
import { AlertService } from '../services/alert.service';
import { RouteService } from '../services/route.service';
import { RouteInfo } from '../models/route-info';
import { Geofence } from '../geofence';
import { Alert } from '../models/alert';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss']
})
export class AlertsComponent implements OnInit {

  alertForm = new FormGroup({
    vehicle: new FormControl('', Validators.required),
    criteriaName: new FormControl({ value: '', disabled: true }, Validators.required),
    alertType: new FormControl('', Validators.required),
    criteriaValue: new FormControl('', Validators.required),
    geofenceCategory: new FormControl(''),
    email: new FormControl('', Validators.email),
    alertCategory: new FormControl('', Validators.required),
    object_id: new FormControl(''),
    routes: new FormControl(),
    geofences: new FormControl(),
    concreteMixerStatus: new FormControl()
  });


  selectedVehicle: Vehicle;
  routeVehicles: Vehicle[] = [];
  routeSelected: RouteInfo;
  geofenceSelected: any;
  selectedObject: any = {
    id: '',
    name: ''
  };

  // Multiselect
  vehicleList = [];
  allDevices: Vehicle[];
  selectedItems = [];
  dropdownSettings: IDropdownSettings = {};

  // routes
  routes: RouteInfo[];
  geofences: Geofence[];

  alertSearch = new FormControl('');
  selectedAlert: Alert;
  alertRules: any;

  spinnerStatus = 'update';
  action = 'Create';
  buttonTitle = 'Create';

  customerName = localStorage.getItem('cusName');
  customerID = localStorage.getItem('cusId');
  userName = localStorage.getItem('userName');
  userMapType = localStorage.getItem('userMapType');
  userMaster = localStorage.getItem('userIsMaster');
  userID = localStorage.getItem('userId');

  onDestroy$ = new Subject();

  constructor(
    public dataService: DataService,
    public spinner: NgxSpinnerService,
    private modalService: NgbModal,
    private alertService: AlertService,
    private routeService: RouteService
  ) { }

  ngOnInit(): void {
    this.spinner.show();
    this.spinnerStatus = 'Fetching Data ....';

    // get all Devices
    this.dataService.getVehicle({
      userId: this.userID,
      cus_id: this.customerID,
      userIsMaster: this.userMaster,
    })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(response => {

        this.allDevices = response;
        this.vehicleList = response;
        this.spinner.hide();

      });



    this.alertSearch.valueChanges.subscribe(term => {
      // this.filterVehicles = this.availableDevices.filter(vh => vh.dev_name.includes(term))
    });

    // Multi-select
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'dev_id',
      textField: 'dev_name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    this.getAlertRules();
    this.getRoutes();
    this.getGeofence();

    // listen to form input changes
    this.alertForm.get('alertCategory').valueChanges.subscribe(value => {
      // update the criteria Name
      this.alertForm.patchValue({
        criteriaName: ''
      });

      if (value !== 'geofence' || value !== 'routing') {
        this.selectedObject = {
          id: '',
          name: ''
        };
      }

    });

    // listen to form input changes
    this.alertForm.get('geofenceCategory').valueChanges.subscribe(value => {
      // update the criteria Name
      if (this.selectedAlert) {

        this.alertForm.patchValue({
          criteriaName: this.selectedAlert.criteria_name
        });

      } else {

        this.alertForm.patchValue({
          criteriaName: value
        });

      }

    });

    // listen to change in routes
    this.alertForm.get('routes').valueChanges.subscribe(value => {
      if (value === '') {

      } else {

        let route = this.routes.find(route => route.name === value);


        if (this.selectedAlert) {
          route = this.routes.find(route => route.route_id === this.selectedAlert.object_id);

          if (route) {
            this.selectedObject = {
              id: route.route_id,
              name: route.name
            };
          }


          this.alertForm.patchValue({
            criteriaName: this.selectedAlert.criteria_name
          });

        } else {

          this.selectedObject = {
            id: route.route_id,
            name: route.name
          };

          this.alertForm.patchValue({
            criteriaName: 'off-route_' + this.selectedObject.id
          });

        }
      }

    });

    // listen to change in routes
    this.alertForm.get('geofences').valueChanges.subscribe(value => {
      if (value === '') {

      } else {
        // update the form information
        let geofence = this.geofences.find(geofence => geofence.geofence_name === value);


        if (this.selectedAlert) {
          geofence = this.geofences.find(geofence => geofence.geofence_id === this.selectedAlert.object_id);

          this.alertForm.patchValue({
            criteriaName: this.selectedAlert.criteria_name
          });

          if (geofence) {
            this.selectedObject = {
              id: geofence.geofence_id,
              name: geofence.geofence_name
            };
          }


        } else {

          this.selectedObject = {
            id: geofence.geofence_id,
            name: geofence.geofence_name
          };

          this.alertForm.patchValue({
            criteriaName: value
          });

        }
      }
    });
    //
  }

  getAlertRules() {

    this.dataService.getAlertRules({
      cus_id: this.customerID
    })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((response) => {
        //console.log(response);
        this.alertRules = response;
      });
  }

  // get all the client routes
  getRoutes() {
    this.routeService.getRoutes({
      cus_id: this.customerID
    })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(response => {

        //console.log(response);

        this.routes = response;

      });

  }

  getGeofence() {

    this.dataService.getGeofence({
      cus_id: this.customerID
    })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(response => {
        this.geofences = response;
        //console.log(response);
      });

  }

  getAlertInformation(dev_id: number, alert_type: number, cus_id: string, object_id: number, criteria_value: number) {

    this.selectedAlert = this.alertRules.find(alert => {

      if (alert.dev_id === dev_id && alert.alert_type === alert_type
        && alert.cus_id === cus_id && alert.object_id === object_id && alert.criteria_value === criteria_value) {

        return alert;
      }

    });

    this.selectedVehicle = this.allDevices.find(dev => dev.dev_id === this.selectedAlert.dev_id);

    // clean or the field
    this.alertForm.patchValue({
      alertCategory: '',
      alertType: '',
      criteriaName: '',
      criteriaValue: '',
      geofenceCategory: '',
      email: '',
      object_id: '',
      routes: '',
      geofences: ''
    });


    if (this.selectedAlert) {
      this.action = 'Update';
      this.buttonTitle = 'Update';

      // Patch the form values
      this.alertForm.patchValue({
        alertCategory: this.selectedAlert.alert_category,
        alertType: this.selectedAlert.alert_type,
        criteriaName: this.selectedAlert.criteria_name,
        criteriaValue: this.selectedAlert.criteria_value,
        email: this.selectedAlert.emails
      });

      // update the routes or the geofences
      if (this.selectedAlert.alert_category === 'geofence') {
        const geofence = this.geofences.find(geofence => geofence.geofence_id === this.selectedAlert.object_id);

        //console.log(geofence);

        this.alertForm.patchValue({
          geofenceCategory: this.selectedAlert.criteria_name.split('_')[0],
          geofences: geofence.geofence_name
        });

      } else if (this.selectedAlert.alert_category === 'routing') {
        const route = this.routes.find(route => route.route_id === this.selectedAlert.object_id);

        this.alertForm.patchValue({
          routes: route.name
        });

      } else if (this.selectedAlert.alert_category === 'concrete-mixer') {

        this.alertForm.patchValue({
          concreteMixerStatus: this.selectedAlert.criteria_name.split('_')[0],
        });

      } else {

      }


    }
  }

  // submit form
  onSubmit(content) {
    // validate the form
    if (this.selectedAlert) {

      this.action = 'Update';
      this.buttonTitle = 'Update';
      this.spinnerStatus = 'Update alert: ';

      // update the vehicle value
      this.alertForm.get('vehicle').setValue(this.selectedVehicle.dev_id);

    } else {

      this.action = 'Create';
      this.buttonTitle = 'Create';
      this.spinnerStatus = 'Create alert ....';
    }

    if (this.alertForm.valid) {
      this.modalService.open(content, { centered: true }).result.then(result => {
        if (result === 'Cancel') {

        } else {
          // Update or create
          this.action === 'Update' ? this.updateAlert() : this.createAlert();
        }
      });

    } else {
      console.log('Error');
      throw (
        new Error('Fill the required field')
      );
    }
  }

  createAlert() {
    const data = this.alertForm.getRawValue();
    const vehilces = data.vehicle;
    //console.log(data);

    this.spinner.show();
    vehilces.forEach(element => {

      this.alertService.createAlert({
        cus_id: localStorage.getItem('cusId'),
        dev_id: element.dev_id,
        criteria_name: this.getCriteriaName(data, element.dev_id),
        criteria_value: data.criteriaValue,
        alert_type: data.alertType,
        email: data.email,
        alert_category: data.alertCategory,
        object_id: this.selectedObject.id,
        object_name: this.selectedObject.name
      })
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(response => {
          //console.log(response);

          this.getAlertRules();
          this.spinner.hide();

          this.resetForm();
        });

    });


  }

  getCriteriaName(data, dev_id): string {
    if (data.alertCategory === 'routing') {
      return 'off-route' + '_' + this.selectedObject.id + '_' + data.criteriaValue + '_' + dev_id;

    } else if (data.alertCategory === 'geofence') {

      return data.geofenceCategory + '_' + this.selectedObject.id + '_' + data.criteriaValue + '_' + dev_id;

    } else if (data.alertCategory === 'speed') {

      return data.alertCategory + '_' + this.selectedObject.id + '_' + data.criteriaValue + '_' + dev_id;

    } else {

      return data.concreteMixerStatus + '_' + data.criteriaValue + '_' + dev_id;
    }

  }


  updateAlert() {
    const data = this.alertForm.getRawValue();
    //console.log(data);
    this.spinner.show();

    // send the request
    this.alertService.updateAlert({
      dev_id: this.selectedAlert.dev_id,
      criteria_name: this.getCriteriaName(data, this.selectedAlert.dev_id),
      criteria_value: data.criteriaValue,
      cus_id: (this.customerID),
      alert_type: data.alertType,
      emails: data.email,
      alert_category: data.alertCategory,
      object_id: this.selectedObject.id,
      object_name: this.selectedObject.name
    })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(response => {
        //console.log(response);

        this.getAlertRules();
        this.spinner.show();
        this.resetForm();
      });

  }

  deleteAlert(content, dev_id: number, alert_type: number, criteria_name: string, object_id: number) {
    // call to the api to delete the vehicle
    this.action = 'Delete';
    this.buttonTitle = 'Delete';
    this.spinnerStatus = 'Delete Alert';

    this.modalService.open(content, { centered: true }).result.then(result => {
      if (result === 'Cancel') {
        // reselect the vehicl again
        this.action = 'Create';
        this.buttonTitle = 'Create';
      } else {

        // call to the api to delete the vehicle
        this.action = 'Create';
        this.buttonTitle = 'Create';

        this.spinner.show();

        this.alertService.deleteAlert({
          object_id,
          alert_type,
          cus_id: localStorage.getItem('cusId'),
          dev_id,
          criteria_name
        })
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(response => {
            //console.log(response);

            this.getAlertRules();
            this.spinner.hide();
            this.resetForm();
          });

      }

    });

  }

  // MultiSelect Event Listeners
  onItemDeSelect(item, content) {
    //console.log(item);
    this.modalService.open(content, { centered: true }).result.then(result => {
      if (result === 'Cancel') {
        // reselect the vehicl again
        this.selectedItems.push(item);
      } else {

        // call to the api to delete the vehicle
        this.selectedVehicle = this.allDevices.find(dev => dev.dev_id === item.dev_id);

      }
    });

  }

  onItemSelect(item) {
    // add the item to route vehicle
    const vehicle = this.allDevices.find(dev => dev.dev_id === item.dev_id);

    this.routeVehicles.push(vehicle);

  }

  onSelectAll(items) {
    //console.log(items);
    this.routeVehicles = this.allDevices;

    //console.log(this.routeVehicles);
  }

  resetForm() {
    this.vehicleList = this.allDevices;

    // clean or the field
    this.alertForm.setValue({
      alertCategory: '',
      alertType: '',
      criteriaName: '',
      criteriaValue: '',
      geofenceCategory: '',
      email: '',
      object_id: '',
      routes: '',
      geofences: '',
      vehicle: '',
      concreteMixerStatus: ''
    });

    this.selectedAlert = this.selectedAlert ? undefined : this.selectedAlert;

    this.action = 'Create';
    this.buttonTitle = 'Create';
  }

  OnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }


}
