import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { RouteInfo } from '../models/route-info';
import { catchError, delay, map } from 'rxjs/operators';
import { ProxyURL, GLOBAL_URL } from '../models/globals';

@Injectable({
  providedIn: 'root'
})
export class RouteService {
  API_URL = GLOBAL_URL;
  PROXY_URL = ProxyURL;

  constructor(private httpClient: HttpClient) {

  }

  // get routes
  getRoutes(customer): Observable<RouteInfo[]> {
    return this.httpClient.post<RouteInfo[]>(`${this.API_URL}/get_routes`, customer)
      .pipe(
        catchError(error => throwError(error.message))
      );
  }

  createRoute(route): Observable<any> {
    //(route);
    return this.httpClient.post<any>(`${this.API_URL}/create_route`, route)
      .pipe(
        catchError(error => throwError(new Error('Failed to add the route to the database: Retry')))
      );
  }

  // update route
  updateRoute(route: RouteInfo): Observable<any> {
    return this.httpClient.post<any>(`${this.API_URL}/update_route`, route)
      .pipe(
        catchError(error => throwError(new Error('Failed to update route ' + route.name))));
  }

  deleteRoute(route): Observable<any> {
    return this.httpClient.post<any>(`${this.API_URL}/delete_route`, route)
      .pipe(
        catchError(error => throwError(new Error('Failed to delete route ' + route.name)))
      );
  }

  getRouteProxy(waypoints): Observable<any> {
    //(waypoints);
    return this.httpClient.get(this.PROXY_URL + '/proxy', { params: waypoints })
      .pipe(
        map(response => {
          //console.log(response);
          return response;
        }),
        //  delay(5),
        catchError(error => throwError(new Error('Proxy error')))
      );
  }

}
