import { Injectable } from '@angular/core';
import { Vehicle } from '../models/vehicle';

var moment = require('moment-timezone');


@Injectable({
    providedIn: 'root',
})
export class TimeZoneService {

    convertVehiclesTimezone(vehicles: Vehicle[], currentTimezone: string, timezone: string): Vehicle[] {
        vehicles.map(vehicle => {
            //console.log("Current: " + currentTimezone);
            //console.log("ToZone: " + timezone);
            //console.log("Before: " + vehicle.dev_gps_time);
            vehicle.dev_gps_time = moment.tz(vehicle.dev_gps_time, currentTimezone).tz(timezone).format();
            //console.log("VT: " + vehicle.dev_gps_time)
            return vehicle;
        })
        return vehicles;
    }

    convertTimeToTimezone(time: string, fromTimeZone: string, toTimeZone: string): string {
        return moment.tz(time, fromTimeZone).tz(toTimeZone).format();
    }

}