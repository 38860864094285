import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { EncrService } from '../services/encr.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {
  signinForm: FormGroup;
  constructor(
    public fb: FormBuilder,
    public authService: AuthService,
    public encrServ: EncrService,
    public router: Router
  ) {
    this.signinForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  ngOnInit(): void { }
  loginUser() {
    this.signinForm.value.password = this.encrServ.set(
      this.signinForm.value.password
    );
    this.authService.signIn(this.signinForm.value);
  }
  getToken() {
    const tok = this.authService.getJwtToken();
  }
  logout() {
    this.authService.doLogoutUser();
  }
}
