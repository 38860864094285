<!--
<div class="login_form">
  <div class="login-wrapper">
    <form id="login" [formGroup]="signinForm" (ngSubmit)="loginUser()">
      <label for="username">Användarnamn</label>
      <input id="username" type="username" class="form-control" formControlName="username" placeholder="Epost"
        required />

      <label for="password">Lösenord</label>
      <input type="password" class="form-control" formControlName="password" placeholder="Lösenord" required />
      <button type="submit" [disabled]="signinForm.pristine || signinForm.invalid">
        Login
      </button>
      <div *ngIf="
          signinForm.controls['username'].invalid &&
          (signinForm.controls['username'].dirty ||
            signinForm.controls['username'].touched)
        " class="alert alert-danger">
        <div *ngIf="
            signinForm.controls['username'].errors.required ||
            signinForm.controls['password'].errors.required
          ">
          Användarnamn eller Lösenord saknas.
        </div>
      </div>
    </form>
  </div>
</div>

-->
<div class="wrapper fadeInDown">
  <div id="formContent">
    <div class="fadeIn first">
      <!-- Icon -->
      <img src="../../assets/home-logo.png" style="width:130px;height:90px;" id="icon" alt="User Icon" />
    </div>
    <div class="fadeIn first">
      <!-- Icon -->
      <h4 class="active">Ver. 230203</h4>
    </div>

    <!-- Tabs Titles -->
    <h2 class="active">login</h2>

    <!-- Login Form -->
    <form [formGroup]="signinForm" (ngSubmit)="loginUser()">
      <input type="text" class="fadeIn second form-control" formControlName="username" placeholder="Username" />
      <input type="password" class="fadeIn third from-control" formControlName="password" placeholder="Password" />
      <input type="submit" class="fadeIn fourth" value="Login" />
    </form>
  </div>
</div>