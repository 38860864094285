import {
  Injectable, NgZone
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(
    public snackBar: MatSnackBar,
    public zone: NgZone,
    public spinner: NgxSpinnerService,
    ) {

  }

  showSuccess(message: string) {
    this.snackBar.open(message);

    // this.zone.run(()=>{
    //   this.snackBar.open(message);
    // });
  }

  showError(message: string) {
    this.snackBar.open(message, 'X', { panelClass: ['error'] });
    this.spinner.hide();
  }
}
