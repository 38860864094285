import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

import { DataService } from '../services/data.service';
import { ReportGeneratorService } from '../services/report-generator.service';

import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import pdfMake from 'pdfmake/build/pdfmake';
import { TimeZoneService } from '../services/timezone.service';

@Component({
  selector: 'app-violation-report',
  templateUrl: './violation-report.component.html',
  styleUrls: ['./violation-report.component.scss']
})
export class ViolationReportComponent implements OnInit, OnDestroy, AfterViewInit {

  mileageForm = new FormGroup({
    vehicle: new FormControl('', Validators.required),
    from: new FormControl('', Validators.required),
    to: new FormControl('', Validators.required)
  });

  ShowFilter = true;
  limitSelection = false;
  vehicles: any[] = [];

  selectedItems: any[] = [];
  dropdownSettings: any = {};

  // User information
  userId;
  cus_id;
  userIsMaster;
  username;
  customerName;

  // Selected Vehicles
  public selectedVehicle;
  spinnerText;

  ispdfReady = true;
  // Destroy the subscriber
  private onDestroy$ = new Subject<void>();

  // Datepicker
  public fromModel: NgbDateStruct;
  public toModel: NgbDateStruct;

  minimumDate = { day: 20, month: 4, year: 2020 };
  maximumDate = {
    day: 26,
    month: 4,
    year: 2020
  };

  // From and two date strings
  fromDate;
  toDate;

  constructor(
    private dataService: DataService,
    public spinner: NgxSpinnerService,
    public reportGenerator: ReportGeneratorService,
    private timeZoneService: TimeZoneService
  ) {
    // Customer infomation
    this.userId = localStorage.getItem('userId');
    this.cus_id = localStorage.getItem('cusId');
    this.userIsMaster = localStorage.getItem('userIsMaster');
    this.username = localStorage.getItem('userName');
    this.customerName = localStorage.getItem('cusName');
  }

  ngOnInit(): void {
    this.spinnerText = 'Loading Vehicle Data ...';

    this.spinner.show();

    // Get vehicle data
    this.dataService.getVehicle({
      userId: this.userId,
      cus_id: this.cus_id,
      userIsMaster: this.userIsMaster
    })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(response => {
        // update the vehicle object
        this.vehicles = response;
        this.spinner.hide();

      });

    // dropdown setting
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'dev_id',
      textField: 'dev_name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: this.ShowFilter
    };

  }

  ngAfterViewInit(): void {
    // update minimun data and maximum date when
    this.mileageForm.get('from').valueChanges.subscribe(response => {

      // update minimum and maximum data constraints
      this.minimumDate = response;
      this.maximumDate = {
        day: response.day + 7,
        month: response.month,
        year: response.year
      };

      // empty the to value
      this.mileageForm.get('to').patchValue('');

      // update fromDate
      this.fromDate = Object.values(response).join('-');
    });

    this.mileageForm.get('to').valueChanges.subscribe(response => {
      // format and update toDate
      this.toDate = Object.values(response).join('-');
    });
  }

  onSubmit() {
    !this.mileageForm.valid ? alert('Fill all the required fields') : this.getViolation();

  }

  getViolation() {
    // empty selected vehicles
    this.selectedVehicle = this.selectedVehicle ? undefined : this.selectedVehicle;

    // get the form data
    const vehicles = this.mileageForm.get('vehicle').value;
    const startDate = this.mileageForm.get('from').value;
    const endDate = this.mileageForm.get('to').value;


    // Update spinner text
    this.spinnerText = 'Loading Violation Data ...';
    this.spinner.show();

    // update selected vehicles
    this.selectedVehicle = this.vehicles.filter(vehicle => {
      // get the vehicle from selection
      const currentVehicle = vehicles.find(vh => vh.dev_id === vehicle.dev_id);
      if (currentVehicle) {
        return vehicle;
      }
    });

    const lagos = localStorage.getItem("Timezone");
    const gmt = "GMT";
    // Request the data
    vehicles.forEach((vehicle, i) => {
      // format the data object to be sent to the server
      const data = {
        from: this.timeZoneService.convertTimeToTimezone(Object.values(startDate).join('-').concat(' 00:00:00'), lagos, gmt),
        to: this.timeZoneService.convertTimeToTimezone(Object.values(endDate).join('-').concat(' 23:59:59'), lagos, gmt),
        dev_id: vehicle.dev_id,
      };
      //console.log({ violationdata: data });

      // get the violation history
      this.dataService.getViolationHistory(data)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(response => {
          // Check for errors
          if (response.length === 1 && response[0].error) {
            alert('Resubmit the form');
          } else {

            if (response.length === 0) {
              // Zero mileage if empty array
              this.selectedVehicle.forEach(vh => {

                // Update the vehicle with given id
                if (vh.dev_id === data.dev_id) {
                  vh.violations = [];
                }

                return vh;
              });

              this.spinner.hide();
            } else {
              // Hode the spinner and format violations
              this.spinner.hide();
              this.addViolationInformation(response, data.dev_id);
            }
          }

        });
    });

    this.selectedVehicle.forEach(vh => {
      this.dataService
        .r_geocoding({
          lat: vh.dev_latitude.toString(),
          lng: vh.dev_longitude.toString()
        })
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((response) => {
          vh.location = response[0] ? response[0] : {};
        });

      return vh;
    });
  }

  addViolationInformation(response: any, id: number) {
    // geocode the response
    response.forEach(res => {
      const posLog = res.pos_log.split(',');
      //console.log(posLog);

      // get lat and lng
      const lat = posLog[3];
      const lng = posLog[2];



      if (res.criteria_name.includes('geofence-relativity')) {
        // geofence realtivity values
        res.actual_value = res.criteria_value === 1 ? 'Entered ' + res.object_name : 'Left ' + res.object_name;

      } else {
        // get actual value
        const actualValue = posLog[4];
        res.actual_value = actualValue;
      }

      // geocode data
      this.dataService.r_geocoding({
        lat: lat.toString(),
        lng: lng.toString()
      })
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(geocode => {
          res.location = geocode[0] ? geocode[0] : {};
        });

      return res;
    });

    // add violation properties
    this.selectedVehicle.forEach(vehicle => {
      if (vehicle.dev_id === id) {
        vehicle.violations = response;
      }
    });
  }


  generatePDF(action: string) {
    // open, download and print a table
    const documentDefinition = this.getDocumentDefinition();

    // react to the respective action
    switch (action) {
      case 'download':
        pdfMake.createPdf(documentDefinition).download();
        break;
      case 'print':
        pdfMake.createPdf(documentDefinition).print();
        break;
      case 'open':
        pdfMake.createPdf(documentDefinition).open();
        break;
      default:
        pdfMake.createPdf(documentDefinition).open();
        break;
    }
  }

  getDocumentDefinition() {
    return {
      content: [
        this.reportGenerator.createPageHeader('Violation Report', [this.fromDate, this.toDate]),
        ...this.createViolationTables()
      ],
      styles: {
        header: {
          fontSize: 14,
          bold: true,
        },
        tableHeader: {
          bold: true,
          fontSize: 9
        },
        attr: {
          fontSize: 10
        }
      },
      info: {
        title: 'Violation Report',
        author: this.username,
        subject: 'Trip/ History Report',
        keywords: 'trip, history, report',
      },
    };
  }

  getTableData(vehicles) {
    const output = [];

    vehicles.violations.forEach(violation => {
      const dataObject = [
        {
          text: violation.alert_time,
          fontSize: 9,
          alignment: 'left'
        },
        {
          text: violation.criteria_name,
          fontSize: 9,
          alignment: 'left'
        },
        {
          text: violation.criteria_value,
          fontSize: 9,
          alignment: 'left'
        },

        {
          text: violation.actual_value,
          fontSize: 9,
          alignment: 'left'
        },
        {
          text: violation.location.street || '' + ', ' + violation.location.neighbourhood || ''
            + ', ' + violation.location.ua || '' + ', ' + violation.location.state || '',
          // text:violation.criteria_name + ', '+ violation.criteria_name,
          fontSize: 9,
          alignment: 'left'
        }
      ];

      output.push(dataObject);
    });

    return output;
  }

  createViolationTables() {
    const output = [];
    //console.log(this.selectedVehicle);

    const vehicles = this.selectedVehicle.filter(vh => {
      if (vh.violations.length > 0) {
        return vh;
      }
    });
    vehicles.forEach(vehicle => {

      const tableDescr = [
        {
          text: 'Vehicle: ' + vehicle.dev_name + '   Driver: ' + vehicle.dev_driver_name +
            '     License Plate:  ' + vehicle.dev_license_plate,
          alignment: 'left',
          fontSize: 10,
          margin: [0, 20, 0, 10],
          bold: false,
          color: '#005953'
        }
      ];

      output.push(...tableDescr);

      const table = {
        table: {
          widths: [60, 90, 60, 60, 200],
          headerRows: 1,
          body: [
            [
              {
                text: 'Time',
                style: 'tableHeader'
              },
              {
                text: 'Criteria Name',
                style: 'tableHeader'
              },
              {
                text: 'Criteria Value',
                style: 'tableHeader'
              },
              {
                text: 'Actual Value',
                style: 'tableHeader'
              },
              {
                text: 'Location',
                style: 'tableHeader'
              }
            ],

            ...this.getTableData(vehicle)
          ],
        },
        layout: 'lightHorizontalLines'
      };

      output.push(table);

    });

    return output;
  }

  ngOnDestroy() {
    // Unsubscribe to
    this.onDestroy$.next();
    this.onDestroy$.complete();

    //console.log('Destroy');
  }
}
