import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import * as L from 'leaflet';
import 'leaflet-draw';
import { DataService } from '../services/data.service';
import { Subscription } from 'rxjs';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbModal, NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-geofences',
  templateUrl: './geofences.component.html',
  styleUrls: ['./geofences.component.scss']
})
export class GeofencesComponent implements OnInit, OnDestroy, AfterViewInit {
  private map: L.map;
  private editableLayers: L.featureGroup = new L.FeatureGroup();
  private drawControl: L.Control;

  public geofences;
  public pois = [];
  public selectedPoi: { poi_name: any; poi_latitude: any; poi_longitude: any; };
  public selectedGeofence;
  public filterPoi;
  public filterGeofence;

  // create a custom icon for the POI
  public customMarker = L.Icon.extend({
    options: {
      shadowUrl: null,
      iconAnchor: new L.Point(12, 12),
      iconSize: new L.Point(24, 24),
      iconUrl: '/assets/poi.png'
    }
  });

  public options = {
    position: 'topright',
    draw: {
      polyline: false,
      polygon: {
        allowIntersection: false, // Restricts shapes to simple polygons
        drawError: {
          color: '#e1e100', // Color the shape will turn when intersects
          message: '<strong>Oh snap!<strong> you can\'t draw that!' // Message that will show when intersect
        },
        shapeOptions: {
          color: '#bada55'
        }
      },
      circle: false, // Turns off this drawing tool
      rectangle: false,
      marker: {
        icon: new this.customMarker()
      },
      circlemarker: false
    },
    edit: {
      featureGroup: this.editableLayers, // REQUIRED!!
      remove: false
    }
  };

  public editOptions = {
    position: 'topright',
    draw: {
      polygon: false,
      polyline: false,
      marker: false,
      rectangle: false,
      circle: false,
      circlemarker: false
    },
    edit: {
      featureGroup: this.editableLayers, // REQUIRED!!
      remove: false
    }
  };

  public drawOption = {
    polygon: false,
    polyline: false,
    marker: false,
    rectangle: false,
    circle: false,
    circlemarker: false
  };


  // form display properties
  public isFormVisible = false;

  // Spinner status
  public spinnerStatus = 'Loading ....';
  public action = '';

  // create mode
  public createMode = false;
  public userAction: string;

  // poi form
  public poiForm = new FormGroup({
    poiName: new FormControl('', [
      Validators.required,
      this.uniquePoiNameValidator.bind(this)
    ]),
    lat: new FormControl({ value: '', disabled: false }, Validators.required),
    lng: new FormControl({ value: '', disabled: false }, Validators.required)
  });

  // geofence form
  public geofenceForm = new FormGroup({
    geofenceName: new FormControl('', [
      Validators.required]
    ),
    color: new FormControl('', Validators.required),
    type: new FormControl('', Validators.required),
    sides: new FormControl(),
    radius: new FormControl(),
    center: new FormControl()
  });

  // Tab control
  active = 1;
  poiDisabled;
  geofenceDisabled;

  // Customer ID details
  private cus_id;

  private subscription: Subscription[] = [];
  constructor(
    private dataService: DataService,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal
  ) {

  }

  ngOnInit() {
    this.spinner.show();
    // Get the customer ID from the local storage
    this.cus_id = localStorage.getItem('cusId');

    // Initialize the map object
    this.initMap();

    // Subscribe to the service to get Poi of interest data
    this.getPoi();

    // Get the geofences
    this.getGeofence();

    // call drawing manager method to handle the drawing
    this.drawingManager();

    // listen to color input change
    this.geofenceForm.get('color').valueChanges.subscribe(val => {
      //console.log(val);
      this.editableLayers.eachLayer(layer => {
        if (layer) {
          layer.setStyle({ fillColor: `${val}` });
          //console.log(layer);
        }
      })
    });

    // Filter points of interest by name on type
    this.poiForm.get('poiName').valueChanges.subscribe(term => {
      this.pois = this.filterPoi.filter(poi => poi.poi_name.includes(term));
    });

    // Filter geofence by name on type
    this.geofenceForm.get('geofenceName').valueChanges.subscribe(term => {
      this.geofences = this.filterGeofence.filter(geofence => geofence.geofence_name.includes(term));
    });

  }

  ngAfterViewInit() {

  }

  public initMap(): void {
    // Check if the map container is initialized
    if (this.map) {
      // if true remove the container from map
      this.map.remove();
    }

    // Initialize the map object:set the center, the zoom level
    this.map = L.map('edit-map', {
      center: [0, 7],
      zoom: 12
    });

    // add google mapstitle layer
    const googleStreets = L.tileLayer(
      'http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',
      {
        maxZoom: 22,
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
      }
    );

    this.map.addLayer(googleStreets);

    // add mouseposition layer control
    L.control.mousePosition().addTo(this.map);

    // hide spinner
    this.spinner.hide();
  }

  public drawingManager(): void {
    // A feature group instace to handle drawn layer
    // this.editableLayers = new L.FeatureGroup();

    // add the feature group to the map
    this.map.addLayer(this.editableLayers);

    // Local variable to work with the events or use asymetrik
    this.drawControl = new L.Control.Draw(this.options);

    // add the control to the map
    this.map.addControl(this.drawControl);

    // listen to the draw events
    this.map.on(L.Draw.Event.CREATED, e => this.layerCreated(e));

    // listen to edit move event
    this.map.on(L.Draw.Event.EDITMOVE, e => this.layerMove(e));
  }

  // handle edits
  layerCreated(e): void {
    // Get layer type and the created layer
    const type = e.layerType;
    const layer = e.layer;

    if (type === 'marker') {// check if it a new layer
      // Update form values: latitude and longitude
      //console.log(this.pois);
      this.poiForm.patchValue({ lat: layer.getLatLng().lat });
      this.poiForm.patchValue({ lng: layer.getLatLng().lng });

      //
      this.geofenceDisabled = true;
    } else {
      this.poiDisabled = true;
    }

    // Add the drawn layer to edit featuregroup
    this.editableLayers.addLayer(layer);
  }

  /*
    Upate the form values on edit
    listen to edit events on markers and regular polygons
  */
  layerMove(e): void {
    //console.log(e.layer);

    const layer = e.layer;

    // Check if selectedPoi is defined
    if (this.selectedPoi) {

      // Update the poiForm value with latitude and longitudes
      this.poiForm.patchValue({ lat: layer.getLatLng().lat });
      this.poiForm.patchValue({ lng: layer.getLatLng().lng });

    } else {
      // Update poiform value with latitude and longitudes
      this.poiForm.patchValue({ lat: layer.getLatLng().lat });
      this.poiForm.patchValue({ lng: layer.getLatLng().lng });
    }

  }

  // Cancel an action: Update, Create or Delete
  cancelAction(name: string): void {
    // Update createMode and action
    this.createMode = true;
    this.action = `Canceled ${name}`;
  }

  // Reset edit session
  resetEdit(name: string, content): void {
    // Update the action value
    this.action = 'Reset ';

    // Update spinner status text
    this.spinnerStatus = `${this.action} Form Values in edit session`;

    // Open the model
    this.modalService.open(content, { centered: true }).result.then(result => {
      if (result == 'Cancel') {
        // Update createMode Status
        this.createMode = true;
      } else {
        // Update createMode Status
        this.createMode = false;
        // this.map.removeControl(this.drawControl);
        this.map.removeControl(this.drawControl);

        this.editOptions.draw.polygon = true;
        this.editOptions.draw.marker = true;

        this.drawControl = new L.Control.Draw(this.editOptions);
        this.map.addControl(this.drawControl);

        /*
          check the entry in edition session
          reset the form control values to empty
          update the respoective values on selectedPoi and selectedGeofence
        */
        if (name === 'poi') {
          if (this.selectedPoi) {
            this.pois.push(this.selectedPoi);
          }

          this.selectedPoi = undefined;
          // empty the form
          this.poiForm.reset({
            poiName: '',
            lat: '',
            lng: ''
          });

          // update poi tab disabled attribute
          this.geofenceDisabled = false;

        } else {
          this.selectedGeofence = undefined;
          // reset the geofence form
          this.geofenceForm.reset({
            geofenceName: '',
            color: '',
            type: ''
          });

          // update poi tab disabled attribute
          this.poiDisabled = false;
        }

        // clear layers from editableLayers feature group
        this.editableLayers.clearLayers();
      }
    });


  }

  // get poi
  getPoi(): void {
    // get pois from data service and update pois value
    this.subscription.push(
      this.dataService.getPoi({ cus_id: this.cus_id }).subscribe(response => {
        this.pois = response;
        this.filterPoi = response;

        // zoom to pois
        if (this.pois[0]) {
          //console.log('Poi loaded');
          this.map.setView([this.pois[0].poi_latitude, this.pois[0].poi_longitude], 10);
        }

      })
    );
  }

  // get Geofence
  getGeofence(): void {
    // get pois from data service and update geofence value
    this.subscription.push(
      this.dataService.getGeofence({ cus_id: this.cus_id }).subscribe(response => {
        this.geofences = response;
        this.filterGeofence = response;
      })
    );
  }
  // listen to click event
  addPoiToMap(name: string): void {
    // disable the tab control on edit mode
    this.poiDisabled = false;
    this.geofenceDisabled = true;

    //  get the clicked POi
    this.selectedPoi = this.pois.find(poi => {
      if (poi.poi_name === name) {
        return poi;
      }
    });

    // L.Draw.Marker.disable();
    this.map.removeControl(this.drawControl);

    this.editOptions.draw.polygon = false;
    this.editOptions.draw.marker = false;

    this.drawControl = new L.Control.Draw(this.editOptions);
    this.map.addControl(this.drawControl);

    // this.drawControl.setDrawingOptions(this.drawOption);


    //console.log(this.drawControl);

    // filter points on poi selection for update
    this.pois = this.pois.filter(poi => {
      if (this.selectedPoi.poi_name === poi.poi_name) {
        return;
      }
      return poi;
    });

    //(this.selectedPoi);

    // Create a Marker Instance
    const marker = L.marker([this.selectedPoi.poi_latitude, this.selectedPoi.poi_longitude], {
      icon: L.icon({
        iconUrl: '/assets/poi.png',
        iconSize: [25, 30],
        popupAnchor: [-3, -3]
      }),
      clickable: true,
      draggable: true
    });

    // clear existing layers from editablelayer featugroup
    // and add a marker
    this.editableLayers.clearLayers();
    this.editableLayers.addLayer(marker);

    // Zoom to marker location
    this.map.flyTo(marker.getLatLng(), 15);

    // update form group data
    this.poiForm.setValue({
      poiName: name,
      lat: this.selectedPoi.poi_latitude,
      lng: this.selectedPoi.poi_longitude
    });

    // Variable handling transition form effects
    this.isFormVisible = true;
    this.createMode = true;
  }

  // Check if the form has validation errors
  isPoiFormValid(): boolean {
    // get the form data
    // Form validation erros
    const keys = ['notUnique', 'required'];

    // an array of form control
    const controls = Object.keys(this.poiForm.controls);
    let error = true;

    // iterate through the errors
    for (const key of keys) {

      for (const control of controls) {
        // if there is an erro return true
        if (this.poiForm.getError(key, control)) {
          error = true;
          return error;

        } else {
          // else error if false
          error = false;
        }
      }
    }

    return error;
  }
  //
  savePoi(content): void {
    // update spinner status text
    this.spinnerStatus = `Save the created POI: ${this.poiForm.get('poiName').value}`;

    // update action value
    this.action = this.selectedPoi ? 'update' : 'create';

    // open alert if there are form errors:
    // prompt the user to meet all the requirement
    if (this.isPoiFormValid()) {
      alert('Fill all the required fields');
    } else {
      // Open the modal to confirm on user actions
      this.modalService.open(content, { centered: true }).result.then(result => {
        //console.log(result);
        /*
          if the user click on cancel button: cancel the action
          else send the data to the server
        */
        if (result == 'Cancel') {
          this.cancelAction('poi');
        } else {
          if (this.selectedPoi) { // if it is a selects Poi update
            this.updatePoi();
          } else {
            //console.log('Creating Poi');
            this.createPoiEntry(); //  if it is new call create
          }
        }
      });
    }
    // Check if the Poi exist

  }

  // Create new Point of interest
  createPoiEntry(): void {
    // get a json representation of the data
    const formData = this.poiForm.getRawValue();


    // Create data object to be sent to the server
    const data = {
      cus_id: this.cus_id,
      poi_latitude: formData.lat,
      poi_longitude: formData.lng,
      poi_name: formData.poiName
    };

    //console.log(this.isPoiFormValid());

    // Update the spinner text
    this.spinnerStatus = 'Creating POI ...';
    this.spinner.show();

    // send the poi data to the database
    this.dataService.createPoi(data).subscribe(response => {

      // prompt user to retry submitting the data incase of an error
      if (response.error) {
        alert('Retry Sending the data Again');
      } else {

        // Reset the form values if a successfull post request
        this.poiForm.reset({
          poiName: '',
          lat: '',
          lng: ''
        });

        // Update the poi list
        this.getPoi();

        // clear layers from the layergroup
        this.editableLayers.clearLayers();

        // enable tab navigation
        this.geofenceDisabled = false;
        this.createMode = false;
      }

      // hide the spinner
      this.spinner.hide();
    });


  }

  // Save the poi edits
  updatePoi(): void {
    // get a json representation of the data
    const poiForm = this.poiForm.getRawValue();

    // Create a data object to send to the database
    const data = {
      cus_id: this.cus_id,
      poi_latitude: poiForm.lat,
      poi_longitude: poiForm.lng,
      poi_name: poiForm.poiName,
      poi_old_name: this.selectedPoi.poi_name
    };

    // Update the spinner status and display the spinner
    this.spinnerStatus = 'Updating POI ...';
    this.spinner.show();

    // subscribe to the data service
    this.dataService.updatePoi(data).subscribe(response => {
      // Check on errors
      if (response.error) {
        alert('Retry Sending the data Again');
      } else {
        // Reset the form values if a successfull post request
        this.poiForm.reset({
          poiName: '',
          lat: '',
          lng: ''
        });
        // Update the list
        this.getPoi();
        this.editableLayers.clearLayers();

        // enable tab navigations
        this.geofenceDisabled = false;
        this.createMode = false;
      }
      this.spinner.hide();

    });


  }

  // Delete the selectes Poi
  deletePoi(content): void {
    // Check if the selected is empty
    if (!this.selectedPoi) {
      alert('Select Poi layer');
    } else {
      this.action = 'Delete';
      this.spinnerStatus = `Delete Poi: ${this.poiForm.get('poiName').value}`;

      this.modalService.open(content, { centered: true }).result.then(result => {
        //console.log(result);
        if (result == 'Delete') {
          const data = {
            cus_id: this.cus_id,
            poi_name: this.selectedPoi.poi_name
          };

          // Update the spinner status and display the spinner
          this.spinnerStatus = 'Deleting POI ...';
          this.spinner.show();

          // subscribe to data service
          this.dataService.deletePoi(data).subscribe(response => {
            // prompt the user to resubmit the data in case of error
            if (response.error) {
              //console.log(response);

              alert('Resend the data Again');
            } else {
              // if successessful request: clear data from editable laye
              this.editableLayers.clearLayers();

              // reset form values
              this.poiForm.reset({
                poiName: '',
                lat: '',
                lng: ''
              });

              // Update the poi
              this.getPoi();

              // enable tab navigation
              this.geofenceDisabled = false;
            }

            this.spinner.hide();
          });
        } else {
          // cancel delete action
          this.cancelAction('poi');
        }

      });


    }

  }
  // Validate POi name from existing POI
  uniquePoiNameValidator(control: FormControl): any {
    // check if the POi names are unique;
    if (control.value) {
      const poi = this.pois.find(poiInner => poiInner.poi_name == control.value);

      if (poi) {
        return { notUnique: true };
      }
      return null;

    }

  }

  // Add the geofence to list click
  addGeofenceToMap(name: string): void {
    // allow edit mode only
    this.map.removeControl(this.drawControl);

    this.editOptions.draw.polygon = false;
    this.editOptions.draw.marker = false;

    this.drawControl = new L.Control.Draw(this.editOptions);
    this.map.addControl(this.drawControl);

    // disable the tab control on edit mode
    this.poiDisabled = true;
    this.geofenceDisabled = false;

    // empty the editable layer
    this.editableLayers.clearLayers();

    // get the clicked layer
    this.selectedGeofence = this.geofences.find(gf => gf.geofence_name == name);
    //console.log(this.selectedGeofence);

    // Get an array of coordinates from {x:0,y:0} object
    const coordinates = this.xyObjectToArray(this.selectedGeofence.polygon_geometry);

    // Create polygon layer
    const polygon = L.polygon(coordinates, {
      fillColor: this.selectedGeofence.color,
      color: this.selectedGeofence.color
    });

    // Add the polygon to the layer edits
    this.editableLayers.addLayer(polygon);

    // populate the form with geofence values
    this.geofenceForm.setValue({
      geofenceName: this.selectedGeofence.geofence_name,
      type: this.selectedGeofence.geofence_shape,
      color: this.selectedGeofence.color,
      sides: '',
      radius: '',
      center: ''
    });

    // if regular polygon update the radius, center and sides
    if (this.selectedGeofence.geofence_shape == 'Regular') {
      this.geofenceForm.setValue({
        sides: '',
        radius: '',
        center: ''
      });
    }

    // fit map bounds to layer bounds
    this.map.fitBounds(this.editableLayers.getBounds());

    // update create mode
    this.createMode = true;
  }

  /*
    convert object to array of coordinates
    from {x:0,y:0}
    to [2,3]
  */
  xyObjectToArray(geometry): any[] {
    // Iterate the array and convert the object to an array of array
    const xyArray = geometry.map(coordinates => {
      return coordinates.map(coord => Object.values(coord).reverse());
    });


    return xyArray;
  }

  /* Convert polygon to WKT
    from [[0,0],[0,1]]
    to POLYGON (( 0 0, 0 1))
  */
  arrayToWkt(coord: any[]): any {
    //console.log(coord[0][0]);

    const wkt = coord.map(cds => {
      return cds.map(cd => {
        return cd.lng + ' ' + cd.lat;
      });
    });

    // add the first coordinate to the list
    wkt.push(coord[0][0].lng + ' ' + coord[0][0].lat);

    //console.log('POLYGON ((' + wkt + '))');
    return 'POLYGON ((' + wkt + '))';
  }


  saveGeofence(content): void {
    // Check if there are form errors
    if (this.geofenceForm.valid) {
      // update spinner and action status
      this.action = this.selectedGeofence ? 'update' : 'create';
      this.spinnerStatus = `${this.action} geofence: ${this.geofenceForm.get('geofenceName').value}`;

      // open the modal
      this.modalService.open(content, { centered: true }).result.then(result => {
        if (result === 'Cancel') {
          // handle cancel action
          this.cancelAction('geofence');
        } else {
          // update or create geofence
          this.selectedGeofence ? this.updateGeofence(this.selectedGeofence.geofence_id) : this.createPolygonGeofence();
        }
      });

    } else {
      alert('Fill all the required field');
    }

  }

  createPolygonGeofence(): void {
    // get the the layer
    const layer = this.editableLayers.getLayers()[0];
    //console.log(layer.getLatLngs());
    const fdata = this.geofenceForm.getRawValue();

    // format the data to be sent to the serve
    const data = {
      cus_id: this.cus_id,
      geofence_name: fdata.geofenceName,
      geofence_color: fdata.color,
      polygon_geometry: this.arrayToWkt(layer.getLatLngs())
    };

    //console.log(data);
    // Update spinner and status
    this.spinnerStatus = 'Saving Geofence ...';
    this.spinner.show();

    // Send the data to th database
    this.dataService.createGeofence(data).subscribe(response => {
      if (response.error) {
        // Prompt the user to retry
        alert('Resend the data Again');

      } else {
        /*
          Succes response, reset the
          editable layers and and form values
        */
        //console.log('success');
        this.editableLayers.clearLayers();

        this.geofenceForm.reset({
          geofenceName: '',
          type: '',
          color: ''
        });

        // enable table navigation
        this.poiDisabled = false;
        this.createMode = false;
        this.getGeofence();
      }

      this.spinner.hide();
    });
  }

  deleteGeofence(content): void {
    // Check if a geofence is selected
    if (!this.selectedGeofence) {
      alert('Select a geofence from the list');
    } else {
      // update action and status
      this.action = 'Delete';
      this.spinnerStatus = `${this.action} geofence: ${this.selectedGeofence.geofence_name}`;

      // Open the modal
      this.modalService.open(content, { centered: true }).result.then(result => {
        // cancel edit
        if (result === 'Cancel') {
          this.cancelAction('geofence');

        } else {
          // use the name or the id
          const data = {
            geofence_id: this.selectedGeofence.geofence_id
          };

          // Update the spinner status
          this.spinnerStatus = 'Deleting Geofence ...';
          this.spinner.show();

          // delete the selected geofence
          this.dataService.deleteGeofence(data).subscribe(response => {
            if (response.error) {
              //console.log(response);
              // Prompt the user to retry
              alert('Resend the data Again');
            } else {
              // reset form values and clear layers from the layergroup
              this.editableLayers.clearLayers();
              this.geofenceForm.reset({
                geofenceName: '',
                type: '',
                color: ''
              });

              // enable tab navigation
              this.poiDisabled = false;
              this.createMode = false;

              // update geofence
              this.getGeofence();
            }

            this.spinner.hide();
          });
        }

      });
    }
  }

  updateGeofence(id: number) {
    // get the wkt
    const layer = this.editableLayers.getLayers()[0];
    const formData = this.geofenceForm.getRawValue();

    /*
    check if there are form errors:
    if error alert the user to fill in the values
    form data

    */
    const data = {
      geofence_id: id,
      geofence_name: formData.geofenceName,
      geofence_color: formData.color,
      geofence_shape: formData.type,
      polygon_geometry: this.arrayToWkt(layer.getLatLngs())
    };

    //console.log(data);
    this.spinnerStatus = 'Updating Geofence ...';
    this.spinner.show();

    //  Subscribe to update service
    this.dataService.updateGeofence(data).subscribe(response => {

      if (response.error) {
        //(response);
        // Prompt the user to retry
        alert('Resend the data Again');
      } else {
        // reset the form and layer group
        this.editableLayers.clearLayers();

        this.geofenceForm.reset({
          geofenceName: '',
          type: '',
          color: ''
        });

        // update geofences
        this.getGeofence();

        // enable tab navigation
        this.poiDisabled = false;
        this.createMode = false;
      }

      this.spinner.hide();

    });

  }

  onNavChange(changeEvent: NgbNavChangeEvent) {
    if (changeEvent.activeId === 2) {
      this.map.removeControl(this.drawControl);

      // create a leaf control with marker only
      this.editOptions.draw.marker = true;
      this.editOptions.draw.polygon = false;


      this.drawControl = new L.Control.Draw(this.editOptions);

      this.map.addControl(this.drawControl);
    } else {
      this.map.removeControl(this.drawControl);

      // create a leaf control with marker only
      this.editOptions.draw.marker = false;
      this.editOptions.draw.polygon = true;


      this.drawControl = new L.Control.Draw(this.editOptions);

      this.map.addControl(this.drawControl)
    }

  }

  ngOnDestroy() {
    // destroy the subscription after component is destroyed
    this.subscription.forEach(subscr => {
      subscr.unsubscribe();
    });
  }


}
