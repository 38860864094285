import { Injectable, Injector } from '@angular/core';
import { AuthService } from './auth.service';
import {
  HttpEvent, HttpRequest, HttpHandler,
  HttpInterceptor, HttpErrorResponse
} from '@angular/common/http';
import { retry, catchError } from 'rxjs/operators'
import { throwError, Observable } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private injector: Injector, public myAuthor: AuthService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let authService = this.injector.get(AuthService);

    let tokenizedReq = req.clone({
      headers: req.headers.set(
        'Authorization',
        'Bearer ' + authService.getJwtToken()
      )
    });

    // console.log(req);
    //
    return next.handle(tokenizedReq).pipe(
      retry(2),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {

        } else {
          return throwError(error);
        }
      })
    );
  }
}
