<app-navbar></app-navbar>
<!-- status that fades in and out -->
<div class="container-fluid" id="report" #report>
    <!-- Page heder -->
    <div class="row text-center" id="t-header">
        <div class="col-md-3">
            <img src="../../assets/logo.png" alt="" id="logo">
        </div>

        <div class="col-md-6 text-center">
            <div class="mt-2">
                <h6>{{ selectedVehicle?.dev_name }} Trip/History Report</h6><br>
                Date: <strong>{{ model?.year }}-{{ model?.month }}-{{ model?.day}}</strong>
                Start Time: <strong>{{ from.hour }}:{{from.minute}}</strong>
                End Time: <strong>{{to?.hour}}:{{to?.minute}}</strong> <br>
                <small class=""> Total Distance: {{totalDistance| number }} Kilometres </small>
                <small> Average Speed: {{ averageSpeed| number }} km/hr </small><br>
                <p class="bold">
                    Vehicle Name &nbsp;&nbsp;
                    Driver Name &nbsp;&nbsp;
                    Driver Mobile &nbsp;&nbsp;
                    License Plate <br>
                    {{ selectedVehicle?.dev_name }} &nbsp;&nbsp;&nbsp;
                    {{ selectedVehicle?.dev_driver_name }} &nbsp;&nbsp;&nbsp;
                    {{ selectedVehicle?.dev_driver_mobile }} &nbsp;&nbsp;&nbsp;
                    {{ selectedVehicle?.dev_license_plate }}&nbsp;&nbsp;&nbsp;
                </p>
                <!-- <tbody class="c"> -->

                <!-- </tbody> -->
            </div>

        </div>
        <div class="col-md-3 text-left mt-2">
            <div>
                <p>
                <p>
                    {{ customerName }} <br>
                    Username: {{username}} <br>
                </p>
            </div>
        </div>


        <!-- Vehicle Information -->
    </div>

    <div class="row t-2 text-center noprint py-2">
        <form class="form-inline">
            <div class="input-group ml-2  input-group-sm">
                <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1">Vehicle</span>
                </div>
                <input id="typeahead-basic" type="text" [formControl]="vehicleName" class="form-control"
                    [(ngModel)]="models" [ngbTypeahead]="search" />
            </div>
            <!-- </div> -->

            <div class="input-group ml-2  input-group-sm">
                <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1">Date</span>
                </div>
                <input class="form-control" placeholder="dd-mm-yyyy" name="dp" [(ngModel)]="model" ngbDatepicker
                    #d="ngbDatepicker">
                <div class="input-group-append">
                    <button class="btn calendar" (click)="d.toggle()" type="button">
                        <i class="fa fa-calendar" aria-hidden="true"></i>
                    </button>
                </div>
            </div>

            <div class="input-group ml-2  input-group-sm">
                <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1">From</span>
                </div>
                <input type="time" class="form-control form-control-sm " value="00:00:00" [formControl]="fromTime"
                    required>
            </div>

            <div class="input-group ml-2  input-group-sm">
                <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1">To</span>
                </div>
                <input type="time" class="form-control form-control-sm " value="23:59:59" [formControl]="toTime"
                    required>
            </div>

            <input type="button" value="load" class="btn btn-sm mx-2 " (click)="getReport()" id="load">
        </form>

        <!-- Filter form -->
        <!-- <div class="d-flex mt-2 ml-2">
         <p class="mt-2 mr-1">From</p>
        <ngb-timepicker [(ngModel)]="from" [spinners]="false"></ngb-timepicker>

        <p class="mt-2 ml-1">To</p>
        <ngb-timepicker [(ngModel)]="to" [spinners]="false"></ngb-timepicker>
       
     </div> -->

        <!-- Controls -->
        <!-- Display and hide on report generation -->
        <div class="control">
            <div class="btn-group btn-group-sm" id="control-btn" *ngIf="ispdfReady">
                <button class="btn btn-sm" (click)="first()">
                    <img src="../../assets/first.png" alt="" height="15" width="14">
                </button>
                <button class="btn btn-sm" (click)="backward()">
                    <img src="../assets/rewind.png" alt="" height="15" width="14">
                </button>
                <button class="btn btn-sm" (click)="stop()">
                    <img src="../../assets/stop.png" alt="" height="15" width="14">
                </button>
                <button class="btn btn-sm" (click)="play()">
                    <img src="../../assets/playy.png" alt="" height="15" width="14">
                </button>
                <button class="btn btn-sm" (click)="forward()">
                    <img src="../../assets/forward.png" alt="" height="15" width="14">
                </button>
                <button class="btn btn-sm" (click)="last()">
                    <img src="../../assets/last.png" alt="" height="15" width="14">
                </button>
                <button class="btn btn-sm btn-outline-info" (click)="animateLine()">
                    Animate
                    <i class="fas fa-charging-station"></i>
                </button>
            </div>

            <div class="btn-group ml-2" id="pdf-btn" *ngIf="ispdfReady">
                <button (click)="generatePDF('print')" class="btn btn-sm">
                    <i class="fa fa-print text-danger " aria-hidden="true"></i>
                    Print
                </button>
                <button (click)="generatePDF('download')" class="btn btn-sm">
                    <i class="fa fa-download text-danger " aria-hidden="true"></i>
                    Pdf
                </button>
                <button (click)="generatePDF('open')" class="btn btn-sm">
                    <i class="fa fa-eye text-danger" aria-hidden="true"></i>
                    Pdf
                </button>
            </div>
        </div>
    </div>
    <div class="container-fluid row">
        <div class="col-md-3 pl-2 noprint" id="side-menu">
            <div class="d-flex align-items-center justify-content-between">
                <p class="p">Vehicle Name: {{ vehicleName.value }} </p>
                <p>
                    <small (click)="loadEntireTrip()" class="btn btn-sm btn-action" [hidden]="isEntireTrip">
                        Entire Trip
                    </small>
                </p>

            </div>

            <div class="d-flex" *ngIf="tripGroupsCopy">
                <label for="" class="mt-2">Filter</label>
                <!-- <ngb-timepicker [(ngModel)]="filterDate" [spinners]="false"></ngb-timepicker> -->

                <!-- timepicker -->
                <input type="time" name="time-picker" id="" value="16:32" class="form-control form-control-sm w-50"
                    [formControl]="timeFilter">

                <button class="btn btn-sm btn-success ml-2" id="load" (click)="filterTripByTime()">Filter</button>
            </div>
            <div class="trip-section" [ngClass]="{
            'd-none':filterOpacity == 1,
            'd-block':filterOpacity == 0
        }">

                <ngb-accordion [closeOthers]="true" activeIds="static-1" #acc>
                    <ngb-panel *ngFor="let tgroup of tripGroupsCopy" id="{{tgroup.Name}}">
                        <ng-template ngbPanelHeader>
                            <div class="t-3 ml-2 d-flex justify-content-between">
                                <p>
                                    {{ tgroup.stop.Name }} <br>
                                    From <strong>{{ tgroup.stop.start| date:'H:mm:ss' }}</strong><br>
                                    To: <strong>{{ tgroup.stop.stop| date:'H:mm:ss' }}</strong><br>
                                    Duration <strong>{{ tgroup.stop.duration }}</strong>
                                </p>

                                <i class="fa fa-map-pin btn" aria-hidden="true"
                                    (click)="pinStopToMap(tgroup.stop.Name)"></i>
                            </div>
                            <div class="d-flex align-items-center justify-content-between">
                                <div class="ml-2">
                                    <p class="p bold" (click)="displayTripOnMap(tgroup.Name)">{{ tgroup.Name | titlecase
                                        }}
                                        <tbody>
                                            <tr>
                                                <td>Start {{ tgroup.start | date:'H:mm:ss'}} Stop {{ tgroup.end |
                                                    date:'H:mm:ss'}}</td>
                                                <td>

                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Duration {{ tgroup.duration }}</td>

                                            </tr>
                                            <tr>
                                                <td>Distance {{tgroup.distance| number:'1.1-3' }} Kilometres </td>
                                            </tr>
                                        </tbody>
                                    </p>
                                </div>

                                <button ngbPanelToggle class="btn btn-link p-0" (click)="displayTripOnMap(tgroup.Name)">
                                    <img src="../../assets/down.png" alt="" *ngIf="!acc.isExpanded(tgroup.Name)">
                                    <img src="../../assets/up.png" alt="" *ngIf="acc.isExpanded(tgroup.Name)">
                                </button>
                            </div>
                            <div class="t-3 mt-2 ml-2" *ngIf="tgroup.LastStop">
                                <p>
                                    {{ tgroup.LastStop.Name }} <br>
                                    From <strong>{{ tgroup.LastStop.start| date:'H:mm:ss' }}</strong> <br>
                                    To: <strong>{{ tgroup.LastStop.stop| date:'H:mm:ss' }}</strong> <br>
                                    Duration <strong>{{ tgroup.LastStop.duration }}</strong>
                                </p>
                            </div>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <table class="table">
                                <thead>
                                    <th>Status</th>
                                    <th>Speed</th>
                                    <th>Time</th>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let data of tgroup.location" (click)="addVehicleHistory(data.pos_id)">
                                        <td>
                                            <div class="status" [ngClass]="{
                                                                    'bg-grey':data.status == 'IGN OFF',
                                                                    'bg-success':data.status == 'Normal Speed',
                                                                    'bg-info':data.status == 'STOPPED IGN ON'
                                                            }"></div>
                                            {{ data.status }}
                                        </td>
                                        <td>{{ data.pos_speed }}</td>
                                        <td>{{ data.pos_gps_time| date:'H:mm:ss'}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
            </div>

            <div class="filter-section" [style.opacity]="filterOpacity">
                <table class="table">
                    <thead>
                        <th>Status</th>
                        <th>Speed</th>
                        <th>Time</th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let data of tripFilter" (click)="addVehicleHistory(data.pos_id)">
                            <td>
                                <div class="status" [ngClass]="{
                                    'bg-grey':data.status == 'IGN OFF',
                                    'bg-success':data.status == 'Normal Speed',
                                    'bg-info':data.status == 'STOPPED IGN ON'
                            }"></div>
                                {{ data.status }}
                            </td>
                            <td>{{ data.pos_speed }}</td>
                            <td>{{ data.pos_gps_time| date:'H:mm:ss'}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </div>
        <div class="col-md-9 col-sm-12">
            <ngx-spinner size="medium" type="ball-clip-rotate-multiple" bdColor="#fff" [fullScreen]="false"
                bdOpacity=0.5 color="#333">
                <p class="t-2">{{ spinnerStatus }}</p>
            </ngx-spinner>
            <!-- Map, PDF Report-->
            <div class="row">
                <div id="map-report" #reportMap></div>
            </div>
            <div class="row mt-1 noprint">
                <div class="col-md-2 mt-3">
                    <div class="card mb-1 ml-1 mt-2">
                        <div class="card-body  text-center">
                            <h3 class="bold">{{ averageSpeed| number }} <br> km/hr</h3>
                            <small class="p">Average Speed</small>
                        </div>
                    </div>
                    <div class="card text-center ml-1">
                        <div class="card-body">
                            <h3 class="bold">{{ totalDistance| number }} <br> km</h3>
                            <small class="p">Total Distance</small>
                        </div>
                    </div>
                </div>
                <div class="col-md-10">
                    <!-- Speed charts -->
                    <canvas baseChart width="100%" height="28" [datasets]="lineChartData" [labels]="lineChartLabels"
                        [options]="lineChartOptions" [colors]="lineChartColors" [legend]="lineChartLegend"
                        [chartType]="lineChartType" [plugins]="lineChartPlugins">
                    </canvas>
                </div>
            </div>
        </div>
    </div>
    <div class="container mt-4 d-none">
        <div class="row">
            <h6 class="text-center mb-1">Geocoded Data</h6>
        </div>
        <table class="table">
            <thead>
                <th>Time</th>
                <th>Status</th>
                <th>Speed</th>
                <th>Location</th>
            </thead>
            <tbody>
                <tr *ngFor="let trip of tripReport" [ngClass]="{
                    'text-info':trip.status == 'STOPPED IGN ON'
                }">
                    <td>{{ trip?.pos_gps_time | date:'H:mm:ss'}}</td>
                    <td>{{ trip.status }}</td>
                    <td>{{ trip.pos_speed }} km/hr</td>
                    <td>
                        {{ trip.location | titlecase }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>