import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { AttachSession } from 'protractor/built/driverProviders';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  public username: string;
  public companyName: string;
  public customerId;
  public userRole: string;
  // public userMaster = localStorage.getItem('userIsMaster');

  public isMenuCollapsed = true;

  constructor(private authService: AuthService, private router: Router) {
    this.username = localStorage.getItem('userName');
    this.companyName = localStorage.getItem('cusName');
    this.customerId = localStorage.getItem('cusId');
    this.userRole = localStorage.getItem('roleName')
  }

  ngOnInit(): void { }
  logout() {
    localStorage.clear();
    this.authService.doLogoutUser();
    this.router.navigate(['/']);
  }
}
