<app-navbar></app-navbar>
<div class="container-fluid row">
  <ngx-spinner [fullScreen]="false" type="ball-clip-rotate-multiple" size="medium">
    <p class="loading text-white">{{ spinnerStatus }}</p>
  </ngx-spinner>

  <div class="col-md-2 col-lg-2 col-sm-4" id="edit-tab">
    <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs" (navChange)="onNavChange($event)">
      <li [ngbNavItem]="1" [disabled]="poiDisabled">
        <a ngbNavLink>Poi</a>
        <ng-template ngbNavContent>
          <div class="poi">
            <div class="mb-2 py-2" id="tab">
              <form action="form-horizontal mx-5" id="search">
                <!-- <input type="search" name="" id="" class="form-control form-control-sm" placeholder="Point of Interest"> -->
              </form>
            </div>

            <form action="" class="form-horizontal mx-2 text-white poi-form" [formGroup]="poiForm">
              <div class="input-group input-group-sm">
                <div class="input-group-prepend ">
                  <span class="input-group-text" id="basic-addon1">Name</span>
                </div>
                <input type="text" name="poiName" formControlName="poiName" id="poi_name"
                  class="form-control form-control-sm" placeholder="Poi Name">

              </div>

              <p class="alert alert-danger" *ngIf="poiForm.get('poiName')?.errors?.notUnique">Not Unique</p>
              <p class="alert alert-danger" *ngIf="poiForm.get('poiName')?.errors?.required">Required</p>
              <div class="input-group input-group-sm">
                <div class="input-group-prepend ">
                  <span class="input-group-text" id="basic-addon1">Latitude</span>
                </div>
                <input type="number" name="lat" formControlName="lat" class="form-control form-control-sm"
                  placeholder="Latitude">

              </div>
              <p class="alert alert-danger" *ngIf="poiForm.get('lat')?.errors?.required">Required</p>

              <div class="input-group input-group-sm">
                <div class="input-group-prepend ">
                  <span class="input-group-text" id="basic-addon1">Longitude</span>
                </div>
                <input type="number" name="lng" formControlName="lng" class="form-control form-control-sm"
                  placeholder="Longitude">
              </div>
              <p class="alert alert-danger" *ngIf="poiForm.get('lng')?.errors?.required">Required</p>

              <!-- Save RESET AND DELETE BUTTONS -->
              <div class="input-group mt-2">
                <input type="submit" value="Save" class="btn btn-sm btn-save mr-2" (click)="savePoi(content)">
                <input *ngIf="selectedPoi" type="button" value="Delete" class="btn btn-sm btn-danger mr-2"
                  (click)="deletePoi(content)">
                <input *ngIf="this.poiForm.get('poiName').value" type="button" value="Reset"
                  class="btn btn-sm btn-reset" (click)="resetEdit('poi', content)">
              </div>

            </form>

            <!-- Poi list -->
            <div class="poi-list mt-2">
              <ul class="list-group" [ngClass]="{
                                    'create': createMode == true 
                                  }">
                <li class="list-group-item d-flex align-items-center justify-content-between" *ngFor="let poi of pois"
                  (click)="addPoiToMap(poi.poi_name)">
                  <p class="ml-2">{{ poi.poi_name }}</p>
                  <img src="/assets/poi.png" alt="poi" height="20px" width="20px">
                </li>
              </ul>
            </div>

          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="2" [disabled]="geofenceDisabled">
        <a ngbNavLink>Geofence</a>
        <ng-template ngbNavContent>
          <!-- Geometry Form -->
          <div class="mb-2 py-2" id="tab">

          </div>

          <div class="geofence">
            <form action="" [formGroup]="geofenceForm" class="form-horizontal text-white  mx-2" id="geofence">
              <div class="input-group input-group-sm">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon1">Name</span>
                </div>
                <input type="text" name="geofenceName" id="" formControlName="geofenceName" class="form-control"
                  placeholder="Name">
              </div>
              <p class="alert alert-danger" *ngIf="geofenceForm.get('geofenceName')?.errors?.required">Required</p>

              <div class="input-group input-group-sm">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon1">Color</span>
                </div>
                <input type="color" name="color" formControlName="color" id="" class="form-control">
              </div>
              <p class="alert alert-danger" *ngIf="geofenceForm.get('color')?.errors?.required">Required</p>

              <div class="input-group input-group-sm">
                <!-- <label for="type mr-2">Type</label> -->
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon1">Type</span>
                </div>
                <select name="type" formControlName="type" id="" class="form-control">
                  <!-- <option value="regular">regular</option> -->
                  <option value="polygon" selected>polygon</option>
                </select>
              </div>
              <p class="alert alert-danger" *ngIf="geofenceForm.get('type')?.errors?.required">Required</p>

              <!-- Case  -->
              <div class="input-group input-group-sm" hidden>
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon1">Sides</span>
                </div>
                <input type="number" name="sides" formControlName="sides" class="form-control">
              </div>
              <div class="input-group input-group-sm" hidden>
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon1">Radius</span>
                </div>
                <input type="number" name="radius" formControlName="radius" class="form-control">
              </div>
              <div class="input-group input-group-sm" hidden>
                <!-- Alert the user to click on the map  -->
                <div class="input-group-prepend">
                  <span class="input-group-text" id="basic-addon1">Center</span>
                </div>
                <input type="text" name="center" id="" formControlName="center" class="form-control">
              </div>

              <div class="input-group mt-2">
                <input type="submit" value="Save" class="btn btn-sm btn-save mr-2" (click)="saveGeofence(content)">
                <input *ngIf="selectedGeofence" type="button" value="Delete" class="btn btn-sm btn-danger mr-2"
                  (click)="deleteGeofence(content)">
                <input *ngIf="this.geofenceForm.get('geofenceName').value" type="button" value="Reset"
                  class="btn btn-sm btn-reset" (click)="resetEdit('geofence',content)">
              </div>
            </form>
            <!-- list of existing Poi -->
            <ul class="list-group mt-2 geofence-list" [ngClass]="{
                                  'create': createMode == true 
                                }">
              <li class="list-group-item d-flex align-items-center justify-content-between" *ngFor="let gf of geofences"
                (click)="addGeofenceToMap(gf.geofence_name)">
                <p>{{ gf.geofence_name }}</p>
                <img src="/assets/polygon.png" alt="polygon">
              </li>
            </ul>
          </div>
        </ng-template>
      </li>
    </ul>

    <!-- Tabsets route outlet -->
    <div [ngbNavOutlet]="nav" class="mt-2"></div>
  </div>
  <div class="col-lg-10 col-md-10 col-sm-10">
    <div id="edit-map"></div>
  </div>
</div>

<!-- Modal to listen to user -->
<ng-template #content let-modal>
  <div class="modal-body text-center text-white">
    <p>{{ spinnerStatus }}</p>
  </div>
  <div class="btn-group my-1">
    <button type="button" class="btn btn-sm mx-2" [ngClass]="{
      'btn-save':this.action != 'Delete',
      'btn-danger':this.action == 'Delete'
    }" (click)="modal.close(action)">{{action}}</button>
    <button type="button" class="btn btn-reset btn-sm mx-2" (click)="modal.close('Cancel')">Cancel</button>
  </div>
</ng-template>