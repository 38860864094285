import { Component, OnInit } from '@angular/core';
import { Vehicle } from '../models/vehicle';
import { RouteInfo } from '../models/route-info';

import { AuthService } from 'src/app/services/auth.service';
import { DataService } from '../services/data.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserServiceService } from '../services/user-service.service';

import { FormControl, FormGroup, Validators } from '@angular/forms';

import { takeUntil } from 'rxjs/operators';
import { Subject, throwError } from 'rxjs';

import * as L from 'leaflet';
import 'leaflet-routing-machine';
import 'lrm-mapzen';

import { RouteService } from '../services/route.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { create } from 'domain';

const getCustomMarkers = (i, wp, n) => {
  var title;
  var iconUrl;

  if (i === 0) {
    iconUrl = 'assets/black-marker.png';
    title = 'Start';
  } else if (i + 1 !== n) {
    iconUrl = 'assets/marker-puple.png'
    title = 'inbetween';
  } else {
    iconUrl = 'assets/red-marker.png';
    title = 'End';
  }

  // in betweeens
  const icon = new L.Icon({
    iconUrl,
    iconSize: [25, 25]
  });

  const marker = L.marker(wp.latLng, {
    icon,
    draggable: true,
    title
  });

  return marker;
};

@Component({
  selector: 'app-route-management',
  templateUrl: './route-management.component.html',
  styleUrls: ['./route-management.component.scss']
})
export class RouteManagementComponent implements OnInit {

  routeForm = new FormGroup({
    routeName: new FormControl('', Validators.required),
    distance: new FormControl('', Validators.required),
    toAddress: new FormControl('', Validators.required),
    fromAddress: new FormControl('', Validators.required)
  });

  coordinates: any[];
  routeError: string;

  availableDevices: Vehicle[];
  filterVehicles: Vehicle[];

  selectedVehicle: Vehicle;
  routeVehicles: Vehicle[] = [];

  // Multiselect
  vehicleList = [];
  selectedItems = [];
  dropdownSettings: IDropdownSettings = {};

  vehicleSearch = new FormControl('');

  vehicleRoute: RouteInfo[];
  selectedRoute: RouteInfo;


  routes: RouteInfo[];
  routeGeojson: any;
  route: L.geoJson;
  routesFilter: RouteInfo[];

  spinnerStatus = 'Update';
  action = 'Create';
  buttonTitle = 'Create';

  customerName = localStorage.getItem('cusName');
  customerID = localStorage.getItem('cusId');
  userName = localStorage.getItem('userName');
  userMapType = localStorage.getItem('userMapType');
  userMaster = localStorage.getItem('userIsMaster');
  userID = localStorage.getItem('userId');

  onDestroy$ = new Subject();

  map: L.map;
  routingControl: L.Routing.control;

  constructor(
    public authService: AuthService,
    public _dataService: DataService,
    public spinner: NgxSpinnerService,
    private modalService: NgbModal,
    private _routeService: RouteService
  ) {

  }

  ngOnInit(): void {
    this.spinner.show();
    this.spinnerStatus = 'Fetching Data ....';

    // get all Devices
    this._dataService.getVehicle({
      userId: this.userID,
      cus_id: this.customerID,
      userIsMaster: this.userMaster,
    })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(response => {

        this.availableDevices = response;
        this.filterVehicles = response;

        this.vehicleList = response;

        // Set map view to
        if (this.availableDevices[0]) {
          this.map.setView([this.availableDevices[0].dev_latitude, this.availableDevices[0].dev_longitude], 10)
        }

      });

    this.spinner.hide();

    this.vehicleSearch.valueChanges.subscribe(term => {
      this.filterVehicles = this.availableDevices.filter(vh => vh.dev_name.includes(term))
    });

    // create mao
    this.createMap();
    this.addRouteControl();
    this.getRoutes();

    // Multi-select
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'dev_id',
      textField: 'dev_name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }

  // Create map element
  createMap() {

    // create map
    this.map = L.map('map', {
      center: [-1.29627, 36.85328],
      zoom: 6
    });

    // the basemap
    // add google mapstitle layer
    const googleStreets = L.tileLayer(
      'http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',
      {
        maxZoom: 22,
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
      }
    );

    this.map.addLayer(googleStreets);

    // handle map click
    this.map.on('click', e => {
      //console.log(e);
      this.updateWaypoint(e);
    });


  }

  // add a routing control
  addRouteControl() {

    this.routingControl = L.Routing.control({
      waypoints: [
        // L.latLng(-0.296629, 36.0611),
        // L.latLng(-1.296276, 36.8532)
      ],
      geocoder: L.Control.Geocoder.nominatim(),
      router: L.Routing.mapzen('matrix-L2KieTU', {
        costing: 'auto'
      }),
      formatter: new L.Routing.mapzenFormatter(),
      routeWhileDragging: false,
      autoRoute: false,
      createMarker: getCustomMarkers
    });

    this.map.addControl(this.routingControl);

    // Zoom to waypoints on geocode
    this.routingControl.on('waypointschanged', e => {

      this.routeError = '';
      const waypointLayer = L.featureGroup();

      this.map.eachLayer(layer => {
        if (layer instanceof L.Marker) {
          // console.log(layer.getBounds());
          waypointLayer.addLayer(layer);
          //console.log(layer);
        }
      });

      // clear the route
      if (this.selectedRoute) {

      } else {
        this.route ? this.route.clearLayers() : '';
      }

      this.map.fitBounds(waypointLayer.getBounds(), { maxZoom: 12 });
      // this.map.setView();
    });

  }

  // get all the client routes
  getRoutes() {
    this._routeService.getRoutes({
      cus_id: this.customerID
    })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(response => {

        //console.log(response);

        this.routes = response;

        this.routesFilter = response;
        this.spinner.hide();
      });

  }

  findRoute() {
    const waypoints = this.routingControl.getWaypoints();
    //console.log(Object.values(waypoints));


    this._routeService.getRouteProxy({
      wp1: Object.values(waypoints[0].latLng),
      wp2: Object.values(waypoints[1].latLng)
    })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(response => {

        if (response.coordinates.length === 0) {
          this.routeError = 'No route found. Retry using other waypoints';

        } else {
          this.routeError = '';
          this.routeGeojson = response;

          this.routeForm.patchValue({
            distance: response.properties.distance,
            fromAddress: waypoints[0].name.toString(),
            toAddress: waypoints[1].name.toString(),
          });

          this.addRouteToMap();
        }

      });
  }

  // update the waypoints on click
  updateWaypoint(e) {
    this.routeError = '';

    const waypoints = this.routingControl.getWaypoints();
    //console.log(waypoints);

    this.route ? this.route.clearLayers() : '';

    // this.routingControl
    if (waypoints[1].latLng == null && waypoints[0].latLng != null) {

      this.routingControl.setWaypoints([
        waypoints[0].latLng,
        e.latlng
      ]);

    } else {

      this.routingControl.setWaypoints([
        e.latlng
        // waypoints[1].latLng
      ]);
    }


  }

  // add route to map
  addRouteToMap() {
    // clear the route
    this.route = this.route ? this.route.clearLayers() : this.route;

    this.route = L.geoJson(this.routeGeojson, {
      color: '#005953',
      weight: 3,
    });

    this.route.addTo(this.map);

    // fit to map bounds
    if (this.route.getBounds()) {
      this.map.fitBounds(this.route.getBounds());
    }

  }

  // get route Information
  getVehicleRouteInformation(routeId: number) {
    // get route
    this.selectedRoute = this.routes.find(route => route.route_id === routeId);

    //console.log(this.vehicleList);

    if (this.selectedRoute) {
      this.action = 'Update';
      this.buttonTitle = 'Update';

      //  update form
      this.routeForm.patchValue({
        routeName: this.selectedRoute.name,
        distance: this.selectedRoute.distance,
        fromAddress: this.selectedRoute.from_address,
        toAddress: this.selectedRoute.to_address,
      });

      //  update the route
      this.routeGeojson = { type: 'LineString', coordinates: this.wktToArray(this.selectedRoute.coord), properties: {} };

      //  add the route to the map
      this.addRouteToMap();

      const length = this.routeGeojson.coordinates.length;

      // update the waypoints
      this.routingControl.setWaypoints([
        L.latLng(this.routeGeojson.coordinates[0].reverse()),
        L.latLng(this.routeGeojson.coordinates[length - 1].reverse())
      ]);

    } else {

      //  update form
      this.routeForm.patchValue({
        routeName: this.selectedVehicle.dev_name.concat(' route'),
        cusId: this.customerID
      });

    }


  }


  // submit form
  onSubmit(content) {
    // validate the form
    // if(this.routeVehicles.length == 0){
    //   console.log("Error");
    //   throw(
    //     new Error('Select atleast one vehicle')
    //   );
    // }

    if (this.selectedRoute) {
      this.action = 'Update';
      this.buttonTitle = 'Update';
      this.spinnerStatus = 'Update route: ' + this.selectedRoute.name;
    } else {
      this.action = 'Create';
      this.buttonTitle = 'Create';
      this.spinnerStatus = 'Create route ....';
    }

    if (this.routeForm.valid) {
      this.modalService.open(content, { centered: true }).result.then(result => {
        if (result === 'Cancel') {

        } else {
          // Update or create
          this.action === 'Update' ? this.updateRoute() : this.createRoute();
        }
      });
    } else {
      //console.log('Error');
      throw (
        new Error('Fill the required field')
      );
    }
  }

  // Create a route
  createRoute() {
    // get form and coordinates data
    const coord = this.latLngToWKT(this.routeGeojson.coordinates);
    const data = this.routeForm.getRawValue();
    this.spinner.show();

    // this.routeVehicles.forEach(vehicle=>{

    this._routeService.createRoute({
      name: data.routeName,
      distance: data.distance,
      cus_id: this.customerID,
      from_address: data.fromAddress,
      to_address: data.toAddress,
      coord: [coord]
    })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(response => {
        //console.log(response);
        if (response.serverStatus === 2) {
          this.getRoutes();
          // reset the form
          this.resetForm();
        }

        this.spinner.hide();


      });

    // });


  }

  // update route Information
  updateRoute() {
    const data = this.routeForm.getRawValue();

    //console.log(this.routeGeojson);
    const coordinates = this.latLngToWKT(this.routeGeojson.coordinates);

    this.spinnerStatus = 'Updating route: ' + this.selectedRoute.name;
    this.action = 'Update';
    this.spinner.show();

    // Update route information
    this._routeService.updateRoute({
      route_id: this.selectedRoute.route_id,
      name: data.routeName,
      distance: data.distance,
      cus_id: data.cusId,
      from_address: data.fromAddress,
      to_address: data.toAddress,
      coord: [coordinates]
    })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(response => {

        this.selectedRoute = undefined;
        // this.route.

        if (response.serverStatus === 2) {
          this.getRoutes();
          // this.selectedRoute = response[0]
        }

        // reset the form
        this.resetForm();
      });
  }

  // Delete the route
  deleteRoute(content, routeId: number) {

    this.action = 'Delete';
    this.spinnerStatus = 'Delete route: ' + this.selectedRoute.name;

    this.modalService.open(content, { centered: true }).result.then(result => {
      if (result === 'Cancel') {

      } else {
        this.spinner.show();

        this._routeService.deleteRoute({
          route_id: routeId,
          cus_id: this.customerID,
          name: this.selectedRoute.name
        })
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(response => {
            if (response.serverStatus === 2) {
              // clear the route
              this.route.clearLayers();

              // fetch the route
              this.getRoutes();

              // reset the form
              this.resetForm();
            }
          });

      }
    });
  }

  // Remove vehicle from route

  removeDeviceRoute(content, routeId: number, devId: number) {

    this.action = 'Delete';
    this.spinnerStatus = 'Delete route with id ' + routeId;


    this.spinner.show();
    this._routeService.deleteRoute({
      route_id: routeId,
      dev_id: devId,
      cus_id: this.customerID
    })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(response => {
        // update the routes
        if (response.serverStatus === 2) {
          this.route.clearLayers();
          this.getRoutes();

          this.resetForm();
        }

      });

  }

  // object to array
  wktToArray(geometry): any[] {
    let coordinates = geometry.split('(')[1].replace(')', '').split(',');

    coordinates = coordinates.map(coord => {
      const cd = coord.split(' ');
      return cd.map(c => parseFloat(c));
    });

    //console.log(coordinates);
    return coordinates;
  }

  //
  latLngToWKT(coord: any[]): string {
    const wkt = coord.map(cd => {
      return cd[0] + ' ' + cd[1];
    });

    return 'LineString(' + wkt + ')';
  }

  resetForm() {
    this.action = 'Create';
    this.buttonTitle = 'Create';
    this.selectedRoute = undefined;

    // delete the route
    this.route = this.route ? this.route.clearLayers() : '';

    this.routeForm.setValue({
      routeName: '',
      distance: '',
      toAddress: '',
      fromAddress: ''
    });
  }

  // MultiSelect Event Listeners
  onItemDeSelect(item, content) {
    //(item);
    if (this.selectedRoute) {
      this.modalService.open(content, { centered: true }).result.then(result => {
        if (result === 'Cancel') {
          // reselect the vehicl again
          this.selectedItems.push(item);
        } else {

          // call to the api to delete the vehicle
          this.selectedVehicle = this.availableDevices.find(dev => dev.dev_id === item.dev_id);

        }
      });
    }
  }

  onItemSelect(item) {
    // add the item to route vehicle
    const vehicle = this.availableDevices.find(dev => dev.dev_id === item.dev_id);

    this.routeVehicles.push(vehicle);

  }

  onSelectAll(items) {
    //console.log(items);
    this.routeVehicles = this.availableDevices;

    //console.log(this.routeVehicles);
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}
