import { Injectable } from '@angular/core';
import { GLOBAL_URL, ProxyURL } from '../models/globals';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Alert } from '../models/alert';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  API_URL = GLOBAL_URL;
  PROXY_URL = ProxyURL;

  constructor(private httpClient: HttpClient) {

  }

  // get alerts
  getAlerts(customer): Observable<Alert[]> {
    return this.httpClient.post<Alert[]>(`${this.API_URL}/get_alerts`, customer)
      .pipe(
        catchError(error => throwError(error.message))
      );
  }

  createAlert(alert): Observable<any> {
    //console.log(alert);

    return this.httpClient.post<any>(`${this.API_URL}/create_alert_rule`, alert)
      .pipe(
        catchError(error => throwError(new Error('Failed to add the alert to the database: Retry')))
      );

  }

  // update alert
  updateAlert(alert: Alert): Observable<any> {
    return this.httpClient.post<any>(`${this.API_URL}/update_alert_rule`, alert)
      .pipe(
        catchError(error => throwError(new Error('Failed to update alert ' + alert.alert_category))));
  }

  deleteAlert(alert): Observable<any> {
    //console.log(alert);
    return this.httpClient.post<any>(`${this.API_URL}/delete_alert_rule`, alert)
      .pipe(
        catchError(error => throwError(new Error('Failed to delete alert ' + alert.criteria_name)))
      );
  }
}
