import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SigninComponent } from './signin/signin.component';
import { HomeComponent } from './home/home.component';
import { MapComponent } from './map/map.component';
import { AuthGuard } from './services/auth.guard';
import { GeofencesComponent } from './geofences/geofences.component';
import { ReportsComponent } from './reports/reports.component';
import { MileageComponent } from './mileage/mileage.component';
import { AdminComponent } from './admin/admin.component';
import { ViolationReportComponent } from './violation-report/violation-report.component'
import { RouteManagementComponent } from './route-management/route-management.component';
import { UserManagementComponent } from './user-management/user-management.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { UserComponent } from './user/user.component';
import { RouteGuard } from './services/route.guard';
import { AlertsComponent } from './alerts/alerts.component';

const routes: Routes = [
  {
    path: 'signin',
    component: SigninComponent
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: '',
    component: SigninComponent
  },
  {
    path: 'map',
    component: MapComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'edit',
    component: GeofencesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'trip_report',
    component: ReportsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'mileage_report',
    component: MileageComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'violation_report',
    component: ViolationReportComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'dashboard',
    component: DashboardComponent
  },
  {
    path: 'admin',
    component: AdminComponent,
    children: [
      {
        path: 'device',
        component: UserManagementComponent
      },
      {
        path:'',
        component:UserComponent
      },
      {
        path: 'routes',
        component: RouteManagementComponent
      },
      {
        path:'alerts',
        component:AlertsComponent
      }
    ],
    canActivate: [AuthGuard, RouteGuard]
  },
  {
    path: '**',
    component: HomeComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
