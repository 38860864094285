<div class="container-fluid">
    <div class="row">

        <div class="col-md-3">
            <ngx-spinner type="ball-clip-rotate-multiple" size="medium" bdColor="#fff" bdOpacity=0.7 color="#333"
                [fullScreen]="false">
                <p class="loading text-white">{{ spinnerStatus }}</p>
            </ngx-spinner>
            <!-- List of users -->
            <h3 class="t-1  bg-t1 w100 px-2">Current Users</h3>


            <!-- search bar -->
            <form action="" class="mb-1">
                <div class="input-group input-group-sm">
                    <input type="search" name="" id="" class="form-control form-control-sm" placeholder="Filter User"
                        [formControl]="userSearch">

                    <div class="input-group-prepend">
                        <span class="input-group-text">
                            <i class="fa fa-search" aria-hidden="true"></i>
                        </span>
                    </div>
                </div>
            </form>

            <div class="list-group" id="user-list">
                <li class="list-group-item d-flex justify-content-between" *ngFor="let user of userFilter"
                    (click)="getUserDetails(user.username)">
                    {{ user.username }}
                    <p>{{ user.email}}</p>
                </li>
            </div>

        </div>
        <div class="col-md-5">
            <h3 class="t-1 bg-t1 w100 pl-2">User Details</h3>
            <!-- User Details -->
            <div class="card" *ngIf="selectedUser">
                <div class="card-header d-flex justify-content-between">
                    <h3 class="card-title ml-2">{{ selectedUser.username}}</h3>
                    <!-- action -->
                    <div class="mr-2" *ngIf="selectedUser">
                        <button class="btn btn-sm btn-danger mt-2" (click)="deleteUser(selectedUser.id, content)">
                            <i class="fa fa-trash" aria-hidden="true"></i>
                        </button>
                        <button class="btn btn-sm bg-t1 mt-2 ml-2" (click)="addUserToForm(selectedUser)">
                            <i class="fa fa-pencil" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
                <div class="card-body">
                    <table class="table">
                        <tbody>
                            <tr>
                                <td>Email:</td>
                                <td>{{ selectedUser.email }}</td>
                            </tr>
                            <tr>
                                <td>User Role:</td>
                                <td>{{selectedUser.role_name}}</td>
                            </tr>
                            <tr>
                                <td>Map Type:</td>
                                <td>{{ selectedUser.user_map_type }}</td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>
        </div>
        <div class="col-md-4">
            <!-- User Registration form -->
            <h3 class="t-1 bg-t1 w100 px-2"> {{ buttonTitle }} User</h3>

            <form action="" class="form-horizontal" (ngSubmit)="onSubmit(content)" [formGroup]="userForm">
                <div class="input-group input-group-sm">
                    <div class="input-group-prepend ">
                        <span class="input-group-text" id="basic-addon1">
                            <i class="fa fa-user" aria-hidden="true"></i>
                        </span>
                    </div>
                    <input type="text" name="" id="" class="form-control form-control-sm" formControlName="username">
                </div>

                <span class="alert alert-danger" *ngIf="userForm.get('username').errors?.required">Required</span>

                <div class="input-group input-group-sm">
                    <div class="input-group-prepend ">
                        <span class="input-group-text" id="basic-addon1">
                            Password
                        </span>
                    </div>
                    <input type="text" name="" id="" class="form-control form-control-sm"
                        formControlName="userPassword">
                </div>
                <span class="alert alert-danger" *ngIf="userForm.get('userPassword').errors?.required">Required</span>

                <div class="input-group input-group-sm">
                    <div class="input-group-prepend ">
                        <span class="input-group-text" id="basic-addon1">
                            Email
                        </span>
                    </div>
                    <input type="email" name="" id="" class="form-control form-control-sm" formControlName="userEmail"
                        email>
                    <!-- <input type="email" name="" id=""> -->
                </div>
                <span class="alert alert-danger" *ngIf="userForm.get('userEmail').errors?.required">Required</span>
                <span class="alert alert-danger" *ngIf="userForm.get('userEmail').errors?.email">Email</span>

                <div class="input-group input-group-sm">
                    <div class="input-group-prepend ">
                        <span class="input-group-text" id="basic-addon1">
                            User Map Type
                        </span>
                    </div>
                    <select name="" id="" class="form-control form-control-sm" formControlName="userMapType">
                        <option value="GoogleMaps"> Google Maps</option>
                        <option value="osm">OSM</option>
                    </select>

                </div>
                <span class="alert alert-danger" *ngIf="userForm.get('userMapType').errors?.required">Required</span>

                <div class="input-group input-group-sm">
                    <button type="submit" class="btn btn-sm btn-submit" ng-disabled="userForm.$invalid">{{ buttonTitle
                        }}</button>
                    <button type="button" class="btn btn-sm bg-t3 ml-3" *ngIf="userForm.get('username')"
                        (click)="resetForm(content)">Reset</button>
                </div>
            </form>
        </div>
    </div>

    <div class="row">

    </div>
</div>

<!-- Modal to listen to user -->
<ng-template #content let-modal>
    <div class="modal-body text-center text-white">
        <p>{{ spinnerStatus }}</p>
    </div>
    <div class="btn-group my-1">
        <button type="button" class="btn btn-sm mx-2" [ngClass]="{
      'bg-t1':this.action != 'Delete',
      'btn-danger':this.action == 'Delete'
    }" (click)="modal.close(action)">{{action}}</button>
        <button type="button" class="btn bg-t3 btn-sm mx-2" (click)="modal.close('Cancel')">Cancel</button>
    </div>
</ng-template>