<app-navbar></app-navbar>
<!-- Page heder -->


<div class="container-fluid">
    <div class="row">
        <!-- Collapsible -->
        <div class="col-md-3 noprint">
            <div class="d-flex aligns-items-center justify-content-between mb-1">
                <p class="mt-2">Vehicle information</p>
            </div>
           
            <form [formGroup]="mileageForm">
                <ng-multiselect-dropdown name="vehicle" 
                    [placeholder]="'Select Vehicle'" 
                    [data]="vehicles" 
                    formControlName="vehicle"
                    [settings]="dropdownSettings" 
                    (onSelect)="onItemSelect($event)">
                </ng-multiselect-dropdown>

                <p class="alert alert-danger" *ngIf="mileageForm.get('vehicle')?.errors?.required"> Required</p>
                <!-- Date filters -->
                <div class="input-group input-group-sm mt-2">
                    <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1">From</span>
                    </div>
                    <input class="form-control" placeholder="yyyy-mm-dd" name="dp" ngbDatepicker #from="ngbDatepicker"
                    formControlName="from">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="from.toggle()" type="button">
                            <i class="fa fa-calendar" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
                <p class="alert alert-danger" *ngIf="mileageForm.get('from')?.errors?.required"> Required</p>
                <!-- To -->
                <div class="input-group input-group-sm mt-2">
                    <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1">To</span>
                    </div>
                    <input class="form-control" placeholder="yyyy-mm-dd" name="dp" ngbDatepicker
                     [minDate]="minimumDate" 
                     [maxDate]="maximumDate" 
                     #to="ngbDatepicker"
                     formControlName="to">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary calendar" (click)="to.toggle()" type="button">
                           <i class="fa fa-calendar" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>

                <p class="alert alert-danger" *ngIf="mileageForm.get('to')?.errors?.required"> Required</p>
                <div class="input-group mt-2">
                    <input type="submit" value="Submit" name="Submit" class="btn btn-sm submit" (click)="onSubmit()">
                </div>
            </form>

            <!-- report -->
        <div class="btn-group mt-2 ml-2" id="pdf-btn" *ngIf="ispdfReady">
            <button (click)="generatePDF('print')" class="btn btn-sm">
                <i class="fa fa-print text-danger " aria-hidden="true"></i>
                Print
            </button>
            <button (click)="generatePDF('download')" class="btn btn-sm">
                <i class="fa fa-download text-danger " aria-hidden="true"></i>
                Pdf
            </button>
            <button (click)="generatePDF('open')" class="btn btn-sm">
                <i class="fa fa-eye text-danger" aria-hidden="true"></i>
                Pdf
            </button>
        </div>
        </div>
        <div class="col-md-9">
            <div class="data-table">
            <ngx-spinner 
            size="medium" 
            type="ball-clip-rotate-multiple" 
            bdColor="#fff" 
            bdOpacity=0.9 
            color="#333" 
            [fullScreen]="false">
            <p> {{spinnerStatusText }}</p>
            </ngx-spinner>
            <table class="table">
                <thead>
                    <th>Vehicle Name</th>
                    <th>License Plate</th>
                    <th>Current Driver</th>
                    <th>Mileage (km) </th>
                </thead>
                <tbody>
                    <tr *ngFor="let vehicle of selectedVehicle">
                        <td>{{ vehicle?.dev_name }}</td>
                        <td>{{ vehicle?.dev_license_plate }}</td>
                        <td> {{ vehicle?.dev_driver_name }}</td>
                        <td > 
                            {{ vehicle?.mileage }} 
                            <img *ngIf="!vehicle.hasOwnProperty('mileage')" src="../../assets/spinner.gif" alt="" height="30" width="30">
                        </td>
                        
                    </tr>
                </tbody>
            </table>
        </div>
        </div>
    </div>
</div>