import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { DataService } from '../services/data.service';
import { ReportGeneratorService } from '../services/report-generator.service';

import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import * as L from 'leaflet';
import * as turf from '@turf/turf';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { TimeZoneService } from '../services/timezone.service';

@Component({
  selector: 'app-mileage',
  templateUrl: './mileage.component.html',
  styleUrls: ['./mileage.component.scss']
})
export class MileageComponent implements OnInit, OnDestroy {

  mileageForm = new FormGroup({
    vehicle: new FormControl('', Validators.required),
    from: new FormControl('', Validators.required),
    to: new FormControl('', Validators.required)
  });

  public vehicleMileageStatus: any = {};

  ShowFilter = true;
  limitSelection = false;
  vehicles: any[] = [];

  selectedItems: any[] = [];
  dropdownSettings: any = {};

  // spinner text
  spinnerStatusText: string;

  // User information
  userId;
  cus_id;
  userIsMaster;
  username;
  customerName;

  ispdfReady = true;

  // Selected Vehicles
  public selectedVehicle;

  // Destroy the subscriber
  private onDestroy$ = new Subject<void>();

  // Datepicker
  public fromModel: NgbDateStruct;
  public toModel: NgbDateStruct;

  minimumDate = { day: 20, month: 4, year: 2020 };
  maximumDate = { day: 20, month: 4, year: 2020 };

  // From and two date strings
  fromDate;
  toDate;

  // Spinner
  spinner1 = 'my-spinner';

  constructor(
    private dataService: DataService,
    public spinner: NgxSpinnerService,
    private reportGenerator: ReportGeneratorService,
    private timeZoneService: TimeZoneService
  ) {
    // Customer infomation
    this.userId = localStorage.getItem('userId');
    this.cus_id = localStorage.getItem('cusId');
    this.userIsMaster = localStorage.getItem('userIsMaster');
    this.username = localStorage.getItem('userName');
    this.customerName = localStorage.getItem('cusName');
  }

  ngOnInit(): void {
    this.spinnerStatusText = 'Loading Vehicle data ....';
    this.spinner.show();

    // Get vehicle data
    this.dataService.getVehicle({
      userId: this.userId,
      cus_id: this.cus_id,
      userIsMaster: this.userIsMaster
    })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(response => {
        this.vehicles = response;
        //(this.vehicles);

        this.spinner.hide();
      });

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'dev_id',
      textField: 'dev_name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: this.ShowFilter
    };

    this.mileageForm.get('from').valueChanges.subscribe(response => {
      // empty the to value
      this.mileageForm.get('to').setValue('');

      // update min and mx date condtion
      this.minimumDate = response;
      this.maximumDate = {
        day: response.day + 7,
        month: response.month,
        year: response.year
      };

      this.toModel = this.maximumDate;
      // update from date
      this.fromDate = Object.values(response).join('-');
    });

    this.mileageForm.get('to').valueChanges.subscribe(response => {
      // console.log(response);
      this.toDate = Object.values(response).join('-');
    });
  }

  isFormValid(): boolean {
    // get the form data
    const keys = ['required'];
    const controls = Object.keys(this.mileageForm.controls);
    let error = true;

    // Iterate over the errors 
    for (const key of keys) {

      for (const control of controls) { // iterate on each control

        if (this.mileageForm.getError(key, control)) {  // get the errar status
          error = true;
          //console.log(error);
          return error; // if there is error return true
        } else {
          error = false; // Otherwise return false
        }
      }
    }

    return error;
  }
  onSubmit() {
    // check on form errors
    if (this.isFormValid()) {
      throw new Error('Fill all the required fields');
    } else {
      // get vehicles mileage
      this.getMileage();
    }

  }
  getMileage() {
    // empty selected vehicles
    this.selectedVehicle = this.selectedVehicle ? undefined : this.selectedVehicle;

    // show the spinner
    this.spinnerStatusText = 'Loading vehicle mileage data ....';
    this.spinner.show();

    // get the form data
    const vehicles = this.mileageForm.get('vehicle').value;
    const startDate = this.mileageForm.get('from').value;
    const endDate = this.mileageForm.get('to').value;

    // update selected vehicles
    this.selectedVehicle = this.vehicles.filter(vehicle => {
      const currentVehicle = vehicles.find(vh => vh.dev_id == vehicle.dev_id);
      if (currentVehicle) {
        return vehicle;
      }
    });

    // Request the data
    vehicles.forEach((vehicle, i) => {

      const lagos = localStorage.getItem("Timezone");
      const gmt = "GMT";
      // format the data to be sent to the server
      const data = {
        from: this.timeZoneService.convertTimeToTimezone(Object.values(startDate).join('-').concat(' 00:00:00'), lagos, gmt),
        to: this.timeZoneService.convertTimeToTimezone(Object.values(endDate).join('-').concat(' 23:59:59'), lagos, gmt),
        dev_id: vehicle.dev_id
      };
      //console.log({ mydata: data });
      // request for vehicle history
      this.dataService.getVehicleHistory(data)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(response => {

          // Check for errors
          if (response.length === 1 && response[0].error) {
            this.vehicleMileageStatus = { danger: 'Error loading the data' };

          } else {
            this.vehicleMileageStatus = { success: 'Successfully loaded Mileage data' };

            if (response.length === 0) {
              // Zero mileage if empty array
              this.selectedVehicle.forEach(vh => {

                if (vh.dev_id === data.dev_id) {
                  vh['mileage'] = 0;

                }

                return vh;
              });

              this.spinner.hide();
            } else {
              // hide the spinner
              this.spinner.hide();
              this.calculateMileage(response, data.dev_id);
            }
          }

          // this.ispdfReady = i == vehicles.length-1?true:false; 
          //console.log(this.ispdfReady);
        });
    });
    //console.log();
  }

  // Compute distance
  calculateMileage(data, vehicleId): any {
    // convert data to lat and lng
    const coords = data.map(datum => {
      return [datum.pos_longitude, datum.pos_latitude];
    });

    //(coords);

    // create a turf linesString
    const line = turf.lineString(coords);

    // distance variable
    // const distance = [];
    // let previousCoord;

    // // // Compute distance using distanceTo function
    // coords.forEach(coord => {

    //   if (previousCoord) {
    //     const length = previousCoord.distanceTo(L.latLng(coord));
    //     distance.push(length);
    //   }

    //   previousCoord = L.latLng(coord);

    // });

    // find total distance for each vehicle
    const totalDistance = turf.length(line, { units: 'kilometers' });
    // const totalDistance = distance.reduce( (a, b) => a + b );
    //console.log(totalDistance);
    // add the data to the mileage report
    this.selectedVehicle.forEach(vehicle => {

      if (vehicle.dev_id === vehicleId) {
        // update the mileage value
        vehicle['mileage'] = Math.round(totalDistance);
        this.spinner.hide();

        return vehicle;
      }

      return vehicle;
    });

    //console.log(this.selectedVehicle);
    // this.spinner.hide();
  }

  isNumber(value): boolean {
    return parseInt(value, 10) ? true : false;
  }

  // 
  onItemSelect(item: any) {
    //console.log(item);
  }

  onSelectAll(items: any) {

  }

  printReport() {
    window.print();
  }

  generatePDF(action: string) {
    // open, download and print a table
    const documentDefinition = this.getDocumentDefinition();

    // react to the respective action
    switch (action) {
      case 'download':
        pdfMake.createPdf(documentDefinition).download();
        break;
      case 'print':
        pdfMake.createPdf(documentDefinition).print();
        break;
      case 'open':
        pdfMake.createPdf(documentDefinition).open();
        break;
      default:
        pdfMake.createPdf(documentDefinition).open();
        break;
    }
  }

  getDocumentDefinition() {
    return {
      content: [
        this.reportGenerator.createPageHeader('Mileage Report', [this.fromDate, this.toDate]),
        {
          table: {
            widths: ['*', '*', '*', '*'],
            body: [
              [
                {
                  text: 'Vehicle Name',
                  style: 'tableHeader'
                },
                {
                  text: 'License Plate',
                  style: 'tableHeader'
                },
                {
                  text: 'Current Driver',
                  style: 'tableHeader'
                },
                {
                  text: 'Mileage',
                  style: 'tableHeader'
                }
              ],
              ...this.selectedVehicle.map(vehicle => {
                return [
                  {
                    text: vehicle.dev_name,
                    fontSize: 9,
                    alignment: 'left'
                  },
                  {
                    text: vehicle.dev_license_plate,
                    fontSize: 9,
                    alignment: 'left'
                  },
                  {
                    text: vehicle.dev_driver_name,
                    fontSize: 9,
                    alignment: 'left'
                  },
                  {
                    text: vehicle.mileage,
                    fontSize: 9,
                    alignment: 'left'
                  }
                ];
              })
            ],
          },
          layout: 'lightHorizontalLines'
        }
      ],
      styles: {
        header: {
          fontSize: 14,
          bold: true,
        },
        tableHeader: {
          bold: true,
          fontSize: 9
        },
        attr: {
          fontSize: 10
        }
      },
      info: {
        title: 'Trip/ History Report',
        author: this.username,
        subject: 'Trip/ History Report',
        keywords: 'trip, history, report',
      },
    };
  }

  // create a table
  getTableData() {

  }
  ngOnDestroy() {
    // Unsubscribe to
    this.onDestroy$.next();
    this.onDestroy$.complete();

    //console.log('Destroy');
  }
}
